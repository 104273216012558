import {
	CircularProgress,
	Grow,
	Modal,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import jzip from 'xlsx/dist/jszip';
import 'xlsx/dist/xlsx.full.min.js';
import '../../assets/css/vec-datatables.css';
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg';
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import Sort from "../../assets/images/llantas/table/sort.svg";
import LogoCompany from '../../assets/images/logo-company.png';
import LogoVecFleet from '../../assets/images/logo-vecfleet.png';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Security from '../../commons/security/Security.js';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTableCellProps as StyledTableCell, StyledTooltip, rowsPerPage } from '../../commons/utils/TableStyles';
import * as Utils from '../../commons/utils/Utils';
import TicketCards from "./TicketCards.jsx";
import AccionesMasivas from './TicketGridAccionesMasivas';
import TicketGridFilters from './TicketGridFilters';
import TicketsCerradosImportar from "./TicketsCerradosImportar.jsx";
import TicketsCerradosImportarReporte from "./TicketsCerradosImportarReporte.jsx";
import TicketsGridExport from './TicketsGridExport';
import TicketsGridRow from './TicketsGridRow';
import TicketsGridTableRow from './TicketsGridTableRow';
import swal2 from 'sweetalert2';


window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PAGINATION_INIT = {
	page: 0,
	count: 0,
	perPage: 10,
}

const FILTER_QUERY_INIT = {
	entidad: '',
	ticketTipo: 'DEFAULT',
	id: '',
	estado: 'PENDIENTES',
	fechaHoraAlta: '',
	servicio: '',
	movilPersona: '',
	gerenciador: '',
	region: '',
	subRegion: [],
	base: '',
	pais: [],
	fechaRealizado: '',
	enTaller: '',
	detalle: '',
	abono: '',
	centroCostos: '',
	centroBeneficios: '',
	responsable1: '',
	encuestaNivelSatisfaccion: '',
	fechaHoraTurno: '',
	nivelAprobacionPresupuesto: '',
	tipoModelo: '',
	enTallerKpi: '',
	mttTotal: '',
	mttParcial: '',
	abono: '',
	origen: '',
	turnoInicio: '',
	turnoFinal: '',
	fechaPrometidaInicio: '',
	fechaPrometidaFinal: '',
	prioridad: '',
}

const prioridadHabilitado = ConfigBusiness.get('tickets.prioridad.habilitado') === 'true' ? true : false;

const SORT_QUERY_INIT = {
	id: !prioridadHabilitado ? 'desc' : '',
	ticketTipo: '',
	estado: '',
	fechaHoraAlta: '',
	detalle: '',
	servicio: '',
	nivelAprobacionPresupuesto: '',
	entidad: '',
	gerenciador: '',
	region: '',
	subRegion: '',
	base: '',
	pais: '',
	centroCostos: '',
	centroBeneficios: '',
	responsable1: '',
	enTaller: '',
	encuestaNivelSatisfaccion: '',
	fechaHoraTurno: '',
	abono: '',
	tipoModelo: '',
	mttTotal: '',
	mttParcial: '',
	fechaPrometida: '',
	prioridad: prioridadHabilitado ? 'desc' : '',
}

const getStorage = () => {
	let storage = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))
	if (storage && storage.activo) {
		return true
	}
	else {
		return false
	}
}

const TicketsGrid = (props) => {
	const isTicketGridTable = ConfigBusiness.get('tickets.grilla.tabla_fuente') === 'ticket_grid' ? true : false;
	const intl = useIntl()
	const ticketsTipoStrings = localStorage.getItem('ticketsTipoStrings') ? JSON.parse(localStorage.getItem('ticketsTipoStrings')) : []
	const ticketsEstadoStrings = localStorage.getItem('ticketsEstadoStrings') ? JSON.parse(localStorage.getItem('ticketsEstadoStrings')) : []

	const [loading, setLoading] = useState(false)
	const [redirectTo, setRedirectTo] = useState(null)

	const [searchActive, setSearchActive] = useState(false)
	const [pagination, setPagination] = useState({ ...PAGINATION_INIT })
	const [paginationCount, setPaginationCount] = useState(0);

	const [persisteFiltros, setPersisteFiltros] = useState(getStorage())

	const [filterResetInputs, setFilterResetInputs] = useState(false)
	const [filterQuery, setFilterQuery] = useState(null)

	const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT })

	const [dropdownActive, setDropdownActive] = useState(false);

	const [tickets, setTickets] = useState([])
	const [estados, setEstados] = useState([])

	const [cardKpi, setCardKpi] = useState(null);

	//Alarmas Controles
	const [estadisticas, setEstadisticas] = useState(null)

	//Enabled Columns Config
	const [columnsEnabled, setColumnsEnabled] = useState({
		editKm: false,
		pais: false,
		region: false,
		abono: false,
		subRegion: false,
		unidad: false,
		titular: false,
		companiaOrigen: false,
		centroCostos: false,
		cecosDireccion: false,
		cecosMercado: false,
		cecosGerencia: false,
		cecosArea: false,
		responsable1: false,
		chasis: false,
		base: false,
		nivelAprobacionPresupuesto: false,
		origen_checklist: false,
		fechaPrometida: false,
		mostrarTotalPresupuesto: false,
		diasReparacion: false,
		prioridad: false,
	})

	//Security Permissions
	const [permissions, setPermissions] = useState({
		canModifyKM: false,
		movilesVisualizar: false,
		movilesModificar: false,
		ticketsCrear: false,
		ticketsListar: false,
		vencimientosListar: false,
		vencimientosCrear: false,
		preventivosListar: false,
		correctivosCrear: false,
		ticketsModificar: false,
		ticketsVisualizar: false,
		ticketsCambiarPrioridad: false,
	})

	const [modalExport, setModalExport] = useState(false)
	const [modalExportHistorico, setModalExportHistorico] = useState(false)
	const [stateImport, setStateImport] = useState(false)

	useEffect(() => {
		setLoading(true)
		if (Security.hasPermission("TICKETS_LISTAR")) {
			configColumns()
			securityPermission()
			filterQuery && fetchTickets()

			getEstadisticas()
		} else {
			setRedirectTo("/error")
		}
		//componentWillUnmount
		return () => { }
	}, [pagination, filterQuery, sortQuery, stateImport])

	const configColumns = () => {
		let aux = { ...columnsEnabled }
		aux.region = ConfigBusiness.get('tickets.region.grilla') === 'true' ? true : false;
		aux.pais = ConfigBusiness.get('tickets.pais.grilla') === 'true' ? true : false;
		aux.centroCostos = ConfigBusiness.get('tickets.centroCostosGrilla.habilitado') === 'true' ? true : false;
		aux.centroBeneficios = ConfigBusiness.get('tickets.centroBeneficiosGrilla.habilitado') === 'true' ? true : false;
		aux.responsable1 = ConfigBusiness.get('tickets.responsable1.habilitado') === 'true' ? true : false;
		aux.encuestaSatisfaccion = ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ? true : false;
		aux.fechaHoraTurno = ConfigBusiness.get('tickets.fechaHoraTurno.habilitado') === 'true' ? true : false;
		aux.abono = ConfigBusiness.get('mantenimientos.correctivo.abono.habilitado') === 'true' ? true : false;
		aux.tipoModelo = ConfigBusiness.get('tickets.movil.modelo') === 'true' ? true : false;
		aux.nivelAprobacionPresupuesto = ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.grilla') === 'true' ? true : false;
		aux.origen_checklist = ConfigBusiness.get("checklist.habilitado") === 'true' ? true : false;
		aux.fechaPrometida = ConfigBusiness.get("tickets.fechaprometida.grilla") === 'true' ? true : false;
		aux.mostrarTotalPresupuesto = ConfigBusiness.get("tickets.grilla.mostrar_total_presupuesto") === 'true' ? true : false;
		aux.diasReparacion = ConfigBusiness.get("tickets.diasReparacion.grilla") === 'true' ? true : false;
		aux.prioridad = ConfigBusiness.get("tickets.prioridad.habilitado") === 'true' ? true : false;
		setColumnsEnabled(aux)
	}

	const securityPermission = () => {
		let aux = { ...permissions }
		aux.ticketsCrear = Security.hasPermission('TICKETS_CREAR');
		aux.correctivosCrear = Security.hasPermission('MANTENIMIENTOS_CORRECTIVOS_CREAR');
		aux.gestoriaCrear = Security.hasPermission('TICKETS_GESTORIA_CREAR');
		aux.exportarHistorico = Security.hasPermission('TICKETS_EXPORTAR_HISTORICO');
		aux.ticketsListar = Security.hasPermission('TICKETS_LISTAR');
		aux.ticketsModificar = Security.hasPermission('TICKETS_MODIFICAR');
		aux.ticketsVisualizar = Security.hasPermission('TICKETS_VISUALIZAR');
		aux.ticketsCambiarPrioridad = Security.hasPermission('TICKETS_CAMBIAR_PRIORIDAD');
		setPermissions(aux)
	}

	const getEstadisticas = () => {
		Utils.getData(`/tickets/estadisticas`).then((data) => {
			setEstadisticas(data)
		}).catch((err) => {
			console.log(err)
		})
	}
	const fetchTickets = () => {
		let url = isTicketGridTable ? "/ticket-grid" : "/tickets/newGrid";

		Promise.all([
			Utils.getData(url+`${getQuery()}`),
		])
			.then((data) => {
				setTickets(data[0].tickets)
				setLoading(false)
				setStateImport(false)
				setPaginationCount(data[0].pagination.count)
			})
			.catch((err) => {
				console.log(err)
				setLoading(false)
			})
	};

	const getQuery = () => {
		//pagination
		let myQuery = "?page=" + pagination.page
		myQuery += "&perPage=" + pagination.perPage

		//filter
		myQuery += '&ticketTipo=' + filterQuery.ticketTipo;
		myQuery += '&entidad=' + filterQuery.entidad;
		myQuery += "&id=" + filterQuery.id;
		myQuery += "&estado=" + filterQuery.estado;
		myQuery += "&nivelAprobacionPresupuesto=" + filterQuery.nivelAprobacionPresupuesto;
		myQuery += "&fechaHoraAlta=" + filterQuery.fechaHoraAlta;
		myQuery += "&detalle=" + filterQuery.detalle;
		myQuery += "&servicio=" + filterQuery.servicio;
		myQuery += "&movilPersona=" + filterQuery.movilPersona;
		myQuery += "&gerenciador=" + filterQuery.gerenciador;
		myQuery += "&region=" + filterQuery.region;
		myQuery += "&subRegion=" + filterQuery.subRegion;
		myQuery += "&base=" + filterQuery.base;
		myQuery += "&pais=" + filterQuery.pais;
		myQuery += "&centroCostos=" + filterQuery.centroCostos;
		myQuery += "&centroBeneficios=" + filterQuery.centroBeneficios;
		myQuery += "&responsable1=" + filterQuery.responsable1;
		myQuery += "&enTaller=" + filterQuery.enTaller;
		myQuery += "&mttTotal=" + filterQuery.mttTotal;
		myQuery += "&mttParcial=" + filterQuery.mttParcial;
		myQuery += "&encuestaNivelSatisfaccion=" + filterQuery.encuestaNivelSatisfaccion;
		myQuery += "&fechaRealizado=" + filterQuery.fechaRealizado;
		myQuery += "&fechaHoraTurno=" + filterQuery.fechaHoraTurno;
		myQuery += "&tipoModelo=" + filterQuery.tipoModelo;
		myQuery += "&abono=" + filterQuery.abono;
		myQuery += "&origen=" + filterQuery.origen;
		myQuery += "&turnoInicio=" + filterQuery.turnoInicio;
		myQuery += "&turnoFinal=" + filterQuery.turnoFinal;
		myQuery += "&fechaPrometidaInicio=" + filterQuery.fechaPrometidaInicio;
		myQuery += "&fechaPrometidaFinal=" + filterQuery.fechaPrometidaFinal;
		myQuery += "&prioridad=" + filterQuery.prioridad;

		//sort
		myQuery += "&idSort=" + sortQuery.id;
		myQuery += '&tipoTicketSort=' + sortQuery.ticketTipo;
		myQuery += "&estadoSort=" + sortQuery.estado;
		myQuery += "&fechaHoraAltaSort=" + sortQuery.fechaHoraAlta;
		myQuery += "&detalleSort=" + sortQuery.detalle;
		myQuery += "&servicioSort=" + sortQuery.servicio;
		myQuery += "&entidadSort=" + sortQuery.entidad;
		myQuery += "&gerenciadorSort=" + sortQuery.gerenciador;
		myQuery += "&regionSort=" + sortQuery.region;
		myQuery += "&subRegionSort=" + sortQuery.subRegion;
		myQuery += "&baseSort=" + sortQuery.base;
		myQuery += "&paisSort=" + sortQuery.pais;
		myQuery += "&centroCostosSort=" + sortQuery.centroCostos;
		myQuery += "&centroBeneficiosSort=" + sortQuery.centroBeneficios;
		myQuery += "&responsableSort=" + sortQuery.responsable1;
		myQuery += "&enTallerSort=" + sortQuery.enTaller;
		myQuery += "&encuestaNivelSatisfaccionSort=" + sortQuery.encuestaNivelSatisfaccion;
		myQuery += "&fechaHoraTurnoSort=" + sortQuery.fechaHoraTurno;
		myQuery += "&abonoSort=" + sortQuery.abono;
		myQuery += "&tipoModeloSort=" + sortQuery.tipoModelo;
		myQuery += "&mttTotalSort=" + sortQuery.mttTotal;
		myQuery += "&mttParcialSort=" + sortQuery.mttParcial;
		myQuery += "&fechaPrometidaSort=" + sortQuery.fechaPrometida;
		myQuery += "&prioridadSort=" + sortQuery.prioridad;

		return myQuery;
	}

	const handleChangePage = (event, newPage) => {
		setPagination({ ...pagination, page: newPage });
	}

	const handleChangeRowsPerPage = (event) => {
		setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
	}

	const requestSort = (key) => {
		let query = { ...sortQuery }
		let aux = sortQuery[key];

		query["ticketTipo"] = "";
		query["id"] = "";
		query["estado"] = "";
		query["fechaHoraAlta"] = "";
		query["detalle"] = "";
		query["servicio"] = "";
		query["entidad"] = "";
		query["gerenciador"] = "";
		query["pais"] = "";
		query["region"] = "";
		query["subRegion"] = "";
		query["base"] = "";
		query["titular"] = "";
		query["companiaOrigen"] = "";
		query["centroCostos"] = "";
		query["centroBeneficios"] = "";
		query["responsable1"] = "";
		query["enTaller"] = "";
		query["mttTotal"] = "";
		query["mttParcial"] = "";
		query["encuestaNivelSatisfaccion"] = "";
		query["fechaRealizado"] = "";
		query["fechaHoraTurno"] = "";
		query["abono"] = "";
		query["tipoModelo"] = "";
		query["filtroKpi"] = "";
		query["showInactivos"] = "";
		query["origen"] = "";
		query["fechaPrometida"] = "";
		query["prioridad"] = "";

		pagination.page = 0;
		if (!aux) query[key] = "asc";
		else if (aux === "desc") { query[key] = "asc"; }
		else { query[key] = "desc"; }

		setSortQuery(query);
	};

	const handleFilterReset = () => {
		setPagination(PAGINATION_INIT);
		setFilterQuery(FILTER_QUERY_INIT)
		setSortQuery(SORT_QUERY_INIT);
		setDropdownActive(false);
		setSearchActive(false);
		setPersisteFiltros(false)
		setFilterResetInputs(true)
	}

	const handleAlertClick = (filter) => {
		let reset = false;
		let query = { ...filterQuery };
		query['filtroKpi'] = reset ? "" : filter;
		setFilterQuery(query);
	}

	const handleModalExportOpen = () => {
		setModalExport(true)
	};

	const handleModalExportClose = () => {
		setModalExport(false)
	};

	const handleModalHistoricoExportOpen = () => {
		setModalExportHistorico(true)
	};

	const handleModalHistoricoExportClose = () => {
		setModalExportHistorico(false)
	};

	const handleFilter = (filter) => {
		let query = { ...FILTER_QUERY_INIT }
		let cpyquery = { ...filterQuery };
		if (cpyquery['ticketTipo'] === filter || (filter === 'EN_TALLER' && cpyquery['enTaller'] === true)) {
			query["ticketTipo"] = 'DEFAULT';
			query['enTaller'] = '';
		}
		else
			if (filter === 'EN_TALLER')
				query['enTaller'] = true;
			else
				query["ticketTipo"] = filter;
		setFilterQuery(query);
		setCardKpi(filter);
	}

	const handleExportComentariosPDF = async () => {
		const mostrarComentarios = false;

		const tablaBody = [];

		const rowData = tickets;
		// Obtener columnas desde Datatable
		/*
		const columnas = dt.settings().init().columns;
		const columnasSeleccionadas = Object.entries(columnas).filter(([i,v])=> dt.column(i).visible() && v.title);
		const tablaTitleProp = columnasSeleccionadas.map(([i,v])=>v.title);
		const tablaDataProp = columnasSeleccionadas.map(([i,v])=>v.data);
		*/
		const tablaTitleProp = Object.entries(tickets);
		const tablaDataProp = Object.values(tickets);

		let ticketComentarios = null;
		if (mostrarComentarios) {
			//Obtiene todos los comentarios de los ticketsId
			const rowsWithComments = rowData.filter((el) => el.comentario !== null).map((item) => item.id);
			ticketComentarios = await Promise.all(
				rowsWithComments.map(async (_ticketId) => {
					let _res = await this.ajaxHandler.getJson(`/ticket-comentarios/ticket/${_ticketId}/grid`)
					return {
						id: _ticketId,
						comentarios: _res.map(v => v.comentario)
					}
				})
			);
		}

		tablaBody.push(
			tickets[0].map((v, k) => {
				Object.entries(v).map(val => {
					return { text: 'Titulo', style: 'tabla_header' }
				})
			})
		);

		rowData.forEach((el) => {
			let fila = tablaDataProp.map(v => '');
			Object.entries(el).forEach(([i, v]) => {
				let obj = { style: 'tabla_row', text: v };
				if (tablaDataProp.includes(i)) {
					switch (i) {
						case 'fechaHoraAlta':
							obj.text = Timezone.getDateForClient(v, 'YYYY-MM-DD HH:mm:ss', 'L');
							break;
						case 'servicio':
							obj.text = v.nombre;
							break;
						case 'tipoEntidadColumnGrid':
							obj.text = v.descripcion;
							break;
						case 'gerenciador':
							obj.text = (v === null) ? '' : v.razonSocial;
							break;
						case 'base':
							obj.text = v.descripcion;
							break;
						case 'pais':
							obj.text = v.nombre;
							break;
						case 'subRegion':
							obj.text = v.nombre;
							break;
						default:
							obj.text = (v === null) ? '' : v;
							break;
					}
					fila[tablaDataProp.indexOf(i)] = obj;
				}
			});
			tablaBody.push(fila);

			// Adjunta comentarios
			if (mostrarComentarios && ticketComentarios != null) {
				if (el.comentario) {
					const row_comentarios = ticketComentarios.filter((v) => v.id === el.id).map(v => v.comentarios).shift();
					if (row_comentarios) {
						tablaBody.push([
							'',
							{ style: 'comentario', colSpan: tablaTitleProp.length - 1, text: row_comentarios.join('\n') },
						]);
					}
				}
			}
		});

		const file = {
			pageSize: {
				width: 842,
				height: 595
			},
			pageMargins: [30, 30, 30, 30],
			content: [
				{
					columns: [
						{
							alignment: 'left',
							image: LogoCompany,
							fit: [100, 100]
						},
						{
							alignment: 'right',
							image: LogoVecFleet,
							fit: [40, 40]
						},
					]
				},
				{
					text: intl.formatMessage({ id: 'ticketsGrid.print_content.header_listado_de_perfiles', defaultMessage: 'Listado de Tickets' }),
					style: 'header'
				},
				{
					style: 'tabla',
					table: {
						widths: tablaDataProp.map(col => (col === 'detalle') ? '*' : 'auto'),
						heights: 15,
						body: tablaBody
					},
					layout: 'noBorders'
				},
			],
			styles: {
				tabla: {
					margin: [0, 0, 0, 0]
				},
				tabla_header: {
					bold: true,
					color: 'white',
					fillColor: '#1D0D38'
				},
				tabla_row: {
					color: 'gray'
				},
				comentario: {
					bold: true,
				},
				header: {
					fontSize: 13,
					bold: true,
					margin: [0, 0, 0, 10]
				},
			},
			defaultStyle: {
				alignment: 'left',
				fontSize: 10
			}
		};

		pdfMake.createPdf(file).open();
	}

	const generacionMasiva = ConfigBusiness.get('tickets.generacionMasiva.habilitado') === 'true' ? true : false
	const [selectedTickets, setSelectedTickets] = useState([])
	const [allChecked, setAllCheked] = useState(false)
	const [showPopup, setShowPopup] = useState(false)
	const [ticketsAccionesMasivas, setTicketsAccionesMasivas] = useState({})
	const [amLoader, setAMLoader] = useState(false)

	const handleChecked = ticketId => e => {
		let selected = [...selectedTickets]

		if (ticketId == 'all') {
			selected = tickets.map(ticket => isTicketGridTable ? ticket.ticket_id : ticket.id)
			setAllCheked(true)

		} else if (ticketId == 'none') {
			selected = []
			setAllCheked(false)

		} else {
			let idx = selected.indexOf(ticketId)

			if (selected.includes(ticketId)) selected.splice(idx, 1)
			else selected.push(ticketId)
			setAllCheked(true)
		}
		setSelectedTickets(selected);
	}

	const checkTicketActions = () => {
		setAMLoader(true)
		Utils.sendData('/tickets/checkTicketActions/false', 'POST', selectedTickets)
			.then((data) => {
				setShowPopup(true);
				setTicketsAccionesMasivas(data);
				setAMLoader(false)
			}).catch((err) => {
				console.log(err)
			})
	}

	/**
	 * Responde siempre que el filtro cambie
	 *  - acutaliza paginado en pagina 0
	 *  - otros
	 */
	const handleFilterChange = () => {
		//actualiza paginado a pagina inicial (0) 
		setPagination(prevState => ({ ...prevState, page: 0 }));
		//otros
		//...`
	}

	const [isTicketImportModalOpen, setIsTicketImportModalOpen] = useState(false);
	const [isTicketImportReportOpen, setIsTicketImportReportOpen] = useState(false);
	const [reporteImportacion, setReporteImportacion] = useState({ faltantes: [], mensajeImportacion: '' });


	const handleCloseTicketsImportReportModal = () => {
		setIsTicketImportReportOpen(!isTicketImportReportOpen);
	};

	const handleCloseTicketsImport = (message, faltantes = [], error = {}) => {

		setIsTicketImportModalOpen(!isTicketImportModalOpen);

		if (message !== "" && error) {
			if (error.codigo === 4010) {
				swal(
					this.props.intl.formatMessage({
						id: "preventivosGrid.alert.sesion_expiro",
						defaultMessage: "La sesión expiró"
					}),

					this.props.intl.formatMessage({
						id: "preventivosGrid.alert.ingrese_nuevamente",
						defaultMessage: "Por favor, ingrese nuevamente."
					}),

					"error"
				).then(() => {
					window.location.reload();
				});

			}

			else if (error.codigo) {
				swal(
					this.props.intl.formatMessage({
						id: "preventivosGrid.alert.error",
						defaultMessage: "Error"
					}),

					message,
					"error"
				);

			}

			else {
				swal(message, error, "error");
			}
		}

		else {
			if (faltantes["filas_fallaron"].length === 0) {
				swal(message, "", "success").then(() => {
					window.location.reload();
				});

			} else {

				// mostrar reporete de errores de importacion
				setReporteImportacion(faltantes);
				setIsTicketImportReportOpen(true);
			}
		}
	}

	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			{estadisticas &&
				<>
					<TicketCards
						unclickable={showPopup}
						estadisticas={estadisticas}
						handleFilter={handleFilter}
					/>
					<br></br></>
			}
			{/* modal importador de tickets cerrados */}
			<Modal
				scrollable={true}
				open={isTicketImportModalOpen}
				onClose={handleCloseTicketsImport}
				closeAfterTransition
				BackdropProps={{
					timeout: 1000,
				}
				}
			>
				<Grow in={isTicketImportModalOpen}>
					<div className="col-8" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
						<div className="modal-xl modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header bg-fleet">
									<h4 className="modal-title text-white" id="myModalLabel1">
										<FormattedMessage id="ticketsGrid.tools.import.button.importar" defaultMessage=" Importar Ticket Cerrado" />
									</h4>
									<button type="button" className="close" onClick={() => handleCloseTicketsImport()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
								</div>
								<div className="modal-body modal-grey">
									<form className="form">
										<TicketsCerradosImportar
											handleClose={handleCloseTicketsImport}
											callbackClose={handleCloseTicketsImport}
											callbackCancel={handleCloseTicketsImport}
										/>
									</form>
								</div>
							</div>
						</div>
					</div>
				</Grow>
			</Modal>
			{/* modal reporte de importador de tickets cerrados */}
			<Modal
				open={isTicketImportReportOpen}
				onClose={handleCloseTicketsImportReportModal}
				closeAfterTransition
				BackdropProps={{
					timeout: 1000,
				}
				}>
				<Grow in={isTicketImportReportOpen}>
					<div className="col-8" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
						<div className="modal-xl modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header bg-fleet">
									<h4 className="modal-title text-white" id="myModalLabel1">
										<FormattedMessage id="ticketsGrid.tools.import.button.reporte_filas_fallaron" defaultMessage=" Reporte filas que fallaron " />
									</h4>
									<button type="button" className="close" onClick={() => handleCloseTicketsImportReportModal()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
								</div>
								<div className="modal-body modal-grey">
									<form className="form">
										<TicketsCerradosImportarReporte
											data={reporteImportacion}
											callbackClose={handleCloseTicketsImportReportModal}>
										</TicketsCerradosImportarReporte>
									</form>
								</div>
							</div>
						</div>
					</div>
				</Grow>
			</Modal>

			<TicketsGridExport
				open={modalExport}
				openHistorico={modalExportHistorico}
				handleClose={handleModalExportClose}
				handleModalHistoricoExportClose={handleModalHistoricoExportClose}
				queryFilters={filterQuery} />
			<div className={showPopup ? "card unclickable" : "card"}>
				<div className="card-content collpase show">
					<div className="card-body card-dashboard">
						<div className="container-fluid mb-1">
							<div className="row dt-icons">
								<div className="col">
									{
										!columnsEnabled.mantenimientosGestoria ?
											(
												<StyledTooltip
													title={intl.formatMessage({
														id: 'ticketsGrid.render.add_tickets.button_title_nuevo',
														defaultMessage: 'Nuevo',
													})}
													arrow
													placement="right"
												>
													<div
														className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
														style={{ height: 55 }}
														onClick={() => setRedirectTo('../correctivos/add')}
													>
														<i className="ft-plus"></i>
													</div>
												</StyledTooltip>
											)
											:
											(
												<>
													{
														permissions.ticketsCrear &&
														<StyledTooltip
															title={intl.formatMessage({
																id: 'movilesGrid.render.button_add.title_nuevo',
																defaultMessage: 'Nuevo',
															})}
															arrow
															placement="right"
														>
															<div
																className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
																style={{ height: 55 }}
																onClick={() => setRedirectTo(props.match.url + '/add')}
															>
																<i className="ft-plus"></i>
															</div>
														</StyledTooltip>
													}
													{
														permissions.correctivosCrear &&
														<StyledTooltip
															title={intl.formatMessage({
																id: 'ticketsGrid.render.add_tickets.button_title_nuevo_ticket_correctvo',
																defaultMessage: 'Nuevo Ticket Correctvo',
															})}
															arrow
															placement="right"
														>
															<div
																className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
																style={{ height: 55 }}
																onClick={() => setRedirectTo('../correctivos/add')}
															>
																<i className="ft-plus"></i>
															</div>
														</StyledTooltip>
													}
													{
														permissions.gestoriaCrear &&
														<StyledTooltip
															title={intl.formatMessage({
																id: 'ticketsGrid.render.add_tickets.button_title_nuevo_ticket_de_gestoria',
																defaultMessage: 'Nuevo Ticket de Gestoría',
															})}
															arrow
															placement="right"
														>
															<div
																className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
																style={{ height: 55 }}
																onClick={() => setRedirectTo('../ticketsgestoria/add')}
															>
																<i className="ft-plus"></i>
															</div>
														</StyledTooltip>
													}
												</>
											)
									}
									<div
										className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
										style={{ height: 52 }}
										onClick={() => setDropdownActive(!dropdownActive)}
									>
										{dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
									</div>
								</div>
								<div className="col">
									{searchActive && (
										<div className="btn-dt-main round-icon">
											<div
												className="text-center"
												style={{
													height: '25px',
													borderRadius: '3px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingLeft: '8px',
													backgroundColor: '#5AAC43',
													color: 'white',
												}}
											>
												<div
													className="text-center"
													style={{
														width: '100%',
														fontSize: '12px',
														fontWeight: '500',
													}}
												>
													{tickets.length} de {paginationCount} resultados de busqueda
												</div>
												<div className="resultados-busqueda-notif">
													<i
														className="las la-times la-xs cursor-pointer"
														onClick={() => handleFilterReset()}
													></i>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="col">
									<ButtonsTableTop
										intl={intl}
										permissions={permissions}
										handleModalExportOpen={handleModalExportOpen}
										handleModalHistoricoExportOpen={handleModalHistoricoExportOpen}
										handleFilterReset={handleFilterReset}
										handleExportComentariosPDF={handleExportComentariosPDF}
										handleCloseTicketsImport={handleCloseTicketsImport}
										isTicketGridTable={isTicketGridTable}
									/>
								</div>
							</div>
						</div>

						<TicketGridFilters
							columnsEnabled={columnsEnabled}
							dropdownActive={dropdownActive}
							setDropdownActive={setDropdownActive}
							persisteFiltros={persisteFiltros}
							setPersisteFiltros={setPersisteFiltros}
							filterResetInputs={filterResetInputs}
							setFilterResetInputs={setFilterResetInputs}
							filterQuery={filterQuery}
							setFilterQuery={setFilterQuery}
							searchActive={searchActive}
							setSearchActive={setSearchActive}
							pagination={pagination}
							cardKpi={cardKpi}
							handleAlertClick={handleAlertClick}
							onFilterChange={handleFilterChange}
							loading={loading}
						/>

						<TableContainer className={showPopup ? "min-height-35" : ''}>
							<Table aria-label="clases table" className="table-hover">
								{(generacionMasiva && tickets.length > 0 && selectedTickets.length > 0) ? (
									<TableHead>
										<TableRow>
											<StyledTableCell show={generacionMasiva && selectedTickets.length > 0} colSpan={18}>
												<input type="checkbox" checked={allChecked} onChange={handleChecked('none')} style={{ marginRight: "15px" }} className="customCheckbox" />
												<span style={{ marginRight: "10px" }}>{selectedTickets.length}</span>
												<FormattedMessage id="ticketsGrid.render.generacionMasiva.seleccionados" defaultMessage="Seleccionados " />
												|

												<StyledTooltip
													title={intl.formatMessage({
														id: 'render.generacionMasiva.cambio_de_estado',
														defaultMessage: 'Cambiar Estado',
													})}
													arrow
													placement="top"
												>
													<div style={{ marginLeft: "10px", position: 'relative', display: 'inline-block' }}>
														<span
															type="button"
															onClick={checkTicketActions}
															className="fa-stack fa-lg cursor-pointer"
														>
															<i className="fa fa-circle fa-stack-2x cursor-pointer"></i>
															<i className="fa fa-arrow-right fa-1_4x fa-stack-1x fa-inverse" style={{ color: "#241b3e" }}></i>
														</span>

														<AccionesMasivas
															show={showPopup}
															setShow={setShowPopup}
															tickets={ticketsAccionesMasivas}
															amLoader={amLoader}
															setAMLoader={setAMLoader}
															fetchTickets={fetchTickets}
															setSelectedTickets={setSelectedTickets}
														/>

													</div>
												</StyledTooltip>
											</StyledTableCell>
										</TableRow>
									</TableHead>
								) : (
									<TableHead>
										<TableRow>
											<StyledTableCell show={generacionMasiva && selectedTickets.length == 0} style={{ maxWidth: "22px" }}>
												<StyledTooltip
													title={intl.formatMessage({
														id: 'render.generacionMasiva.seleccionar_todos',
														defaultMessage: 'Seleccionar Todos',
													})}
													arrow
													placement="top"
												>
													<input type="checkbox" checked={allChecked} onChange={handleChecked('all')} style={{ marginRight: "15px" }} className="customCheckbox" />
												</StyledTooltip>
											</StyledTableCell>
											{/* ID */}
											<StyledTableCell align="left" onClick={() => requestSort("ticketTipo")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_type.label_tipo" defaultMessage="Tipo" />
												<img className="ml-1"
													src={sortQuery.ticketTipo === "" ? Sort : sortQuery.ticketTipo === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("id")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_Tkt.label_tkt" defaultMessage="Tkt" />
												<img className="ml-1"
													src={sortQuery.id === "" ? Sort : sortQuery.id === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("indicador")} style={{ cursor: "pointer" }}>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.estado} align="left" onClick={() => requestSort("estado")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_state.label_estado" defaultMessage="Estado" />
												<img className="ml-1"
													src={sortQuery.estado === "" ? Sort : sortQuery.estado === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.prioridad} align="left" onClick={() => requestSort("prioridad")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_priority.label_prioridad" defaultMessage="Prioridad" />
												<img className="ml-1"
													src={sortQuery.prioridad === "" ? Sort : sortQuery.prioridad === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.nivelAprobacionPresupuesto} align="left" onClick={() => requestSort("nivelAprobacionPresupuesto")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_state.label_nivelAprobacionPresupuesto" defaultMessage="Nivel Aprobación" />
												<img className="ml-1"
													src={sortQuery.nivelAprobacionPresupuesto === "" ? Sort : sortQuery.nivelAprobacionPresupuesto === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("fechaHoraAlta")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_create.label_creado" defaultMessage="Creado" />
												<img className="ml-1"
													src={sortQuery.fechaHoraAlta === "" ? Sort : sortQuery.fechaHoraAlta === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("detalle")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_detail.label_detalle" defaultMessage="Detalle" />
												<img className="ml-1"
													src={sortQuery.detalle === "" ? Sort : sortQuery.detalle === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("servicio")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_service.label_servicio" defaultMessage="Servicio" />
												<img className="ml-1"
													src={sortQuery.servicio === "" ? Sort : sortQuery.servicio === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("entidad")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_entity.label_entidad" defaultMessage="Entidad" />
												<img className="ml-1"
													src={sortQuery.entidad === "" ? Sort : sortQuery.entidad === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("gerenciador")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_gerenciador.label_gerenciador" defaultMessage="Gerenciador" />
												<img className="ml-1"
													src={sortQuery.gerenciador === "" ? Sort : sortQuery.gerenciador === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.mostrarTotalPresupuesto} align="left">
												<FormattedMessage id="ticketsGrid.column_total.label_total" defaultMessage="Total del Presupuesto" />
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.region} align="left" onClick={() => requestSort("region")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_region.label_region" defaultMessage="Región" />
												<img className="ml-1"
													src={sortQuery.region === "" ? Sort : sortQuery.region === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("subRegion")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_region.label_subRegion" defaultMessage="SubRegión" />
												<img className="ml-1"
													src={sortQuery.subRegion === "" ? Sort : sortQuery.subRegion === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("base")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_base.label_base" defaultMessage="Base" />
												<img className="ml-1"
													src={sortQuery.base === "" ? Sort : sortQuery.base === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.pais} align="left" onClick={() => requestSort("pais")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_pais.label_pais" defaultMessage="Pais" />
												<img className="ml-1"
													src={sortQuery.pais === "" ? Sort : sortQuery.pais === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.centroCostos} align="left" onClick={() => requestSort("centroCostos")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_cost_center.label_centro_de_costos" defaultMessage="Centro de Costos" />
												<img className="ml-1"
													src={sortQuery.centroCostos === "" ? Sort : sortQuery.centroCostos === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.centroBeneficios} align="left" onClick={() => requestSort("centroBeneficios")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_titular.label_titular" defaultMessage="Titular" />
												<img className="ml-1"
													src={sortQuery.centroBeneficios === "" ? Sort : sortQuery.centroBeneficios === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("responsable1")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="Responsable" defaultMessage="Responsable" />
												<img className="ml-1"
													src={sortQuery.companiaOrigen === "" ? Sort : sortQuery.companiaOrigen === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("enTaller")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="Taller" defaultMessage="Taller" />
												<img className="ml-1"
													src={sortQuery.enTaller === "" ? Sort : sortQuery.enTaller === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.fechaPrometida} align="left" onClick={() => requestSort("fechaPrometida")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="Fecha_promesa" defaultMessage="Fecha promesa" />
												<img className="ml-1"
													src={sortQuery.fechaPrometida === "" ? Sort : sortQuery.fechaPrometida === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.diasReparacion} align="left" style={{ cursor: "pointer" }}>
												<FormattedMessage id="Dias_reparacion" defaultMessage="Días de Reparación" />
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("mttTotal")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_TT.label_TT" defaultMessage="TT" />
												<img className="ml-1"
													src={sortQuery.mttTotal === "" ? Sort : sortQuery.mttTotal === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("mttParcial")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_TP.label_TP" defaultMessage="TP" />
												<img className="ml-1"
													src={sortQuery.mttParcial === "" ? Sort : sortQuery.mttParcial === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.encuestaSatisfaccion} align="left" onClick={() => requestSort("encuestaNivelSatisfaccion")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_encuesta.label_encuesta" defaultMessage="Satisfacción" />
												<img className="ml-1"
													src={sortQuery.encuestaNivelSatisfaccion === "" ? Sort : sortQuery.encuestaNivelSatisfaccion === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.fechaRealizado} align="left" onClick={() => requestSort("fechaRealizado")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_realized.label_realizado" defaultMessage="Realizado" />
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.fechaHoraTurno} align="left" onClick={() => requestSort("fechaHoraTurno")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_state.label_fecha_hora_turno" defaultMessage="Turno" />
												<img className="ml-1"
													src={sortQuery.fechaHoraTurno === "" ? Sort : sortQuery.fechaHoraTurno === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell show={columnsEnabled.abono} align="left" onClick={() => requestSort("abono")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_abono.label_abono" defaultMessage="Abono" />
												<img className="ml-1"
													src={sortQuery.abono === "" ? Sort : sortQuery.abono === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left" onClick={() => requestSort("tipoModelo")} style={{ cursor: "pointer" }}>
												<FormattedMessage id="ticketsGrid.column_tipo_modelo.lable_tipo_modelo" defaultMessage="Tipo Modelo" />
												<img className="ml-1"
													src={sortQuery.tipoModelo === "" ? Sort : sortQuery.tipoModelo === "asc" ? SortAsc : SortDesc}
													alt={"/"}
													style={{ height: "12px", width: "22px" }}
												/>
											</StyledTableCell>
											<StyledTableCell align="left"><FormattedMessage id="movilesGrid.rennder.column_acciones.label" defaultMessage="Acciones" /></StyledTableCell>
										</TableRow>
									</TableHead>
								)}
								<TableBody >
									{loading && (
										<TableRow>
											<StyledTableCell colSpan={13} align="center">
												<CircularProgress />
											</StyledTableCell>
										</TableRow>
									)}
									{!loading &&
										(tickets.length > 0 ?
											tickets.map((ticket) => (
												isTicketGridTable ? (
													<TicketsGridTableRow
														columnsEnabled={columnsEnabled}
														permissions={permissions}
														key={'ticketsGridFullRow_' + ticket.id}
														baseUrl={props.match.url}
														ticketUrl={`${props.match.url}/${ticket.ticket_id}/edit`}
														ticket={ticket}
														ticketEstado={ticketsEstadoStrings[ticket.estado]}
														ticketTipo={ticketsTipoStrings[ticket.tipo]}
														generacionMasiva={generacionMasiva}
														selectedTickets={selectedTickets}
														setSelectedTickets={setSelectedTickets}
														handleChecked={handleChecked}
													/>
												) : (
													<TicketsGridRow
														columnsEnabled={columnsEnabled}
														permissions={permissions}
														key={'ticketsGridRow_' + ticket.id}
														baseUrl={props.match.url}
														ticketUrl={`${props.match.url}/${ticket.id}/edit`}
														ticket={ticket}
														ticketEstado={ticketsEstadoStrings[ticket.estado]}
														ticketTipo={ticketsTipoStrings[ticket.ticket_tipo]}
														generacionMasiva={generacionMasiva}
														selectedTickets={selectedTickets}
														setSelectedTickets={setSelectedTickets}
														handleChecked={handleChecked}
													/>

												)
											))
											:
											<TableRow>
												<StyledTableCell colSpan={18} align="center">
													<FormattedMessage id="movilesGrid.rennder.no_data_table" defaultMessage="No se encontraron resultados..." />
												</StyledTableCell>
											</TableRow>
										)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={rowsPerPage}
							component="div"
							count={paginationCount}
							rowsPerPage={pagination.perPage}
							page={pagination.page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage="Mostrar"
							labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
						/>
					</div>
				</div>
			</div>

		</>
	)
}

const ButtonsTableTop = (props) => {
	const intl = props.intl
	const permissions = props.permissions
	return (
		<div className="dt-buttons btn-group">
			{props.isTicketGridTable ?
				<StyledTooltip
					title="Grilla en test"
					arrow
					placement="top"
				>
					<button className="btn btn-secondary buttons-collection" >
						<i class="fas fa-user-secret"></i>
					</button>
				</StyledTooltip>
			: null}
			<StyledTooltip
				title={intl.formatMessage({ id: 'ticketsGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' })}
				arrow
				placement="top"
			>
				<button onClick={() => props.handleFilterReset()} className="btn btn-secondary buttons-collection" type="button">
					<span>
						<img id="filterReset" className="filter-reset" src={LimpiarFiltros}></img>
					</span>
				</button>
			</StyledTooltip>
			{/*
		<StyledTooltip
		  title={intl.formatMessage({ id: 'ticketsGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })}
		  arrow
		  placement="top"
		>
		  <button onClick={()=> props.handleExportComentariosPDF()} className="btn btn-secondary buttons-collection" type="button">
			<span>
				<i class="ft-printer"></i>
			</span>
		  </button>
		</StyledTooltip>
		 */}
			<div className="dropdown">
				<StyledTooltip
					title={intl.formatMessage({
						id: 'movilesGrid.tools_export.title_exportar_a_excel',
						defaultMessage: 'Exportar a Excel',
					})}
					arrow
					placement="top"
				>
					<button className="btn buttons-collection" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<span>
							<i className="ft-upload"></i>
						</span>
					</button>
				</StyledTooltip>
				<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
					<button style={{ fontSize: '15px' }} onClick={() => props.handleModalExportOpen()} className="btn btn-secondary buttons-collection" type="button">
						<span>
							<FormattedMessage id="ticketsGrid.render.export_modal.header_exportar_tickets" defaultMessage="Exportar Tickets" />
						</span>
					</button>
					<button style={{ fontSize: '15px' }} onClick={() => props.handleModalHistoricoExportOpen()} className="btn btn-secondary buttons-collection" type="button">
						<span>
							<FormattedMessage id="ticketsGrid.tools_export.title_export_historico" defaultMessage="Exportar Histórico de Estados de Tickets" />
						</span>
					</button>
				</div>
			</div>
			{Security.hasPermission('TICKETS_IMPORTAR_CERRADOS') ? (
				<StyledTooltip
					title={intl.formatMessage({
						id: 'ticketsGrid.render.importar.button_ticket_importar_tickets',
						defaultMessage: 'Importar ticket cerrado',
					})}
					arrow
					placement="top"
				>
					<button onClick={() => props.handleCloseTicketsImport()} className="btn btn-secondary buttons-collection" type="button">
						<span>
							<span>
								<i className="ft-download"></i>
							</span>
						</span>
					</button>
				</StyledTooltip>
			) : ''}

		</div>
	)
}






export default TicketsGrid
