import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Switch from "react-switch"
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css';
import Loading from '../ui/Loading.js';
import 'react-select/dist/react-select.css';
import { useIntl } from 'react-intl';
import * as Utils from '../../commons/utils/Utils';
import Security from '../../commons/security/Security.js';
// import {Select, MenuItem} from '@material-ui/core';
import Select from 'react-select';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';


const InsumosMarcasAbm = (props) => {
    const intl = useIntl();
    const [redirectTo, setRedirectTo] = useState(null);
    const [formData, setFormData] = useState({
        id: 0,
        nombre: '',
        activa: true,
        categoria: []
    });
    const [categoriasCombo, setCategoriasCombo] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (((Security.hasPermission('ITEMS_MARCAS_CREAR') && props.action === 'ADD') ||
			(Security.hasPermission('ITEMS_MARCAS_MODIFICAR') && props.action === 'EDIT') ||
			(Security.hasPermission('ITEMS_MARCAS_VISUALIZAR') && props.action === 'VIEW')) &&
            ConfigBusiness.get('panol.marca.habilitado') === 'true') {
            initForm();
		} else {
			setRedirectTo('/error');
		}
    }, [props.action]);

    const initForm = () => {
        setLoading(true);
        if (props.action !== 'ADD') {
            const insumos = Utils.getData(`/panol/insumomarca/${props.match.params.id}`);
            const categorias = props.showCategoria ? Utils.getData(`/panol2/categorias/select`) : [];
            Promise.all([insumos, categorias])
            .then(data => {
                setFormData(data[0])
                setCategoriasCombo(data[1])
            })
            .catch(err => {
                exit();
            })
            .finally(() => {
                setLoading(false)
            })
        } else {
            Utils.getData(`/panol2/categorias/select`)
                .then(data => {
                    if (data) {
                        setCategoriasCombo(data);
                    } else {
                        exit();
                    }
                })
            .catch(err => exit())
            .finally(() => setLoading(false));
        }
    }

    const handleInputNombreChange = (e) => {
        e.persist()
        setFormData((prev) => ({
            ...prev,
            nombre: e.target.value
        }));
    };

    const handleInputActivaChange = (estado) => {
        setFormData((prev) => ({
            ...prev,
            activa: estado
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        Utils.sendData(`/panol/insumomarca${props.action !== 'ADD' ? '/' + formData.id : ''}`, props.action === 'ADD' ? 'POST' : 'PUT', formData)
            .then(response => {
                if (response) {
                    setLoading(false);
                    exit();
                };
            })
            .catch(err => console.log(err))
            .finally(() => exit());
    };

    const handleCancel = () => {
        exit();
    };

    const exit = () => {
        setRedirectTo('/insumos_marcas');
    };

    const handleSelectCategorias = (e) => {
        const selectedIds = e.map(e => e.id);
        const selectedCategorias = categoriasCombo.filter(cat => selectedIds.includes(cat.id));
            
        setFormData({
            ...formData,
            categoria: selectedCategorias,
        });
    };

    return (
        <React.Fragment>
            {redirectTo && <Redirect push to={redirectTo} />}
            {loading && <Loading />}
            <div className="row">
                <div className="col-md-12">
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="card pull-up">
                                <div className="card-content">
                                    <div className="card-body">
                                        <h4 className="form-section">
                                            <i className="icon-home"></i>
                                            {intl.formatMessage({
                                                id: 'insumoMarcaAbm.render.general_data.header_datos_generales',
                                                defaultMessage: 'Datos Generales',
                                            })}
                                            <div
                                                className="float-right"
                                                style={{ fontSize: '14px' }}
                                            >
                                                *{' '}
                                                {intl.formatMessage({
                                                    id: 'insumoMarcaAbm.render.general_data.campos_requeridos',
                                                    defaultMessage: 'campos requeridos',
                                                })}
                                            </div>
                                        </h4>
                                        <div className="row">
                                            {/* Marca */}
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label
                                                        className="col-md-3 label-control col-form-label"
                                                        htmlFor="nombre"
                                                    >
                                                        {intl.formatMessage({
                                                            id: 'insumoMarcaAbm.render.general_data.marca',
                                                            defaultMessage: 'Marca',
                                                        })}
                                                        *:
                                                    </label>
                                                    <div className="col-md-9">
                                                        {props.action === 'VIEW' ? (
                                                            <div className="form-control-static col-form-label form-value">
                                                                {formData.nombre}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="nombre"
                                                                    name="nombre"
                                                                    placeholder={intl.formatMessage({
                                                                        id: 'insumoMarcaAbm.render.general_data.marca',
                                                                        defaultMessage: 'Marca',
                                                                    })}
                                                                    value={formData.nombre}
                                                                    onChange={handleInputNombreChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* COMBO CATEGORIAS */}
                                        {props.showCategoria ?
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label
                                                            className="col-md-3 label-control col-form-label"
                                                            htmlFor="categoria"
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'insumoMarcaAbm.render.general_data.categoria',
                                                                defaultMessage: 'Categorias',
                                                            })}
                                                            *:
                                                        </label>
                                                        {props.action === 'VIEW' ?
                                                            (<p style={{alignSelf: 'flex-end', marginBottom: '10px'}}>{formData.categoria ? formData.categoria.map(c => (c.nombre)).join('; ') : 'no hay categorias'}</p>)
                                                            :
                                                            (<div className="col-md-9">
                                                                <Select
                                                                    id="categoria"
                                                                    name="categoria"
                                                                    placeholder={intl.formatMessage({
                                                                        id: 'insumoMarcaAbm.render.general_data.selec_categoria',
                                                                        defaultMessage: 'Seleccione categorías',
                                                                    })}
                                                                    options={categoriasCombo}
                                                                    valueKey="id"
                                                                    labelKey="nombre"
                                                                    multi={true}
                                                                    value={formData.categoria.map(c => c.id)}
                                                                    onChange={handleSelectCategorias}
                                                                />
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                        }
                                        {/* ESTADO */}
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-md-3 label-control col-form-label" htmlFor="activa">
                                                    {intl.formatMessage({
                                                        id: 'insumoMarcaAbm.render.general_data.activa',
                                                        defaultMessage: 'Activa',
                                                    })}
                                                </label>
                                                <div className="col-md-9">
                                                    <Switch
                                                        onChange={handleInputActivaChange}
                                                        checked={formData.activa ? true : false}
                                                        id="activa"
                                                        name="activa"
                                                        disabled={props.action === 'VIEW' ? true : false}
                                                        offColor="#FF4961"
                                                        onColor="#28D094"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card pull-up">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="text-cd text-right">
                                            {props.action !== 'VIEW' && (
                                                <button
                                                    onClick={handleSubmit}
                                                    className="btn btn-primary mr-1"
                                                >
                                                    <i className="fa fa-check-circle"></i>
                                                    {intl.formatMessage({
                                                        id: 'insumoMarcaAbm.render.guardar',
                                                        defaultMessage: 'Guardar',
                                                    })}
                                                </button>
                                            )}
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={handleCancel}
                                            >
                                                <i className="fa fa-times-circle"></i>{' '}
                                                {props.action === 'VIEW'
                                                    ? intl.formatMessage({
                                                        id: 'insumoMarcaAbm.render.volver',
                                                        defaultMessage: 'Volver',
                                                    })
                                                    : intl.formatMessage({
                                                        id: 'insumoMarcaAbm.render.cancelar',
                                                        defaultMessage: 'Cancelar',
                                                    })}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default InsumosMarcasAbm;
