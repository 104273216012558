import React, { useEffect, useState } from "react";
import {
	CircularProgress,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import 'xlsx/dist/xlsx.full.min.js';
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import Sort from "../../assets/images/llantas/table/sort.svg";
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles.js';
import * as Utils from '../../commons/utils/Utils.js';
import ItemCategoriaGridRow from './ItemCategoriaGridRow.jsx';
import ItemCategoriaGridFiltros from './ItemCategoriaGridFiltros.jsx';
import Security from "../../commons/security/Security.js";

const PAGINATION_INIT = {
	page: 0,
	count: 0,
	perPage: 10,
}

const FILTER_QUERY_INIT = {
	nombre: "",
	showInactivos: false
}

const SORT_QUERY_INIT = {
	nombre: "",
	id: "desc"
}

const ItemCategoriaGrid = (props) => {
	const intl = useIntl()

	const [loading, setLoading] = useState(false)
	const [redirectTo, setRedirectTo] = useState(null)

	const [searchActive, setSearchActive] = useState(false)
	const [pagination, setPagination] = useState({ ...PAGINATION_INIT })
	const [count, setCount] = useState(0)

	const [filterResetInputs, setFilterResetInputs] = useState(false)
	const [filterQuery, setFilterQuery] = useState({ nombre: '', showInactivos: false })

	const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT })

	const [dropdownActive, setDropdownActive] = useState(false);

	const [categorias, setCategorias] = useState([]);
	
	const [permissions,setPermissions] = useState({
		itemsCategoriasCrear:false,
		itemsCategoriasVisualizar:false,
		itemsCategoriasModificar:false,
		itemsCategoriasEliminar:false,
	});

	useEffect(() => {
		setLoading(true)
		if (Security.hasPermission("ITEMS_CATEGORIAS_LISTAR")) {
			securityPermission();
			filterQuery && fetchCategorias();

		} else {
			setRedirectTo("/error")
		}

		return () => { }
	}, [pagination, filterQuery, sortQuery]);

	const securityPermission = () => {
		let aux = {...permissions}
			aux.itemsCategoriasCrear = Security.hasPermission('ITEMS_CATEGORIAS_CREAR')
			aux.itemsCategoriasModificar = Security.hasPermission('ITEMS_CATEGORIAS_MODIFICAR')
			aux.itemsCategoriasVisualizar = Security.hasPermission('ITEMS_CATEGORIAS_VISUALIZAR')
			aux.itemsCategoriasEliminar = Security.hasPermission('ITEMS_CATEGORIAS_ELIMINAR')
		setPermissions(aux)   
	}

	const fetchCategorias = () => {
		Utils.getData(`/panol2/categorias${getQuery()}`)
		.then((data) => {
			setCategorias(data.categorias);
			setCount(data.pagination.count)
			setLoading(false);
		})
		.catch((err) => {
			setLoading(false)
		})
	};

	const getQuery = () => {

		//pagination
		let query = "?page=" + pagination.page;
		query += "&perPage=" + pagination.perPage;

		//filter
		query += '&nombre=' + filterQuery.nombre;
		query += '&showInactivos=' + filterQuery.showInactivos;

		//sort
		query += '&nombreSort=' + sortQuery.nombre;
		query += '&idSort=' + sortQuery.id;

		return query;
	}

	const handleChangePage = (event, newPage) => {
		setPagination({ ...pagination, page: newPage });
	}

	const handleChangeRowsPerPage = (event) => {
		setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
	}

	const requestSort = (key) => {
		let query = { ...sortQuery }
		let aux = sortQuery[key];

		query["nombre"] = "";
		query["id"] = "";

		pagination.page = 0;

		if (!aux) query[key] = "asc";
		else if (aux === "desc") query[key] = "asc";
		else query[key] = "desc";
		setSortQuery(query);
	};

	const handleFilterReset = () => {
		setPagination(PAGINATION_INIT);
		setFilterQuery(FILTER_QUERY_INIT);
		setDropdownActive(false);
		setSortQuery(SORT_QUERY_INIT);
		setSearchActive(false);
		setFilterResetInputs(true)
	}

	const handleFilterChange = () => {
		setPagination(prevState => ({ ...prevState, page: 0 }));
	}

	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<div className="card">
				<div className="card-content collpase show">
					<div className="card-body card-dashboard">
						<div className="container-fluid mb-1">
							<div className="row dt-icons">
								<div className="col">
									{permissions.itemsCategoriasCrear &&
										<StyledTooltip
										title={intl.formatMessage({
											id: 'Nuevo',
											defaultMessage: 'Nuevo',
										})}
										arrow
										placement="right"
									>
										<div
											className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
											style={{ height: 55 }}
											onClick={() => setRedirectTo(props.match.url + '/add')}
										>
											<i className="ft-plus"></i>
										</div>
									</StyledTooltip>
									}
									<div
										className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
										style={{ height: 52 }}
										onClick={() => setDropdownActive(!dropdownActive)}
									>
										{dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
									</div>
								</div>
								<div className="col">
									{searchActive && (
										<div className="btn-dt-main round-icon">
											<div
												className="text-center"
												style={{
													height: '25px',
													borderRadius: '3px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingLeft: '8px',
													backgroundColor: '#5AAC43',
													color: 'white',
												}}
											>
												<div
													className="text-center"
													style={{
														width: '100%',
														fontSize: '12px',
														fontWeight: '500',
													}}
												>
													{categorias.length} de {count} resultados de busqueda
												</div>
												<div className="resultados-busqueda-notif">
													<i
														className="las la-times la-xs cursor-pointer"
														onClick={() => handleFilterReset()}
													></i>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<ItemCategoriaGridFiltros
							dropdownActive={dropdownActive}
							setDropdownActive={setDropdownActive}
							filterResetInputs={filterResetInputs}
							setFilterResetInputs={setFilterResetInputs}
							filterQuery={filterQuery}
							setFilterQuery={setFilterQuery}
							searchActive={searchActive}
							setSearchActive={setSearchActive}
							pagination={pagination}
							handleFilterChange={handleFilterChange}
						/>
						<TableContainer>
							<Table aria-label="clases table" className="table-hover">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left" onClick={() => requestSort("id")} style={{ width: "50px" }}>
											<FormattedMessage id="Id" defaultMessage="Id" />
											<img className="ml-1"
												src={sortQuery.id === "" ? Sort : sortQuery.id === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>
										<StyledTableCell align="left" onClick={() => requestSort("nombre")} style={{ cursor: "pointer" }}>
											<FormattedMessage id="item_categoria" defaultMessage="Categoría" />
											<img className="ml-1"
												src={sortQuery.nombre === "" ? Sort : sortQuery.nombre === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>
										<StyledTableCell align="center" style={{ width: "150px" }}>
											<FormattedMessage id="ItemCategoria.render.column_acciones.label" defaultMessage="Acciones" />
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading && (
										<TableRow>
											<StyledTableCell colSpan={14} align="center">
												<CircularProgress />
											</StyledTableCell>
										</TableRow>
									)}
									{!loading &&
										(categorias.length > 0 ?
											categorias.map((categoria) => (
												<ItemCategoriaGridRow
													intl={intl}
													key={'GridRow_' + categoria.id}
													baseUrl={props.match.url}
													categoria={categoria}
													fetchCategorias={fetchCategorias}
													permissions={permissions}
												/>
											))
											:
											<TableRow>
												<StyledTableCell colSpan={14} align="center">
													<FormattedMessage id="item_categoria.render.no_data_table" defaultMessage="No se encontraron resultados..." />
												</StyledTableCell>
											</TableRow>
										)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50, 100]}
							component="div"
							count={count}
							rowsPerPage={pagination.perPage}
							page={pagination.page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={intl.formatMessage({ id: 'Mostrar', defaultMessage: 'Mostrar' })}
							labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default ItemCategoriaGrid;