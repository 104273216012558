import React, { Component } from 'react'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Validator from '../../commons/validation/Validator.js'
import CircularProgress from "@material-ui/core/CircularProgress";
import $ from 'jquery'
import swal from 'sweetalert'
import moment from 'moment';

class PreventivoForzar extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            loading : false,
			loadingHandleSubmitPreventivoNext: false,
			loadingHandleSubmitPreventivo: false,
			data: null,
        };

		this.handleChangeDetalle = this.handleChangeDetalle.bind(this);
		this.handleSubmitPreventivoNext = this.handleSubmitPreventivoNext.bind(this);
		this.handleSubmitPreventivo = this.handleSubmitPreventivo.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
		this.handleSelectPreventivoIncorrecto = this.handleSelectPreventivoIncorrecto.bind(this);
    }

    componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		if(this.props.onRef) this.props.onRef(undefined);
		this.setState({
			loadingHandleSubmitPreventivoNext: false,
			loadingHandleSubmitPreventivo: false,
		});
	}

    componentDidMount() {
		this.ajaxHandler = new AjaxHandler();
		this.ajaxHandler.subscribe(this);
		if(this.props.onRef) this.props.onRef(this);
    }

	componentDidUpdate(prevProps, prevState) {
		if (this.props.preventivoId && prevProps.preventivoId !== this.props.preventivoId ) {
			this.props.handleSelectPreventivoIncorrecto(false);
			this.setState({
				loadingHandleSubmitPreventivoNext: false,
				loadingHandleSubmitPreventivo: false,
			});
			this.init();
		}
	}

    init(){
		this.ajaxHandler.subscribe(this);
		this.setState({ loading: true });
		console.log(this.props.preventivoId);
        this.ajaxHandler.getJson('/preventivos/validacionforzar/' + this.props.preventivoId, {
            method: 'GET',
        }).then(response => {
			this.setState({ data: response });
        }).catch(() => {
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.props.callbackCancel();
		this.setState({
			loadingHandleSubmitPreventivoNext: false,
			loadingHandleSubmitPreventivo: false,
		});
    }

    handleSave(event) {
		this.setState({loading: true },()=>this.dropzone.processQueue());
		event.preventDefault();
	}

	handleSelectPreventivoIncorrecto(event){
		this.props.handleSelectPreventivoIncorrecto(event.target.checked);
	}

	handleSubmitPreventivoNext(){
		this.setState({ loadingHandleSubmitPreventivoNext: true });
		this.props.submitForzarPreventivo(this.state.data.preventivoNext)
	}

	handleChangeDetalle(event){
		this.props.handleDetalleForzarPreventivo(event.target.value);
	}

	handleSubmitPreventivo(){
		this.setState({ loadingHandleSubmitPreventivo: true });
		this.props.submitForzarPreventivo(this.state.data.preventivo)
	}

    render() {

        return (
            <React.Fragment>
                { this.state.loading ? 
                    <div align={"center"} colSpan={14}>
                        <CircularProgress />
                    </div>
                    :
						(this.state.data ? <>
							<div className="card pull-up">
								<div className="card-content pl-1 pr-1">
									<div className="card-body">
										<h4 className="form-section">
											<i className="la la-file"></i> 
											<FormattedMessage id="Atención" defaultMessage="Atención"/>{": "}
											<FormattedMessage id="El_servicio_preventivo_que_está_por_forzar" defaultMessage="El servicio preventivo que está por forzar"/>{": "} 
											"{this.state.data ? this.state.data.preventivo.servicio_nombre : ''}"{" "}
											<FormattedMessage id="no_es_el_que_correspondería" defaultMessage="no es el que correspondería"/>{". "}
											<FormattedMessage id="Dado_el_medidor_actual_del_vehículo" defaultMessage="Dado el medidor actual del vehículo"/>{": "}
											{this.state.data ? (this.state.data.movil.kmActual ? parseInt(this.state.data.movil.kmActual) : 0 ) : ''}{" "}
											<FormattedMessage id="kms" defaultMessage="kms"/>{" "}
											<FormattedMessage id="y/o" defaultMessage="y/o"/>{" "}
											<FormattedMessage id="fecha" defaultMessage="fecha"/>{": "}{moment().format('DD/MM/YYYY')}{" "}
											{
												this.state.data.preventivoNext.ticket_id === null ?
													<FormattedMessage id="El_servicio_más_próximo_a_ejecutar_es" defaultMessage="El servicio más próximo a ejecutar es"/>
												:
													<FormattedMessage id="preventivoForzar.y_a_su_vez_esta_en ejecucion_el_servicio" defaultMessage="y a su vez esta en ejecución el servicio"/>
											}
											{": "}"{this.state.data ? (this.state.data.preventivoNext ? this.state.data.preventivoNext.servicio.nombre : '') : ''}"
										</h4>
										<div className="d-flex justify-content-center">
											<h4 style={{color: "#00c0bd"}}>
												<input 
													type="checkbox"
													checked={this.props.selectPreventivoIncorrecto}
													style={{ width: "15px", height: "15px" }}
													className="customCheckbox" 
													onChange={this.handleSelectPreventivoIncorrecto}
												/>{"  "}
												<span>
													<FormattedMessage id="deseo_ejecutar_el_servicio" defaultMessage="Deseo ejecutar el servicio"/>{" "}
													"{this.state.data ? this.state.data.preventivo.servicio_nombre : ''}"
												</span>
											</h4>
										</div>
										<div className="d-flex justify-content-center">
											<textarea 
												placeholder={
													this.props.intl.formatMessage({ id: 'Por_favor,_ingrese_un_detalle_acerca_de_la_razón_del_forzado', defaultMessage: 'Por favor, ingrese un detalle acerca de la razón del forzado' })
												}
												style={{width: "100%", height: "120px", marginTop: "20px"}}
												value={this.props.detalleForzarPreventivo}
												onChange={this.handleChangeDetalle}
											/>
										</div>
									</div>
									
								</div>
							</div>
							<div className="card pull-up">
									<div className="card-content pl-1 pr-1">
										
										<div className="card-footer">
											<div className="text-cd text-right">
												<button 
													type="button"
													className="btn btn-primary mr-1" 
													onClick={this.handleSubmitPreventivo}
													disabled={!this.props.selectPreventivoIncorrecto || this.state.loadingHandleSubmitPreventivo || this.state.loadingHandleSubmitPreventivoNext}
												>
													<i className={this.state.loadingHandleSubmitPreventivo ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i>{" "}
													<FormattedMessage id="Forzar" defaultMessage="Forzar"/>{" "}
													{this.state.data ? (this.state.data.preventivo ? this.state.data.preventivo.servicio_nombre : '') : ''}
												</button>
												{
													this.state.data.preventivoNext.ticket_id === null ?
														<button  
															type="button"
															className="btn btn-primary mr-1"
															onClick={this.handleSubmitPreventivoNext}
															disabled={this.state.loadingHandleSubmitPreventivoNext || this.state.loadingHandleSubmitPreventivo}
														>
															<i className={this.state.loadingHandleSubmitPreventivoNext ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i>{" "}
															<FormattedMessage id="Forzar" defaultMessage="Forzar"/>{" "}
															{this.state.data ? (this.state.data.preventivoNext ? this.state.data.preventivoNext.servicio.nombre : '') : ''}
														</button>
													: ''
												}
												<button type="button" className="btn btn-danger" onClick={this.handleCancel}>
													<i className="fa fa-times-circle"></i><FormattedMessage id="preventivosImportar.import_file.button.cancelar" defaultMessage=" Cancelar"/>
												</button>
											</div>
										</div>
									</div>
							</div>
                    	</>
					: '')
               	}
            </React.Fragment>
        );
    }
}

export default injectIntl(PreventivoForzar);
