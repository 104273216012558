import React, { useState, useEffect} from "react";
import { Redirect } from 'react-router-dom'
import {
	TableRow,
} from '@material-ui/core'
import 'moment/min/locales'
import Swal from 'sweetalert2'
import * as Utils from '../../commons/utils/Utils'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'

const RutasGridRow = (props) => {
	const intl = props.intl
	const ruta = props.ruta;
	const permissions = props.permissions;
	const baseUrl = props.baseUrl;
	const idRutaCompleto = (props.ruta.baseDescripcion.substring(0, 4) + props.ruta.nombre+ props.ruta.tipoRuta);

	const [redirectTo, setRedirectTo] = useState(null)

	useEffect(()=>{
	},[]);

	const getEstado = (activo) => {
		if (activo === 1) {
		  	return "status-green";
		} else if  (activo === 0){
			return "status-red";
		} else {
		  	return "";
		}
	};

	const eliminarRuta = () => {
		Swal.fire({
			title: intl.formatMessage({ id: '¿Confirma la eliminación?', defaultMessage: '¿Confirma la eliminación?' }),
			html: intl.formatMessage({ id: 'Se_eliminara_la_ruta', defaultMessage: 'Se eliminará la ruta' }) + ': ' + "<b>" + ruta.nombre + "</b>",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: intl.formatMessage({ id: 'Si', defaultMessage: 'Si' }),
			cancelButtonText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
		}).then((result) => {
			if (result.value) {
				Utils.deleteData('/rutas/' + ruta.id)
				.then(() => {
					props.fetchRutas();
					Swal(intl.formatMessage({ id: 'Eliminado!', defaultMessage: 'Eliminado!' }), "", "success");
				})
				.catch((err) => {
					Swal(
						intl.formatMessage({ id: err.response.data.detalle[0], defaultMessage: 'error' }),
						'',
						'error',
					);
				});
			}
		})
	};

	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<TableRow>
				<StyledTableCell align="left">
					{ruta.id}
				</StyledTableCell>
				<StyledTooltip arrow placement="right">
					<td
						align="left"
						className={getEstado(ruta.activo) + " p-0 all custom-lineHeight br"}
						style={{ maxWidth: "3px"}}
					/>
				</StyledTooltip>
				<StyledTableCell align="left">
					{ruta.nombre}
				</StyledTableCell>
				<StyledTableCell align="left">
					{idRutaCompleto}
				</StyledTableCell>
				<StyledTableCell align="left">
					{ruta.tipoRuta}
				</StyledTableCell>
				<StyledTableCell align="left">
					{ruta.horario}
				</StyledTableCell>
				<StyledTableCell align="left">
					{ruta.dominio}
				</StyledTableCell>
				<StyledTableCell align="left">
					{ruta.base ? ruta.base.descripcion : null}
				</StyledTableCell>
				<StyledTableCell>
					<div className="d-inline-flex">
						{permissions.visualizar ?
							<button
								className="action view btn btn-sm btn-icon btn-dt-grid text-success"
								title="Ver"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + ruta.id)}
							>
								<i className="fa fa-search fa-xs"/>
							</button>
							: ''
						}
						{permissions.modificar ?
							<button
								className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
								title="Editar"
								data-togle="tooltip"
								data-placement="top"
								onClick={()=> setRedirectTo(baseUrl +'/'+ ruta.id +'/edit')}
								>
									<i className="fa fa-pencil fa-xs"/>
							</button>
							: ''
						}
						{permissions.eliminar ?
							<button
								className="action delete btn btn-sm btn-icon btn-dt-grid text-danger"
								title="Eliminar"
								data-togle="tooltip"
								data-placement="top"
								onClick={()=> eliminarRuta()}
								>
									<i className="fa fa-trash fa-xs"/>
							</button>
							: ''
						}
					</div>
				</StyledTableCell>
			</TableRow>
		</>
	)
}

export default RutasGridRow
