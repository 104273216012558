import {
    Backdrop,
    Grow,
    Modal,
} from '@material-ui/core';
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import Switch from "react-switch";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Security from '../../commons/security/Security.js';
import * as Utils from '../../commons/utils/Utils';

const MovilesGridExport = (props) => {
  const [loading, setLoading] = useState(false)

  //Switch enabled config
  const [switchEnabled, setSwitchEnabled] = useState({
    unidadEnabled:false,
    regionEnabled:false,
    subRegionEnabled:false,
    colorEnabled:false,
    inmovilizadoEnabled:false,
    centroCostosResponsableEnabled:false,
    titularEnabled:false,
    funcionEnabled:false,
    centroCostosDireccionEnabled:false,
    centroCostosMercadoEnabled:false,
    centroCostosGerenciaEnabled:false,
    centroCostosAreaEnabled:false,
    disponibilidadEnabled: false,
    rutasEnabled:false,
  })

  const [dataToExport, setDataToExport] = useState({
    dominio: true,
    unidad: true,
    region: true,
    subRegion: true,
    baseDescripcion: true,
    responsableBaseNombreApellido: true,
    gerenciador: true,
    activo: true,
    inmovilizado: true,
    marcaNombre: true,
    modeloNombre: true,
    modeloTipoNombre: true,
    chasis: true,
    motor: true,
    color: true,
    anio: true,
    fechaAlta: true,
    fechaBaja: true,
    responsable1NombreApellido: true,
    responsable2NombreApellido: true,
    supervisorNombreApellido: true,
    temporalNombreApellido: true,
    centroCostosNombreResponsable: true,
    centroCostosNombre: true,
    centroCostosDireccion:true,
    centroCostosMercado:true,
    centroCostosGerencia:true,
    centroCostosArea:true,
    cebeNombre: true,
    poliza: true,
    lugarGuarda: true,
    certificadoVtv: true,
    telepeaje: true,
    ypfRuta: true,
    kmActual: true,
    dnrpaSeccional: true,
    activoFijo: true,
    nroTitulo: true,
    observaciones: true,
    estado: true,
    combustible: true,
    titular: true,
    proveedorGps: true,
    companiaOrigen: true,
    planPreventivoDescripcion: true,
    guardaEnBase: true,
    guardaLatitud: true,
    guardaLongitud: true,
    guardaDireccion: true,
    guardaAltura: true,
    guardaLocalidad: true,
    guardaProvincia: true,
    guardaPais: true,
    equipamiento: true,
    equipamientoDescripcion: true,
    fechaInicioOperacion: true,
    tipologiaPosicionDescripcion: true,
    funcion: true,
    rendimientoPropio: true,
    rendimientoEsperado: true,
    adjuntoCategoria: true,
    costoPreventivos:  false,
    costoCombustibles: false,
    costoCorrectivos: false,
    costoVencimientos: false,
    costoGestoria: false,
    costoInfracciones: false,
    clase: true,
    division: true,
    disponibilidad: true,
    pesoCargaMaxima: true,
    cantidadCompartimentos: true,
    anchoEspacioCarga: true,
    rutas: true,
    coordinadorNombreApellido: true
  })

  useEffect(() => {
    switchConfigEnabled()
  },[])

  const switchConfigEnabled = () => {
    Promise.all([
      ConfigBusiness.get('moviles.unidad.grilla'),
      ConfigBusiness.get('moviles.region.grilla'),
      ConfigBusiness.get('moviles.color.habilitado'),
      ConfigBusiness.get('tickets.inmovilizado.habilitado'),
      ConfigBusiness.get('moviles.centroCostosResponsable.habilitado'),
      ConfigBusiness.get('moviles.titular.grilla'),
      ConfigBusiness.get('moviles.funcion.habilitado'),
      ConfigBusiness.get('moviles.cecosDireccion.grilla'),
      ConfigBusiness.get('moviles.cecosMercado.grilla'),
      ConfigBusiness.get('moviles.cecosGerencia.grilla'),
      ConfigBusiness.get('moviles.cecosArea.grilla'),
      ConfigBusiness.get('moviles.subRegion.grilla'),
      ConfigBusiness.get('moviles.clasificador.habilitado'),
      ConfigBusiness.get('moviles.ruta.grilla'),
    ]).then((data) => {

      let auxSwitchEnabled = JSON.parse(JSON.stringify(switchEnabled))
        auxSwitchEnabled.unidadEnabled = data[0] === 'true' ? true : false
        auxSwitchEnabled.regionEnabled = data[1] === 'true' ? true : false
        auxSwitchEnabled.colorEnabled = data[2] === 'true' ? true : false
        auxSwitchEnabled.inmovilizadoEnabled = data[3] === 'true' ? true : false
        auxSwitchEnabled.centroCostosResponsableEnabled = data[4] === 'true' ? true : false
        auxSwitchEnabled.titularEnabled = data[5] === 'true' ? true : false
        auxSwitchEnabled.funcionEnabled = data[6] === 'true' ? true : false

        auxSwitchEnabled.centroCostosDireccionEnabled = data[7] === 'true' ? true : false
        auxSwitchEnabled.centroCostosMercadoEnabled = data[8] === 'true' ? true : false
        auxSwitchEnabled.centroCostosGerenciaEnabled = data[9] === 'true' ? true : false
        auxSwitchEnabled.centroCostosAreaEnabled = data[10] === 'true' ? true : false
        auxSwitchEnabled.subRegionEnabled = data[11] === 'true' ? true : false
        auxSwitchEnabled.disponibilidadEnabled = data[12] === 'true' ? true : false
        auxSwitchEnabled.rutasEnabled = data[13] === 'true' ? true : false
      setSwitchEnabled(auxSwitchEnabled)

      let auxDataToExport = JSON.parse(JSON.stringify(dataToExport))
        auxDataToExport.unidad = data[0] === 'true' ? true : false
        auxDataToExport.region = data[1] === 'true' ? true : false
        auxDataToExport.color = data[2] === 'true' ? true : false
        auxDataToExport.inmovilizado = data[3] === 'true' ? true : false
        auxDataToExport.centroCostosNombreResponsable = data[4] === 'true' ? true : false
        auxDataToExport.titular = data[5] === 'true' ? true : false
        auxDataToExport.funcion = data[6] === 'true' ? true : false

        auxDataToExport.centroCostosDireccion = data[7] === 'true' ? true : false
        auxDataToExport.centroCostosMercado = data[8] === 'true' ? true : false
        auxDataToExport.centroCostosGerencia = data[9] === 'true' ? true : false
        auxDataToExport.centroCostosArea = data[10] === 'true' ? true : false
        auxDataToExport.subRegion = data[11] === 'true' ? true : false
        auxDataToExport.disponibilidad = data[12] === 'true' ? true : false
        auxDataToExport.rutas = data[13] === 'true' ? true : false

      setDataToExport(auxDataToExport)
    });
  }

  const handleDataExport = (nombre)=>{
    const miState = {...dataToExport};
    switch (nombre) {
      case "dominio":
        miState.dominio = !miState.dominio;
        break;
      case "unidad":
        miState.unidad = !miState.unidad;
        break;
      case "region":
        miState.region = !miState.region;
        break;
      case "subRegion":
        miState.subRegion = !miState.subRegion;
        break;
      case "baseDescripcion":
        miState.baseDescripcion = !miState.baseDescripcion;
        break;
      case "responsableBaseNombreApellido":
        miState.responsableBaseNombreApellido = !miState.responsableBaseNombreApellido;
        break;
      case "gerenciador":
        miState.gerenciador = !miState.gerenciador;
        break;
      case "activo":
        miState.activo = !miState.activo;
        break;
      case "inmovilizado":
        miState.inmovilizado = !miState.inmovilizado;
        break;
      case "marcaNombre":
        miState.marcaNombre = !miState.marcaNombre;
        break;
      case "modeloNombre":
        miState.modeloNombre = !miState.modeloNombre;
        break;
      case "modeloTipoNombre":
        miState.modeloTipoNombre = !miState.modeloTipoNombre;
        break;
      case "chasis":
        miState.chasis = !miState.chasis;
        break;
      case "motor":
        miState.motor = !miState.motor;
        break;
      case "color":
        miState.color = !miState.color;
        break;
      case "anio":
        miState.anio = !miState.anio;
        break;
      case "fechaAlta":
        miState.fechaAlta = !miState.fechaAlta;
        break;
      case "fechaBaja":
        miState.fechaBaja = !miState.fechaBaja;
        break;
      case "responsable1NombreApellido":
        miState.responsable1NombreApellido = !miState.responsable1NombreApellido;
        break;
      case "responsable2NombreApellido":
        miState.responsable2NombreApellido = !miState.responsable2NombreApellido;
        break;
      case "supervisorNombreApellido":
        miState.supervisorNombreApellido = !miState.supervisorNombreApellido;
        break;
      case "temporalNombreApellido":
        miState.temporalNombreApellido = !miState.temporalNombreApellido;
        break;
      case "centroCostosNombreResponsable":
        miState.centroCostosNombreResponsable = !miState.centroCostosNombreResponsable;
        break;
      case "centroCostosNombre":
        miState.centroCostosNombre = !miState.centroCostosNombre;
        break;
      case "centroCostosDireccion":
        miState.centroCostosDireccion = !miState.centroCostosDireccion;
        break;
      case "centroCostosMercado":
        miState.centroCostosMercado = !miState.centroCostosMercado;
        break;
      case "centroCostosGerencia":
        miState.centroCostosGerencia = !miState.centroCostosGerencia;
        break;
      case "centroCostosArea":
        miState.centroCostosArea = !miState.centroCostosArea;
        break;
      case "cebeNombre":
        miState.cebeNombre = !miState.cebeNombre;
        break;
      case "poliza":
        miState.poliza = !miState.poliza;
        break;
      case "lugarGuarda":
        miState.lugarGuarda = !miState.lugarGuarda;
        break;
      case "certificadoVtv":
        miState.certificadoVtv = !miState.certificadoVtv;
        break;
      case "telepeaje":
        miState.telepeaje = !miState.telepeaje;
        break;
      case "ypfRuta":
        miState.ypfRuta = !miState.ypfRuta;
        break;
      case "kmActual":
        miState.kmActual = !miState.kmActual;
        break;
      case "dnrpaSeccional":
        miState.dnrpaSeccional = !miState.dnrpaSeccional;
        break;
      case "activoFijo":
        miState.activoFijo = !miState.activoFijo;
        break;
      case "nroTitulo":
        miState.nroTitulo = !miState.nroTitulo;
        break;
      case "observaciones":
        miState.observaciones = !miState.observaciones;
        break;
      case "estado":
        miState.estado = !miState.estado;
        break;
      case "combustible":
        miState.combustible = !miState.combustible;
        break;
      case "titular":
        miState.titular = !miState.titular;
        break;
      case "proveedorGps":
        miState.proveedorGps = !miState.proveedorGps;
        break;
      case "companiaOrigen":
        miState.companiaOrigen = !miState.companiaOrigen;
        break;
      case "planPreventivoDescripcion":
        miState.planPreventivoDescripcion = !miState.planPreventivoDescripcion;
        break;
      case "tipologiaPosicionDescripcion":
        miState.tipologiaPosicionDescripcion = !miState.tipologiaPosicionDescripcion;
        break;
      case "guardaEnBase":
        miState.guardaEnBase = !miState.guardaEnBase;
        break;
      case "guardaLatitud":
        miState.guardaLatitud = !miState.guardaLatitud;
        break;
      case "guardaLongitud":
        miState.guardaLongitud = !miState.guardaLongitud;
        break;
      case "guardaDireccion":
        miState.guardaDireccion = !miState.guardaDireccion;
        break;
      case "guardaAltura":
        miState.guardaAltura = !miState.guardaAltura;
        break;
      case "guardaLocalidad":
        miState.guardaLocalidad = !miState.guardaLocalidad;
        break;
      case "guardaProvincia":
        miState.guardaProvincia = !miState.guardaProvincia;
        break;
      case "guardaPais":
        miState.guardaPais = !miState.guardaPais;
        break;
      case "equipamiento":
        miState.equipamiento = !miState.equipamiento;
        break;
      case "equipamientoDescripcion":
        miState.equipamientoDescripcion = !miState.equipamientoDescripcion;
        break;
      case "funcion":
        miState.funcion = !miState.funcion;
        break;
      case "fechaInicioOperacion":
        miState.fechaInicioOperacion = !miState.fechaInicioOperacion;
        break;
			case "rendimientoPropio":
    			miState.rendimientoPropio = !miState.rendimientoPropio;
    		break;
			case "rendimientoEsperado":
    			miState.rendimientoEsperado = !miState.rendimientoEsperado;
    		break;
			case "adjuntoCategoria":
    			miState.adjuntoCategoria = !miState.adjuntoCategoria;
        break;
      case "costoPreventivos":
        miState.costoPreventivos = !miState.costoPreventivos;
        break;
      case "costoCombustibles":
        miState.costoCombustibles = !miState.costoCombustibles;
        break;
      case "costoCorrectivos":
        miState.costoCorrectivos = !miState.costoCorrectivos;
        break;
      case "costoVencimientos":
        miState.costoVencimientos = !miState.costoVencimientos;
        break;
      case "costoGestoria":
        miState.costoGestoria = !miState.costoGestoria;
        break;
      case "costoInfracciones":
        miState.costoInfracciones = !miState.costoInfracciones;
        break;
      case "clase":
        miState.clase = !miState.clase;
        break;
      case "division":
        miState.division = !miState.division;
        break;
      case "disponibilidad":
        miState.disponibilidad = !miState.disponibilidad;
        break;
      case "pesoCargaMaxima":
        miState.pesoCargaMaxima = !miState.pesoCargaMaxima;
        break;
      case "cantidadCompartimentos":
        miState.cantidadCompartimentos = !miState.cantidadCompartimentos;
        break;
      case "anchoEspacioCarga":
        miState.anchoEspacioCarga = !miState.anchoEspacioCarga;
        break;
      case "rutas":
        miState.rutas = !miState.rutas;
        break;
      case 'coordinadorNombreApellido':
        miState.coordinadorNombreApellido = !miState.coordinadorNombreApellido;
    }
    setDataToExport(miState);
  }

  const handleExportExcel = (event) => {
    
    let queryFilters = props.queryFilters();
    event.preventDefault()
		setLoading(true)
    let auxDataToExport = JSON.stringify({
      colsAExportar: dataToExport,
     
    })
    Utils.sendDataBlob(`/moviles/exportar-excel${queryFilters}`,'POST',auxDataToExport)
      .then(data => {
        let fileBlob = new File([data],'fleet-moviles.xlsx')
        let fileUrl = URL.createObjectURL(fileBlob);
        $("<a />", {
          href: fileUrl,
          download: "fleet-moviles.xlsx"
        }).appendTo("body").get(0).click();
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        props.handleClose();
        setLoading(false)
      })
	}

  return(
    <Modal open={props.open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Grow in={props.open}>
        <div className="" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="movilesGrid.render.export_modal.header_datos_generales" defaultMessage=" Exportar Moviles"/></h4>
                <button type="button" onClick={()=>props.handleClose()} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <div className="row">
                  <div className="col-md-12">
                    {/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
                      {this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
                    </div>*/}
                    {/* onSubmit={this.handleSubmit} */}
                    <form className="form form-horizontal">
                      <div className="form-body">
                              <div className="card pull-up">
                                    <div className="card-content">
                                      <div className="card-body">
                                        <h4 className="form-section">
                                          <i className="la la-columns"></i><FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.header_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
                                        </h4>
                                        <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="dominio">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_dominio" defaultMessage="Dominio:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("dominio")}
                                              checked = {dataToExport.dominio ? true : false}
                                              value={dataToExport.dominio}
                                              id = "dominio"
                                              name = "dominio"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          {(switchEnabled.unidadEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="unidad">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_unidad" defaultMessage="Unidad:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("unidad")}
                                              checked = {dataToExport.unidad ? true : false}
                                              value={dataToExport.unidad}
                                              id = "unidad"
                                              name = "unidad"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>: ""}
                                          {(switchEnabled.regionEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="region">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_region" defaultMessage="Region:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("region")}
                                              checked = {dataToExport.region ? true : false}
                                              value={dataToExport.region}
                                              id = "region"
                                              name = "region"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}
                                          {(switchEnabled.subRegionEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_subRegion" defaultMessage="SubRegion:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("subRegion")}
                                              checked = {dataToExport.subRegion ? true : false}
                                              value={dataToExport.subRegion}
                                              id = "subRegion"
                                              name = "subRegion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}
                                        </div>
                                        <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="baseDescripcion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_base" defaultMessage="Base:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("baseDescripcion")}
                                              checked = {dataToExport.baseDescripcion ? true : false}
                                              value={dataToExport.baseDescripcion}
                                              id = "baseDescripcion"
                                              name = "baseDescripcion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="responsableBaseNombreApellido">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_responsable_base" defaultMessage="Responsable Base:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("responsableBaseNombreApellido")}
                                              checked = {dataToExport.responsableBaseNombreApellido ? true : false}
                                              value={dataToExport.responsableBaseNombreApellido}
                                              id = "responsableBaseNombreApellido"
                                              name = "responsableBaseNombreApellido"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="gerenciador">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_gerenciador" defaultMessage="Gerenciador:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("gerenciador")}
                                              checked = {dataToExport.gerenciador ? true : false}
                                              value={dataToExport.gerenciador}
                                              id = "gerenciador"
                                              name = "gerenciador"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="activo">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_activo" defaultMessage="Activo:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("activo")}
                                              checked = {dataToExport.activo ? true : false}
                                              value={dataToExport.activo}
                                              id = "activo"
                                              name = "activo"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          {switchEnabled.inmovilizadoEnabled ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="activo">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_inmovilizado" defaultMessage="Inmovilizado:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("inmovilizado")}
                                              checked = {dataToExport.inmovilizado ? true : false}
                                              value={dataToExport.inmovilizado}
                                              id = "inmovilizado"
                                              name = "inmovilizado"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ''}
                                          </div>
                                          <div className="row m-1">
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="personaNombreApellido">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_marca" defaultMessage="Marca:"/>
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("marcaNombre")}
                                                checked = {dataToExport.marcaNombre ? true : false}
                                                value={dataToExport.marcaNombre}
                                                id = "marcaNombre"
                                                name = "marcaNombre"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="modeloNombre">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_modelo" defaultMessage="Modelo:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("modeloNombre")}
                                              checked = {dataToExport.modeloNombre ? true : false}
                                              value={dataToExport.modeloNombre}
                                              id = "modeloNombre"
                                              name = "modeloNombre"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="modeloTipoNombre">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_tipo" defaultMessage="Tipo:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("modeloTipoNombre")}
                                              checked = {dataToExport.modeloTipoNombre ? true : false}
                                              value={dataToExport.modeloTipoNombre}
                                              id = "modeloTipoNombre"
                                              name = "modeloTipoNombre"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="chasis">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_chasis" defaultMessage="Chasis:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("chasis")}
                                              checked = {dataToExport.chasis ? true : false}
                                              value={dataToExport.chasis}
                                              id = "chasis"
                                              name = "chasis"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          </div>
                                          <div className="row m-1">
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="motor">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_motor" defaultMessage="Motor:"/>
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("motor")}
                                                checked = {dataToExport.motor ? true : false}
                                                value={dataToExport.motor}
                                                id = "motor"
                                                name = "motor"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                            {(switchEnabled.colorEnabled) ?
                                            <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="color">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_color" defaultMessage="Color:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("color")}
                                              checked = {dataToExport.color ? true : false}
                                              value={dataToExport.color}
                                              id = "color"
                                              name = "color"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>: ""}
                                        <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="anio">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_anio" defaultMessage="Año:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("anio")}
                                              checked = {dataToExport.anio ? true : false}
                                              value={dataToExport.anio}
                                              id = "anio"
                                              name = "anio"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>

                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaAlta">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_fec_alta" defaultMessage="Fec. Alta:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("fechaAlta")}
                                              checked = {dataToExport.fechaAlta ? true : false}
                                              value={dataToExport.fechaAlta}
                                              id = "fechaAlta"
                                              name = "fechaAlta"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          </div>
                                        <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaBaja">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_fec_baja" defaultMessage="Fec. Baja:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("fechaBaja")}
                                              checked = {dataToExport.fechaBaja ? true : false}
                                              value={dataToExport.fechaBaja}
                                              id = "fechaBaja"
                                              name = "fechaBaja"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="responsable1NombreApellido">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_responsable1" defaultMessage="Responsable1:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("responsable1NombreApellido")}
                                              checked = {dataToExport.responsable1NombreApellido ? true : false}
                                              value={dataToExport.responsable1NombreApellido}
                                              id = "responsable1NombreApellido"
                                              name = "responsable1NombreApellido"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                        <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="responsable2NombreApellido">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_responsable2" defaultMessage="Responsable2:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("responsable2NombreApellido")}
                                              checked = {dataToExport.responsable2NombreApellido ? true : false}
                                              value={dataToExport.responsable2NombreApellido}
                                              id = "responsable2NombreApellido"
                                              name = "responsable2NombreApellido"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                        <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="supervisorNombreApellido">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_supervisor" defaultMessage="supervisor:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("supervisorNombreApellido")}
                                              checked = {dataToExport.supervisorNombreApellido ? true : false}
                                              value={dataToExport.supervisorNombreApellido}
                                              id = "supervisorNombreApellido"
                                              name = "supervisorNombreApellido"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          </div>

                                        <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="temporalNombreApellido">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_temporal" defaultMessage="Temporal:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("temporalNombreApellido")}
                                              checked = {dataToExport.temporalNombreApellido ? true : false}
                                              value={dataToExport.temporalNombreApellido}
                                              id = "temporalNombreApellido"
                                              name = "temporalNombreApellido"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="coordinadorNombreApellido">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.coordinador" defaultMessage="Coordinador"/>:
                                            </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("coordinadorNombreApellido")}
                                                checked = {dataToExport.coordinadorNombreApellido ? true : false}
                                                value={dataToExport.coordinadorNombreApellido}
                                                id = "coordinadorNombreApellido"
                                                name = "coordinadorNombreApellido"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                          </div>
                                          {(switchEnabled.centroCostosResponsableEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombreResponsable">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_centro_costos_de_responsable" defaultMessage="Centro Costos de Responsable:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("centroCostosNombreResponsable")}
                                              checked = {dataToExport.centroCostosNombreResponsable ? true : false}
                                              value={dataToExport.centroCostosNombreResponsable}
                                              id = "centroCostosNombreResponsable"
                                              name = "centroCostosNombreResponsable"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>: ""}
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_centro_costos" defaultMessage="Centro Costos:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("centroCostosNombre")}
                                              checked = {dataToExport.centroCostosNombre ? true : false}
                                              value={dataToExport.centroCostosNombre}
                                              id = "centroCostosNombre"
                                              name = "centroCostosNombre"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="cebeNombre">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_cebeNombre" defaultMessage="CebeNombre:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("cebeNombre")}
                                              checked = {dataToExport.cebeNombre ? true : false}
                                              value={dataToExport.cebeNombre}
                                              id = "cebeNombre"
                                              name = "cebeNombre"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                      </div>

                                        <div className="row m-1">
                                        {(switchEnabled.centroCostosDireccionEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosDireccion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_centroCostosDireccion" defaultMessage="CC.Dirección:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("centroCostosDireccion")}
                                              checked = {dataToExport.centroCostosDireccion ? true : false}
                                              value={dataToExport.centroCostosDireccion}
                                              id = "centroCostosDireccion"
                                              name = "centroCostosDireccion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}

                                          {(switchEnabled.centroCostosMercadoEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosMercado">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.centroCostosMercado" defaultMessage="CC.Mercado:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("centroCostosMercado")}
                                              checked = {dataToExport.centroCostosMercado ? true : false}
                                              value={dataToExport.centroCostosMercado}
                                              id = "centroCostosMercado"
                                              name = "centroCostosMercado"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}

                                          {(switchEnabled.centroCostosGerenciaEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosGerencia">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_centroCostosGerencia" defaultMessage="CC.Gerencia:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("centroCostosGerencia")}
                                              checked = {dataToExport.centroCostosGerencia ? true : false}
                                              value={dataToExport.centroCostosGerencia}
                                              id = "centroCostosGerencia"
                                              name = "centroCostosGerencia"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}

                                          {(switchEnabled.centroCostosAreaEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosArea">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_centroCostosArea" defaultMessage="CC.Área:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("centroCostosArea")}
                                              checked = {dataToExport.centroCostosArea ? true : false}
                                              value={dataToExport.centroCostosArea}
                                              id = "centroCostosArea"
                                              name = "centroCostosArea"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}
                                        </div>

                                        <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="poliza">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_poliza" defaultMessage="Poliza:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("poliza")}
                                              checked = {dataToExport.poliza ? true : false}
                                              value={dataToExport.poliza}
                                              id = "poliza"
                                              name = "poliza"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="lugarGuarda">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_lugar_guarda:" defaultMessage="Lugar Guarda:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("lugarGuarda")}
                                              checked = {dataToExport.lugarGuarda ? true : false}
                                              value={dataToExport.lugarGuarda}
                                              id = "lugarGuarda"
                                              name = "lugarGuarda"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>

                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="certificadoVtv">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_vtv" defaultMessage="VTV:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("certificadoVtv")}
                                              checked = {dataToExport.certificadoVtv ? true : false}
                                              value={dataToExport.certificadoVtv}
                                              id = "certificadoVtv"
                                              name = "certificadoVtv"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="telepeaje">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_telepeaje" defaultMessage="Telepeaje:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("telepeaje")}
                                              checked = {dataToExport.telepeaje ? true : false}
                                              value={dataToExport.telepeaje}
                                              id = "telepeaje"
                                              name = "telepeaje"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                        </div>
                                        <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="ypfRuta">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_yPFRuta" defaultMessage="YPFRuta:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("ypfRuta")}
                                              checked = {dataToExport.ypfRuta ? true : false}
                                              value={dataToExport.ypfRuta}
                                              id = "ypfRuta"
                                              name = "ypfRuta"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                        <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="kmActual">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_medidor" defaultMessage="Medidor:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("kmActual")}
                                              checked = {dataToExport.kmActual ? true : false}
                                              value={dataToExport.kmActual}
                                              id = "kmActual"
                                              name = "kmActual"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="dnrpaSeccional">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_DNRPA" defaultMessage="DNRPA:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("dnrpaSeccional")}
                                              checked = {dataToExport.dnrpaSeccional ? true : false}
                                              value={dataToExport.dnrpaSeccional}
                                              id = "dnrpaSeccional"
                                              name = "dnrpaSeccional"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="activoFijo">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_activo_fijo" defaultMessage="Activo Fijo:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("activoFijo")}
                                              checked = {dataToExport.activoFijo ? true : false}
                                              value={dataToExport.activoFijo}
                                              id = "activoFijo"
                                              name = "activoFijo"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                      </div>
                                      <div className="row m-1">
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="nroTitulo">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_titulo" defaultMessage="Titulo:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("nroTitulo")}
                                              checked = {dataToExport.nroTitulo ? true : false}
                                              value={dataToExport.nroTitulo}
                                              id = "nroTitulo"
                                              name = "nroTitulo"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                          <label className="col-md-6 label-control col-form-label" htmlFor="observaciones">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_obs" defaultMessage="Obs.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("observaciones")}
                                              checked = {dataToExport.observaciones ? true : false}
                                              value={dataToExport.observaciones}
                                              id = "observaciones"
                                              name = "observaciones"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          {(switchEnabled.disponibilidadEnabled) ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="disponibilidad">
                                                <FormattedMessage id="Disponibilidad" defaultMessage="Disponibilidad"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("disponibilidad")}
                                                checked = {dataToExport.disponibilidad ? true : false}
                                                value={dataToExport.disponibilidad}
                                                id = "disponibilidad"
                                                name = "disponibilidad"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : null}
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="estado">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_estado" defaultMessage="Estado:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("estado")}
                                              checked = {dataToExport.estado ? true : false}
                                              value={dataToExport.estado}
                                              id = "estado"
                                              name = "estado"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="combustible">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_combustible" defaultMessage="Combustible:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("combustible")}
                                              checked = {dataToExport.combustible ? true : false}
                                              value={dataToExport.combustible}
                                              id = "activcombustibleoFijo"
                                              name = "combustible"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                        </div>
                                        <div className="row m-1">
                                          {(switchEnabled.titularEnabled) ?
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="titular">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_titular" defaultMessage="Titular:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("titular")}
                                              checked = {dataToExport.titular ? true : false}
                                              value={dataToExport.titular}
                                              id = "titular"
                                              name = "titular"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div> : ""}
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="proveedorGps">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_prov_gps" defaultMessage="Prov. Gps:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("proveedorGps")}
                                              checked = {dataToExport.proveedorGps ? true : false}
                                              value={dataToExport.proveedorGps}
                                              id = "proveedorGps"
                                              name = "proveedorGps"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="companiaOrigen">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_cnia_orig" defaultMessage="Cnia. Orig.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("companiaOrigen")}
                                              checked = {dataToExport.companiaOrigen ? true : false}
                                              value={dataToExport.companiaOrigen}
                                              id = "companiaOrigen"
                                              name = "companiaOrigen"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="planPreventivoDescripcion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_plan_mant" defaultMessage="Plan Mant.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("planPreventivoDescripcion")}
                                              checked = {dataToExport.planPreventivoDescripcion ? true : false}
                                              value={dataToExport.planPreventivoDescripcion}
                                              id = "planPreventivoDescripcion"
                                              name = "planPreventivoDescripcion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>

                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaEnBase">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_base" defaultMessage="Guarda Base:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaEnBase")}
                                              checked = {dataToExport.guardaEnBase ? true : false}
                                              value={dataToExport.guardaEnBase}
                                              id = "guardaEnBase"
                                              name = "guardaEnBase"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaLatitud">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_lat" defaultMessage="Guarda Lat.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaLatitud")}
                                              checked = {dataToExport.guardaLatitud ? true : false}
                                              value={dataToExport.guardaLatitud}
                                              id = "guardaLatitud"
                                              name = "guardaLatitud"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaLongitud">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_lon" defaultMessage="Guarda Lon.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaLongitud")}
                                              checked = {dataToExport.guardaLongitud ? true : false}
                                              value={dataToExport.guardaLongitud}
                                              id = "guardaLongitud"
                                              name = "guardaLongitud"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaDireccion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_dir" defaultMessage="Guarda Dir.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaDireccion")}
                                              checked = {dataToExport.guardaDireccion ? true : false}
                                              value={dataToExport.guardaDireccion}
                                              id = "guardaDireccion"
                                              name = "guardaDireccion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaAltura">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_nro" defaultMessage="Guarda Nro.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaAltura")}
                                              checked = {dataToExport.guardaAltura ? true : false}
                                              value={dataToExport.guardaAltura}
                                              id = "guardaAltura"
                                              name = "guardaAltura"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaLocalidad">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_loc" defaultMessage="Guarda Loc.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaLocalidad")}
                                              checked = {dataToExport.guardaLocalidad ? true : false}
                                              value={dataToExport.guardaLocalidad}
                                              id = "guardaLocalidad"
                                              name = "guardaLocalidad"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaProvincia">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_pro" defaultMessage="Guarda Pro.:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaProvincia")}
                                              checked = {dataToExport.guardaProvincia ? true : false}
                                              value={dataToExport.guardaProvincia}
                                              id = "guardaProvincia"
                                              name = "guardaProvincia"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="guardaPais">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_guarda_pais" defaultMessage="Guarda Pais:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("guardaPais")}
                                              checked = {dataToExport.guardaPais ? true : false}
                                              value={dataToExport.guardaPais}
                                              id = "guardaPais"
                                              name = "guardaPais"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="equipamiento">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_equipamiento" defaultMessage="Equipamiento:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("equipamiento")}
                                              checked = {dataToExport.equipamiento ? true : false}
                                              value={dataToExport.equipamiento}
                                              id = "equipamiento"
                                              name = "equipamiento"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="equipamientoDescripcion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_equipamiento_descripcion" defaultMessage="Equipamiento Descripcion:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("equipamientoDescripcion")}
                                              checked = {dataToExport.equipamientoDescripcion ? true : false}
                                              value={dataToExport.equipamientoDescripcion}
                                              id = "equipamientoDescripcion"
                                              name = "equipamientoDescripcion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaInicioOperacion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_fecha_inicio_operacion" defaultMessage="Fecha Inicio Operacion:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("fechaInicioOperacion")}
                                              checked = {dataToExport.fechaInicioOperacion ? true : false}
                                              value={dataToExport.fechaInicioOperacion}
                                              id = "fechaInicioOperacion"
                                              name = "fechaInicioOperacion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaInicioOperacion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_diagrama_posicion" defaultMessage="Diagrama de Posicion:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("tipologiaPosicionDescripcion")}
                                              checked = {dataToExport.tipologiaPosicionDescripcion ? true : false}
                                              value={dataToExport.tipologiaPosicionDescripcion}
                                              id = "tipologiaPosicionDescripcion"
                                              name = "tipologiaPosicionDescripcion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          {ConfigBusiness.get('moviles.funcion.habilitado') === 'true' ? (
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="funcion">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_funcion" defaultMessage="Funcion:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("funcion")}
                                              checked = {dataToExport.funcion ? true : false}
                                              value={dataToExport.funcion}
                                              id = "funcion"
                                              name = "funcion"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          ) :''}
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="rendimientoPropio">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_rendimiento_propio" defaultMessage="Rendimiento Propio:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("rendimientoPropio")}
                                              checked = {dataToExport.rendimientoPropio ? true : false}
                                              value={dataToExport.rendimientoPropio}
                                              id = "rendimientoPropio"
                                              name = "rendimientoPropio"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="rendimientoEsperado">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_rendimiento_esperado" defaultMessage="Rendimiento Esperado:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("rendimientoEsperado")}
                                              checked = {dataToExport.rendimientoEsperado ? true : false}
                                              value={dataToExport.rendimientoEsperado}
                                              id = "rendimientoEsperado"
                                              name = "rendimientoEsperado"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="adjuntoCategoria">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_adjunto_categoria" defaultMessage="Adjunto Categoría:"/>
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("adjuntoCategoria")}
                                              checked = {dataToExport.adjuntoCategoria ? true : false}
                                              value={dataToExport.adjuntoCategoria}
                                              id = "adjuntoCategoria"
                                              name = "adjuntoCategoria"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                        </div>
                                        <div className="row m-1">
                                          {Security.hasPermission('MOVILES_EXPORTAR_COSTO_CORRECTIVOS') ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="costoCorrectivos">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_costo_correctivos" defaultMessage="Costo Correctivos"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("costoCorrectivos")}
                                                checked = {dataToExport.costoCorrectivos ? true : false}
                                                value={dataToExport.costoCorrectivos}
                                                id = "costoCorrectivos"
                                                name = "costoCorrectivos"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : ''}
                                          {Security.hasPermission('MOVILES_EXPORTAR_COSTO_PREVENTIVO') ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="costoPreventivos">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_costo_preventivos" defaultMessage="Costo Preventivos"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("costoPreventivos")}
                                                checked = {dataToExport.costoPreventivos ? true : false}
                                                value={dataToExport.costoPreventivos}
                                                id = "costoPreventivos"
                                                name = "costoPreventivos"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : ''}
                                          {Security.hasPermission('MOVILES_EXPORTAR_COSTO_GESTORIA') ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="costoGestoria">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_costo_gestoria" defaultMessage="Costo Gestoría"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("costoGestoria")}
                                                checked = {dataToExport.costoGestoria ? true : false}
                                                value={dataToExport.costoGestoria}
                                                id = "costoGestoria"
                                                name = "costoGestoria"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : ''}
                                          {Security.hasPermission('MOVILES_EXPORTAR_COSTO_INFRACCIONES') ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="costoInfracciones">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_costo_infracciones" defaultMessage="Costo Infracciones"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("costoInfracciones")}
                                                checked = {dataToExport.costoInfracciones ? true : false}
                                                value={dataToExport.costoInfracciones}
                                                id = "costoInfracciones"
                                                name = "costoInfracciones"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : ''}
                                        </div>
                                        <div className="row m-1">
                                          {Security.hasPermission('MOVILES_EXPORTAR_COSTO_COMBUSTIBLES') ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="costoCombustibles">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_costo_combustibles" defaultMessage="Costo Combustibles"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("costoCombustibles")}
                                                checked = {dataToExport.costoCombustibles ? true : false}
                                                value={dataToExport.costoCombustibles}
                                                id = "costoCombustibles"
                                                name = "costoCombustibles"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : ''}
                                          {Security.hasPermission('MOVILES_EXPORTAR_COSTO_VENCIMIENTOS') ?
                                            <div className=" col-md-3">
                                              <label className="col-md-6 label-control col-form-label" htmlFor="costoVencimientos">
                                                <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_costo_vencimientos" defaultMessage="Costo Vencimientos"/>:
                                              </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("costoVencimientos")}
                                                checked = {dataToExport.costoVencimientos ? true : false}
                                                value={dataToExport.costoVencimientos}
                                                id = "costoVencimientos"
                                                name = "costoVencimientos"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                            </div>
                                          : ''}
                                        {
                                            ConfigBusiness.get('moviles.trabajaConDivisiones.habilitado') === 'true' &&
                                            Security.hasPermission("DIVISIONES_LISTAR") && (
                                                <div className=" col-md-3">
                                                    <label className="col-md-6 label-control col-form-label" htmlFor="division">
                                                        <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_division:" defaultMessage="División:" />
                                                    </label>
                                                    <Switch
                                                        onChange={(event) => handleDataExport("division")}
                                                        checked={dataToExport.division ? true : false}
                                                        value={dataToExport.division}
                                                        id="division"
                                                        name="division"
                                                        offColor="#FF4961"
                                                        onColor="#28D094"
                                                        disabled={false}
                                                        className="col-md-6 align-middle"
                                                    />
                                                </div>
                                            )} 
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="clase">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_clase" defaultMessage="Clase"/>:
                                            </label>
                                            <Switch
                                              onChange = {(event) => handleDataExport("clase")}
                                              checked = {dataToExport.clase ? true : false}
                                              value={dataToExport.clase}
                                              id = "clase"
                                              name = "clase"
                                              offColor = "#FF4961"
                                              onColor = "#28D094"
                                              disabled = {false}
                                              className =  "col-md-6 align-middle"
                                            />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="pesoCargaMaxima">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_pesoCargaMaxima" defaultMessage="Peso Carga Máxima (kg)"/>:
                                            </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("pesoCargaMaxima")}
                                                checked = {dataToExport.pesoCargaMaxima ? true : false}
                                                value={dataToExport.pesoCargaMaxima}
                                                id = "pesoCargaMaxima"
                                                name = "pesoCargaMaxima"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="cantidadCompartimentos">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_cantidadCompartimentos" defaultMessage="Número de Cortinas"/>:
                                            </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("cantidadCompartimentos")}
                                                checked = {dataToExport.cantidadCompartimentos ? true : false}
                                                value={dataToExport.cantidadCompartimentos}
                                                id = "cantidadCompartimentos"
                                                name = "cantidadCompartimentos"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                          </div>
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="anchoEspacioCarga">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.label_anchoEspacioCarga" defaultMessage="Cantidad de Uñas"/>:
                                            </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("anchoEspacioCarga")}
                                                checked = {dataToExport.anchoEspacioCarga ? true : false}
                                                value={dataToExport.anchoEspacioCarga}
                                                id = "anchoEspacioCarga"
                                                name = "anchoEspacioCarga"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                          </div>
                                          {ConfigBusiness.get('moviles.ruta.grilla') === 'true' ? (
                                          <div className=" col-md-3">
                                            <label className="col-md-6 label-control col-form-label" htmlFor="rutas">
                                              <FormattedMessage id="movilesGrid.render.export_modal.columns_to_export.rutas" defaultMessage="Rutas"/>:
                                            </label>
                                              <Switch
                                                onChange = {(event) => handleDataExport("rutas")}
                                                checked = {dataToExport.rutas ? true : false}
                                                value={dataToExport.rutas}
                                                id = "rutas"
                                                name = "rutas"
                                                offColor = "#FF4961"
                                                onColor = "#28D094"
                                                disabled = {false}
                                                className =  "col-md-6 align-middle"
                                              />
                                          </div>) : null }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                        <div className="col-md-12 alert alert-info small" role="alert">
                          <FormattedMessage id="movilesGrid.render.export_modal.esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer modal-grey">
                <button type="button" className="btn btn-default btn-fleet" onClick={(e)=>handleExportExcel(e)} ><i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="movilesGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel"/></button>
                <button type="button" className="btn btn-danger" onClick={()=>props.handleClose()} ><i className="fa fa-times-circle"></i><FormattedMessage id="movilesGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar"/></button>
              </div>
            </div>
          </div>
        </div>
      </Grow>
    </Modal>
  )
}

export default MovilesGridExport
