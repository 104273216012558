import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import Switch from "react-switch"
import TicketModificarAsignacion from './TicketModificarAsignacion.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import {FormattedMessage, injectIntl} from 'react-intl';

class TicketAsignacion extends Component {
	constructor(props) {
    super(props);
    this.props = props;
    moment.locale('es');

    this.state = {
      modalModificarAsignacionKey: 0
    }
  }

  componentWillMount() {
    this.props.onRef(this);
    this.init();
  }

  init() {

  }

  canDo(action) {
    return this.props.actions.indexOf(action) >= 0;
  }

  handleModificarAsignacionOpen(e) {
    e.preventDefault();
    let modalModificarAsignacionKey = this.state.modalModificarAsignacionKey + 1;
    this.setState({
      modalModificarAsignacionKey: modalModificarAsignacionKey
    }, () => {
      $('#modificar_asignacion_modal').modal({backdrop: 'static'});
    });
  }

  handleModificarAsigacionSave () {
    let modalModificarAsignacionKey = this.state.modalModificarAsignacionKey + 1;
    this.setState({
      modalModificarAsignacionKey: modalModificarAsignacionKey
    }, () => {
      this.child.init();
    });
    $('#modificar_asignacion_modal').modal('toggle');
    this.props.callbackSave();
  }

  handleModificarAsigacionClose () {
    let modalModificarAsignacionKey = this.state.modalModificarAsignacionKey + 1;
    this.setState({
      modalModificarAsignacionKey: modalModificarAsignacionKey
    });
    $('#modificar_asignacion_modal').modal('toggle');
    this.props.callbackClose();
  }

  handleEnTallerChange() {}

	render() {
    if(this.props.ticket) {
      return (
        <div>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <h4 className="form-section">
                  <i className="ft-user-check"></i><FormattedMessage id="ticketAsignacion.render.assignment.header_asignacion" defaultMessage=" Asignación"/>
                </h4>
                {Security.hasAnyPermission(['TICKETS_MODIFICAR_GERENCIADOR', 'TICKETS_MODIFICAR_TALLER', 'TICKETS_MODIFICAR_EN_TALLER', 'TICKETS_MODIFICAR_PRESUPUESTADOS']) ? (
                <div className="float-right ticket-buttons" style={{display:  this.props.action === 'VIEW' ? 'none': ''}}>
                  <a href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketAsignacion.render.assignment.button_title_modificar', defaultMessage: 'Modificar' })} onClick={this.handleModificarAsignacionOpen.bind(this)}>
                    <i className="la la-pencil align-middle"></i>
                  </a>
                </div>
                ) : ''}

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-5 label-control col-form-label"><FormattedMessage id="ticketAsignacion.render.assignment.label_gerenciador" defaultMessage="Gerenciador:"/></label>
                      <div className="col-md-7">
                        <div className="form-control-static col-form-label form-value">{this.props.ticket.gerenciador ? this.props.ticket.gerenciador.razonSocial : ''}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-5 label-control col-form-label"><FormattedMessage id="ticketAsignacion.render.assignment.label_taller" defaultMessage="Taller:"/></label>
                      <div className="col-md-7">
                        <div className="form-control-static col-form-label form-value">{this.props.ticket && this.props.ticket.taller ? this.props.ticket.taller.razonSocial : ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-5 label-control col-form-label"><FormattedMessage id="ticketAsignacion.render.assignment.label_turno" defaultMessage="Turno:"/></label>
                      <div className="col-md-7">
                        <div className="form-control-static col-form-label form-value">{this.props.ticket.fechaHoraTurno ? moment(this.props.ticket.fechaHoraTurno).format('L HH:mm') : ''}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                        <label className="col-md-5 label-control col-form-label" htmlFor="fechaPrometida">
                          <FormattedMessage id="ticketAsignacion.render.assigment.label_fecha_prometida" defaultMessage="Fecha Prometida"/>:
                        </label>
                        <div className="col-md-7">                          													
                          <div className="form-control-static col-form-label form-value">{this.props.ticket.fechaPrometida ? moment(this.props.ticket.fechaPrometida).format('L') : ''}</div>                          
                        </div>
                    </div>
                  </div>
                </div>
                {this.props.ticketsDiasReparacionGrilla ? 
                <div className="row">
                  <div className="col-md-6 "></div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-5 label-control col-form-label">
                        <FormattedMessage id="ticketAsignacion.render.assignment.label_diasReparacion" defaultMessage="Dias de Reparación:"/>
                      </label>
                      <div className="col-md-7">
                        <div className="form-control-static col-form-label form-value">
                          {this.props.ticket.dias_reparacion && this.props.ticket.dias_reparacion != 0 ? this.props.ticket.dias_reparacion : this.props.ticket.dias_reparacion === 0 ? '0' : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : ''}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-5 label-control col-form-label"><FormattedMessage id="ticketAsignacion.render.assignment.label_en_taller" defaultMessage="En Taller:"/></label>
                      <div className="col-md-7 mt-auto">
                        <Switch
                          onChange={this.handleEnTallerChange}
                          checked={this.props.ticket.enTaller ? (this.props.ticket.enTaller > 0 ? true : false): false }
                          id="enTaller"
                          name="enTaller"
                          disabled={true}
                          offColor="#FF4961"
                          onColor="#28D094"
                        />
                        {/*this.props.ticket.enTallerVerificado ? <img src={"../../../images/marker-check.svg"} style={{height: "2.7em", marginTop:"-4px", position: "absolute", paddingLeft: "0.5em"}}/> : ''*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label className="col-md-2 label-control col-form-label pr-4" style={{whiteSpace:'nowrap'}}><FormattedMessage id="ticketAsignacion.render.assignment.label_observaciones" defaultMessage="Observaciones:"/></label>
                      <div className="col-md-10 pl-3">
                        <div className="form-control-static col-form-label form-value">{this.props.ticket.observacionesTaller}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="modificar_asignacion_modal" role="dialog" aria-labelledby="myModalLabel1">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel1"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketAsignacion.render.modal_edit_assignment.header_modificar_asignacion_para_ticket', defaultMessage: ' Modificar asignación para el ticket: ' }) + this.props.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketModificarAsignacion 
                      onRef={ref => (this.child = ref)} 
                      key={this.state.modalModificarAsignacionKey} 
                      ticket={this.props.ticket} 
                      inmovilizado={this.props.inmovilizado} 
                      callbackClose={this.handleModificarAsigacionClose.bind(this)} 
                      callbackSave={this.handleModificarAsigacionSave.bind(this)}
                      ticketsDiasReparacionGrilla={this.props.ticketsDiasReparacionGrilla}
                      motivoAsignacionHabilitado={this.props.motivoAsignacionHabilitado}
                       >
                  </TicketModificarAsignacion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketAsignacion);