import React, { Component, useEffect, useState, useLayoutEffect } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import TanqueVerde from '../../assets/images/combustibles/tanque-verde.svg'
import TanqueRojo from '../../assets/images/combustibles/tanque-rojo.svg'
import TanqueGris from '../../assets/images/combustibles/tanque-gris.svg'
import MangueraVerde from '../../assets/images/combustibles/manguera-verde.svg'
import MangueraRoja from '../../assets/images/combustibles/manguera-roja.svg'
import MangueraGris from '../../assets/images/combustibles/manguera-gris.svg'
import SurtidorHojaVerde from '../../assets/images/combustibles/rendimiento esperado-verde.svg'
import SurtidorHojaRojo from '../../assets/images/combustibles/rendimiento esperado-rojo.svg'
import SurtidorHojaGris from '../../assets/images/combustibles/rendimiento esperado-gris.svg'
import Security from '../../commons/security/Security.js'
import moment, { updateLocale } from 'moment'
import Loading from '../ui/Loading.js'
import WidgetSparkline from '../dashboards/operativo2/WidgetSparkline';
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import GravatarOption from './GravatarOption'
import { random } from '@amcharts/amcharts4/.internal/core/utils/String';
import Switch from "react-switch"
import CombustiblesImportar from './CombustiblesImportar';
import CombustiblesImportarReporte from './CombustiblesImportarReporte';
import { FormattedMessage, injectIntl , useIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
//import { Tooltip } from '@amcharts/amcharts4/core'
import Tooltip from "@material-ui/core/Tooltip";
import Popper from '@material-ui/core/Popper';
import Popover from 'react-simple-popover';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import * as Utils from '../../commons/utils/Utils';
import Input from "@material-ui/core/TextField";
import Cancel from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import Timezone from '../../commons/timezone/Timezone.js';
import ControlDistanciaVerde from '../../assets/images/combustibles/control-distancia-verde.png'
import ControlDistanciaRojo from '../../assets/images/combustibles/control-distancia-rojo.png'
import ControlDistanciaGris from '../../assets/images/combustibles/control-distancia-gris.png'
import ControlChoferVerde from '../../assets/images/combustibles/control-chofer-verde.png'
import ControlChoferRojo from '../../assets/images/combustibles/control-chofer-rojo.png'
import ControlChoferGris from '../../assets/images/combustibles/control-chofer-gris.png'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import { Grid, Box, } from '@material-ui/core';

/*$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;*/

const NewTableCell = withStyles((theme) => ({
	root: {
		justifyContent: "center",
	},
	body: {
		justifyContent: "center"
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

/*const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
});

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	cell: {
		justifyContent: "center"
	}
});*/
/**************** */
const useStyles = makeStyles((theme)=>({
   /* tableRow: {
	  height: 30
	},
	/*tableCell: {
	  padding: "0px 16px"
	}*/

	root: {
		fontSize: '1rem',
		fontWeight: '400',
		fontFamily: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
		color: "#6B6F82",
		borderBottom: "1px solid #e3ebf3 !important"
	},
	rootTable:{
		display: "table",
		overflowX: "auto",
		whiteSpace: "nowrap",
		marginTop: '6px'
	},
	head: {
		backgroundColor: "#1a1036",
		color: theme.palette.common.white,
		fontSize: '1rem',
		fontWeight: '400',
		fontFamily: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
	},
	body: {
		fontSize: 14,
	},
	/*propiedades para popper con mapas */
	popper: {
		zIndex: 1,         
		},
	arrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		left: '-14px',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid', 
			borderWidth: '8px 8px 8px 0',
			borderColor: `transparent #00000033 transparent transparent`,
			right: '-3px',
			top: '-12px',
			position: 'absolute',
		},
		'&::after': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid', 
			borderWidth: '8px 8px 8px 0',
			borderColor: `transparent #fff transparent transparent`,
			top: '-12px',
			position: 'absolute',
			right: '-4px',
			zIndex: '2000'
		},

				
		},
}));

function Row(props) {
	const [ajaxHandler, setAjaxHandler] = useState(props.ajaxHandler);
	const { combustible, intl } = props;
	const columnTarjetaSaldo = props.tarjetaSaldo;
	const columnConductorInformado = props.columnConductorInformado;
	const columnUnidad = props.columnUnidad;
	const classes = useStyles();
	const columnBase = props.columnBase;
	const columnOrigen = props.columnOrigen;
	const Estado = (props) => {

		let title = props.columnData === 'SIN_COMPROBACION' ? props.intl.formatMessage({ id: 'combustiblesGrid.column.createdCell.sin_comprobacion', defaultMessage: 'Sin Comprobación' }) : props.columnData;
		if (props.motivo) {
			title += " | " + props.motivo;
		}

		let classColor = "";
		if (props.columnData == 'INVALIDA') {
			classColor = "status-red";
		} else if (props.columnData == 'VALIDA') {
			classColor = "status-green";
		} else {
			classColor = "status-yellow";
		}

		useLayoutEffect(() => {
			$(".row-tooltip").tooltip();
		  }, []);

		return (
			<React.Fragment>
				<TableCell data-toggle="tooltip" data-placement="top" title={ title }  style={{ maxWidth: "3px" }} component="th" scope="row" align="center" size="small" className={classColor + ' embudo all custom-lineHeight row-tooltip'}  classes={{ root: classes.root }}>
				
				</TableCell>
			</React.Fragment>
		);
	}

	const Controles = (props) => {

		const porcentajeLitrosCargados = ConfigBusiness.get('combustibles.porcentajeLitrosCargados');
		const litrosControlEntreCargas = ConfigBusiness.get('combustibles.litrosControlEntreCargas');
		const toleranciaUbicacion = ConfigBusiness.get('combustibles.toleranciaUbicacion');
		const variabilidadRendimientoInferior = ConfigBusiness.get('combustibles.toleranciaRendimientoInferior');
		const variabilidadRendimientoSuperior = ConfigBusiness.get('combustibles.toleranciaRendimientoSuperior');
		const controlDistanciaEntreCargas = ConfigBusiness.get('combustibles.controlDistanciaEntreCargas.habilitado') == 'true' ? true : false;
		const kmControlDistancia = ConfigBusiness.get('combustibles.controlDistanciaEntreCargas.kilometros')
		const controlChofer = ConfigBusiness.get('combustibles.controlConductor.habilitado') == 'true' ? true : false;

		let map = null;
		let marker = null;
		let litrosCada100km = props.litrosCada100km;
		
		const { combustible } = props;
		
		let rendimientoUnidad;
		let signoRendimiento;
		if(litrosCada100km === 'true'){
			signoRendimiento = " + ";
			rendimientoUnidad = ' LT/100Km';
		} else{
			signoRendimiento = " - ";
			rendimientoUnidad = ' Kms/LT';
		}

		//variables para popper
		const [anchorEl, setAnchorEl] = useState(null);
		const openPopper = Boolean(anchorEl);
		const idPopper = openPopper ? 'simple-popper' : undefined;
		const [arrowRef, setArrowRef] = useState(null);
		
		/*POR AHORA NO SE VA A USAR ESTE CONTROL*/
		/* DIFERENCIA LITROS EN ESTACIÓN */
		/*INFO: este control queda para telemetría avanzada
		//INFO: si esto lo habilita hay que copiarlo en el rowCallback con los cambios corresponndientes
		if(full['diferencia_litros_tanque']){
			if(parseFloat(full['diferencia_litros_tanque'])*(1-parseFloat(component.state.porcentajeLitrosCargados)/100)<=parseFloat(full['litros']) && parseFloat(full['litros'])<=parseFloat(full['diferencia_litros_tanque'])*(1+parseFloat(component.state.porcentajeLitrosCargados)/100)){
				//cadena += '<div style="width:auto; line-height: 1.5rem;"><i className="ft-check combustibles-litros-icon combustibles-carga-verde dt-info-icon"></i>';
				devolver += '<div style="width:auto; line-height: 1.5rem;"><img className="div-diferencia-litros" style="height: 40px" src="'+TanqueVerde+'"/>'
			} else{
					let porcentajeDesvio = (Math.abs(parseFloat(full['diferencia_litros_tanque']) - parseFloat(full['litros']))*100)/ parseFloat(full['litros']);
					//	cadena += '<div style="width:auto; line-height: 1.5rem;"><i className="ft-x combustibles-litros-icon combustibles-carga-rojo dt-info-icon" data-togle="tooltip" data-placement="top" data-html="true" data-original-title="Litros cargados difieren de los litros informados"></i>';
					devolver += '<div style="width:auto; line-height: 1.5rem;"><img className="div-diferencia-litros" style="height: 40px" src="'+TanqueRojo+'" data-togle="tooltip" data-placement="top" data-html="true" data-original-title="Litros Cargados '+full['diferencia_litros_tanque']+', difieren en un '+Math.floor(porcentajeDesvio)+'% de lo informado"></i>';
			}
		}	else{
			devolver += '<div style="width:auto; line-height: 1.5rem;"><img className="div-diferencia-litros" style="height: 40px" src="'+TanqueGris+'" data-togle="tooltip" data-placement="top" data-html="true" data-original-title="'+ (full['diferencia_litros_tanque'] === null ? 'Sin valor de litros en el tanque': 'No aumentaron los litros en el tanque')+'"></img>';
		}
		devolver += '</div>';*/

		const initMapPopover = ()=>{
			if (combustible.control_ubicacion == 'INVALIDA' && combustible.direccion_coincide !== null) {
				initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) }, { latitudDetectada: parseFloat(combustible.latitud_detectada_avl), longitudDetectada: parseFloat(combustible['longitud_detectada_avl']) });
			} else {
				initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) });
			}
		}

		function initMap(id, markerInformado, markerDetectado) {

			let lat = markerInformado['latitudInformada'];
			let long = markerInformado['longitudInformada'];
			let zoom = 14;
			let label = "";
			if (markerDetectado) {
				lat = (markerDetectado['latitudDetectada'] + lat) / 2;
				long = (markerDetectado['longitudDetectada'] + long) / 2;
				zoom = 8;
				label = "I";
			}
			var centro = { lat: lat, lng: long };
			map = new window.google.maps.Map(document.getElementById('map' + id), {
				zoom: zoom,
				center: centro
			});
			// Place a draggable marker on the map
			marker = new window.google.maps.Marker({
				position: { lat: markerInformado['latitudInformada'], lng: markerInformado['longitudInformada'] },
				map: map,
				title: props.intl.formatMessage({ id: 'combustiblesGrid.google_maps.direccion_informada', defaultMessage: 'Dirección Informada' }),
				label: label
			});
	
			if (markerDetectado) {
	
				marker = new window.google.maps.Marker({
					map: map,
					title: props.intl.formatMessage({ id: 'combustiblesGrid.google_maps.direccion_detectada', defaultMessage: 'Direccion Detectada' }),
					position: { lat: markerDetectado['latitudDetectada'], lng: markerDetectado['longitudDetectada'] },
					label: "D"
				});
			}

		}
	
		function placeMarkerAndPanTo(event) {
			geocodeLatLng(event.latLng.lat(), event.latLng.lng());
		}
	
		function geocodeLatLng(lat, lng) {
			var geocoder = new window.google.maps.Geocoder;
			var latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
			let component;
			geocoder.geocode({ 'location': latlng }, geocodeLocation);
		}
	
		function geocodeLocation(results, status) {
	
			if (status === 'OK') {
				if (results[0]) {
	
					//this.fillInAddress(results[0], false).then(()=>{this.actualizarUbicacionInput();});
					//this.actualizarUbicacionInput();
				}
			}
		}

		useLayoutEffect(() => {
			$(".row-tooltip").tooltip();
		  }, []);

		let metros = parseFloat(combustible.direccion_coincide);
		let litrosInformados = parseFloat(combustible['litros_despues_carga_anterior']) - parseFloat(combustible['dif_litros_avl']) + parseFloat(combustible['litros']);
		let dif = Math.abs(litrosInformados - parseFloat(combustible['litros_despues_carga']));

		const handleOpenMap = (event) => {
			
			setAnchorEl(anchorEl ? null : event.currentTarget);
			
		};

		const handleCloseMap = () =>{
			setAnchorEl(null);
		}

		useEffect((component) => {
			if(openPopper)
			{
				if (combustible.control_ubicacion == 'INVALIDA' && combustible.direccion_coincide !== null) {
					initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) }, { latitudDetectada: parseFloat(combustible.latitud_detectada_avl), longitudDetectada: parseFloat(combustible.longitud_detectada_avl) });
				} else {
					initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) });
				}
			}
		},[openPopper]);

		let titlePopper = "";
		if(combustible.control_ubicacion == 'INVALIDA' && combustible.direccion_coincide !== null){
			titlePopper = props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.direccion_informada', defaultMessage: 'Dirección informada a ' }) + (metros > 1000 ? (metros / 1000).toFixed(2) + ' kilómetros' : metros.toFixed(2) + ' metros') + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.posicion_obtenida_avl', defaultMessage: ' de la posición obtenida en el AVL.' });
		} else{
			titlePopper = (combustible.bandera ? combustible.bandera : '') + 'Dir: ' + combustible.direccion_carga;
		}

		let popperComponent = 
							<Popper 
								id={idPopper} 
								open={openPopper} 
								anchorEl={anchorEl} 
								className={classes.popper}
								keepMounted={true} 
								placement={'right'}
								modifiers={{
									flip: {
									  enabled: true,
									},
									preventOverflow: {
									  enabled: true,
									  boundariesElement: 'scrollParent',
									},
									arrow: {
									  enabled: true,
									  element: arrowRef,
									},
								  }}
								 >
									<span  
										className={classes.arrow}
										ref={setArrowRef} />
									<div className="card popover" style={{border: "1px solid #00000033", maxWidth:"300px", height:"275px",left:"10px", top:"-136px"}}>
										<div className="card-header" style={{padding: "0.5rem 0.75rem",
																			marginBottom: "0",
																			fontSize: "1rem",
																			color: "#464855",
																			backgroundColor: "#f7f7f7"}}>
										{ titlePopper } 
										</div>
										<div id={"map" + combustible.id } className="map-popover"
									style={{
										margin: "10px",
										padding: "0.5rem 0.75rem",
										backgroundColor: "#000",
									  }}></div>
									  </div>
									  
								</Popper>

		return (
			<React.Fragment>
				<div className="text-center success dt-info-icon row" /*onMouseLeave={() => handleCloseMap()}*/ style={{padding: "16px",width:'max-content'}}>
					{/* POSITION */}
					{(combustible.control_ubicacion && combustible.control_ubicacion != 'SIN_COMPROBACION') ?
						(combustible.control_ubicacion == 'VALIDA' ) ?
							<ClickAwayListener onClickAway={()=>handleCloseMap()}>
								<div>
									<i id={arrowRef} className="fa fa-map-marker fa-xs combustibles-carga-verde combustibles-ubicacion" data-toggle="popover" data-html="true" data-placement="right" 
										onClick={handleOpenMap}
										aria-describedby={idPopper} style={{ cursor: "pointer" }}>
									</i>
									
									{popperComponent}
									
								</div>
							</ClickAwayListener>
							:
							(combustible.direccion_coincide === null) ?
								<div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_momento_carga', defaultMessage: 'GPS sin posición en el momento de la carga' })}
									data-placement="top" data-html="true" data-togle="tooltip" className="div-position row-tooltip">
									<i className="fa fa-map-marker fa-xs combustibles-tooltip-amarillo"></i>
								</div>
								:
								<ClickAwayListener onClickAway={()=>handleCloseMap()}>
									<div>
										<i id={arrowRef} className="fa fa-map-marker fa-xs combustibles-carga-rojo " data-toggle="popover" data-html="true" data-placement="right"
											onClick={handleOpenMap} aria-describedby={idPopper} style={{ cursor: "pointer" }}>
										</i>
										{popperComponent}
									</div>
								</ClickAwayListener>
						:
						<div data-original-title={(!combustible.latitud_informada ? props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_direccion_informada', defaultMessage: 'Sin coordenadas de dirección informada' }) : props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_satelital', defaultMessage: 'Sin Posición de Disp. Satelital' }))}
							className="div-position row-tooltip" data-html="true" data-togle="tooltip" data-placement="top">
							<i className="fa fa-map-marker fa-xs combustibles-tooltip-gris"></i>
						</div>
					}

					{/* DIFERENCIA CAPACIDAD DE TANQUE */}
					{(combustible.control_tanque && combustible.control_tanque != 'SIN_COMPROBACION') ?
						(combustible.control_tanque == 'VALIDA') ?
							<div style={{width:"auto", lineHeight: "1.5rem"}}>
								<img className="div-diferencia-tanque" style={{height: "40px"}} src={TanqueVerde} /></div>
							:
							(<div style={{width:"auto", lineHeight: "1.5rem"}}>
									<div data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.litros_cargados_mayor_capacidad_tanque', defaultMessage: 'Litros Cargados mayor a la capacidad del tanque' })}
									className="row-tooltip">
										<img className="div-diferencia-tanque" style={{height: "40px"}} src={TanqueRojo}>
										</img>   
									</div>
								
							</div>)
						:
						<div style={{width:"auto", lineHeight: "1.5rem"}}>
								<div data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.no_datos_capacidad_tanque', defaultMessage: 'No hay datos de la capacidad del tanque' })}
								className="row-tooltip">
									<img className="div-diferencia-tanque" style={{height: "40px"}} src={TanqueGris} >
									</img>
								</div>
							
						</div>
					}

					{/* RENDIMIENTO */}
					{(!combustible.control_rendimiento) ? (
						<div
							className="row-tooltip"
							data-placement="top"
							data-togle="tooltip"
							data-html="true"
						>
							<div style={{width:"auto", lineHeight: "1.5rem"}}>
								<img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaGris} />
							</div>
						</div>
					) : null}
					{(combustible.control_rendimiento === 'SIN_COMPROBACION') ? (
						<div
							className="row-tooltip"
							data-placement="top"
							data-togle="tooltip"
							data-html="true"
							data-original-title={
								props.intl.formatMessage(backendStrings[combustible.control_rendimiento_msg] ? 
									backendStrings[combustible.control_rendimiento_msg] 
									: backendStrings['combustiblesGrid.column.controles.rendimiento_esperado_init']
								)}
						>
							<div style={{width:"auto", lineHeight: "1.5rem"}}>
								<img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaGris} />
							</div>
						</div>
					) : null}
					{(combustible.control_rendimiento === 'INVALIDA') ? ( 
						<div
							className="row-tooltip"
							data-placement="top"
							data-togle="tooltip"
							data-html="true"
							data-original-title={
								props.intl.formatMessage(backendStrings[combustible.control_rendimiento_msg] ? 
									backendStrings[combustible.control_rendimiento_msg] 
									: backendStrings['combustiblesGrid.column.controles.rendimiento_esperado_init'])
								+ (combustible.control_rendimiento_msg == 'combustiblesGrid.column.controles.rendimiento_esperado_carga_sin_recorrido' ? '' :  
								combustible.rendimiento_esperado
								+' '+rendimientoUnidad
								+' ('+signoRendimiento
								+(combustible.control_rendimiento_msg == 'combustiblesGrid.column.controles.rendimiento_esperado_superior' ? variabilidadRendimientoSuperior : variabilidadRendimientoInferior)
								+'%)'
								)}
						>
							<div style={{width:"auto", lineHeight: "1.5rem"}}>
								<img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaRojo} />
							</div>
						</div>
					) : null}
					{(combustible.control_rendimiento === 'VALIDA') ? (
						<div
							className="row-tooltip"
							data-placement="top"
							data-togle="tooltip"
							data-html="true"
							data-original-title={
								props.intl.formatMessage(backendStrings[combustible.control_rendimiento_msg] ? 
									backendStrings[combustible.control_rendimiento_msg] 
									: backendStrings['combustiblesGrid.column.controles.rendimiento_esperado_init']
								) + combustible.rendimiento_esperado+' '+rendimientoUnidad+' ('+signoRendimiento+variabilidadRendimientoInferior +'%)'}
						>
							<div style={{width:"auto", lineHeight: "1.5rem"}}>
								<img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaVerde} />
							</div>
						</div>
					) : null}


					{/*	 devolver += '<div data-html="true" data-placement="top" data-toggle="tooltip" title="Servicio: ">';
				//devolver += '<img style="height: 40px" src="'+surtidores[Math.floor(Math.random() * surtidores.length)]+'"/></div>';*/}
					{/*(combustible['dif_litros_avl'] && combustible['litros_despues_carga'] && combustible['litros_despues_carga_anterior'] && combustible['litros']) ?

						((dif <= parseFloat(litrosControlEntreCargas)) ?
							<div data-placement="top" data-togle="tooltip" data-html="true" data-original-title="" className="div-carga" className="row-tooltip">
								<img style={{height: "40px"}} src={MangueraVerde} />
							</div>
							:
							<div className="div-carga row-tooltip" data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.faltante_de', defaultMessage: 'Faltante de ' }) + Math.floor(dif) + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.de_litros', defaultMessage: ' litros' })}>
								<img style={{height: "40px"}} src={MangueraRoja} />
							</div>
						)
						:
						<div className="div-carga row-tooltip" data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.datos_insuficientes', defaultMessage: 'Datos Insuficientes' })}>
							<img className="" style={{height: "40px"}} src={MangueraGris} />
						</div>
				*/}

				{/* CONTROL TARJETA */}
				{(combustible.control_tarjeta) ?
						(combustible.control_tarjeta == 'INVALIDA') ?
						<div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.invalida', defaultMessage: 'La tarjeta de combustible de la carga y la asignada al vehículo no coinciden' })}
							data-placement="top" data-html="true" data-togle="tooltip" className="div-position row-tooltip">
							<i className="fa fa-credit-card fa-xs combustibles-carga-rojo"></i>
						</div>

						:
						(combustible.control_tarjeta == 'SIN_COMPROBACION') ?
							<div data-original-title={(!combustible.tarjeta ? props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.carga_sin_tarjeta_combustible', defaultMessage: 'La carga se realizó sin tarjeta de combustible' }) : props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.movil_sin_tarjeta_combustible', defaultMessage: 'El vehículo no tiene tarjeta asignada' }))}
								className="div-position row-tooltip" data-html="true" data-togle="tooltip" data-placement="top">
								<i className="fa fa-credit-card fa-xs combustibles-tooltip-gris"></i>
							</div>
							:
							<div>
								<i className="fa fa-credit-card fa-xs combustibles-carga-verde"></i>
							</div>

					:
					<div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.sin_control_tarjeta', defaultMessage: 'No se realizó el control de tarjeta' })}
						data-placement="top" data-html="true" data-togle="tooltip" className="div-position row-tooltip">
						<i className="fa fa-credit-card fa-xs combustibles-tooltip-gris"></i>
					</div>
					}

				  {/* CONTROL DISTANCIA */}
				  {(controlDistanciaEntreCargas) ?
						(combustible.control_distancia == 'INVALIDA') 
						?
						<div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.distancia.invalida', defaultMessage: 'La distancia entre cargas es menor a '}) + kmControlDistancia + 'Km'}
							data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
							<img style={{height: "40px"}} src={ControlDistanciaRojo} />
						</div>

						:
						(combustible.control_distancia == 'SIN_COMPROBACION' || combustible.control_distancia == null) ?
						<div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.distancia.sin_comprobacion', defaultMessage: 'No hay información suficiente de la carga anterior'})}
						  data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
						 <img style={{height: "40px"}} src={ControlDistanciaGris} />
						</div>

						:
						<div style={{marginLeft:'5px'}}>
						  <img style={{height: "40px"}} src={ControlDistanciaVerde} />
						</div>
					  :
					  ''
					}

					{/* CONTROL CHOFER */}
					{(controlChofer) ?
						  (combustible.control_chofer == 'INVALIDA') 
						  ?
						  <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'El chofer informado'}) +' "'+ combustible.conductor_informado + '"' + 
							props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.nomatch', defaultMessage: ' por el proveedor no coincide con ningún/o de los registrados en Sistema: '}) + "<br/><br/>" 
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_supervisor', defaultMessage: ' Supervisor'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.supervisor_persona) ? combustible.control_movil_chofer.supervisor_persona.fullName : '') + '<br/>'
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable1', defaultMessage: ' Responsable 1'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable1_persona) ? combustible.control_movil_chofer.responsable1_persona.fullName : '') + '<br/>'
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable2', defaultMessage: ' Responsable 2'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable2_persona) ? combustible.control_movil_chofer.responsable2_persona.fullName : '') + '<br/>'
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_temporal', defaultMessage: ' Temporal'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.temporal_persona) ? combustible.control_movil_chofer.temporal_persona.fullName : '')                            
							}
							data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
							<img style={{height: "40px"}} src={ControlChoferRojo} />
						  </div>

						  // <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'El chofer informado: '}) + combustible.conductor_informado + ' no coincide con el registrado: ' + combustible.conductor_nombre_apellido}
						  //     data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
						  //     <img style={{height: "40px"}} src={ControlChoferRojo} />
						  // </div>

						  :
						  (combustible.control_chofer == 'SIN_COMPROBACION' || combustible.control_chofer == null) ?
						  <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.sin_comprobacion', defaultMessage: 'No hay información para la comprobación'})}
							data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
							<img style={{height: "40px"}} src={ControlChoferGris} />
						  </div>

						  :
						  <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'El chofer informado'}) +' "'+ combustible.conductor_informado + '"' + 
							props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.match', defaultMessage: ' por el proveedor coincide con algún/os de los registrados en Sistema: '}) + "<br/><br/>" 
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_supervisor', defaultMessage: ' Supervisor'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.supervisor_persona) ? combustible.control_movil_chofer.supervisor_persona.fullName : '') + '<br/>'
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable1', defaultMessage: ' Responsable 1'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable1_persona) ? combustible.control_movil_chofer.responsable1_persona.fullName : '') + '<br/>'
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable2', defaultMessage: ' Responsable 2'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable2_persona) ? combustible.control_movil_chofer.responsable2_persona.fullName : '') + '<br/>'
							+ props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_temporal', defaultMessage: ' Temporal'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.temporal_persona) ? combustible.control_movil_chofer.temporal_persona.fullName : '')
							}
							data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
							<img style={{height: "40px"}} src={ControlChoferVerde} />
						  </div>
						:
						''
					  }
				</div>


			</React.Fragment>
		);

	}

	const handleView = (combId) => {
		props.setRedirectTo("/combustibles/" + combId);
	};

	const handleEdit = (combId) => {
		props.setRedirectTo("/combustibles/" + combId + "/edit");
	};
	
	const handleApprove = (combId) => {

		let titleObs = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.observaciones.placeholder', defaultMessage: 'Observaciones' });
		let titleMotive = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla', defaultMessage: 'Motivo para validarla..' });
		let titleRequired = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla_obligatorio', defaultMessage: 'El motivo es obligatorio' });

		let div = document.createElement("div");

		var motivosValidezSelect = document.createElement('select');
		motivosValidezSelect.className = "form-group col-12 Select-control swal-validar-combustibles";
		motivosValidezSelect.id = "confirm-motivoCambioEstado";
		motivosValidezSelect.onchange = function () {
			$(".error-motivo").attr('hidden', true);
		};

		let opcionMotivo = document.createElement('option');
			//opcionMotivo.className = "";
			opcionMotivo.text = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla', defaultMessage: 'Motivo para validarla..' });
			opcionMotivo.value = "";
			opcionMotivo.selected = "selected";
			opcionMotivo.disabled = "disabled";
			opcionMotivo.className = "swal-validar-combustibles";
			motivosValidezSelect.appendChild(opcionMotivo);

			props.motivosValidez.forEach((element)=>{
			let opcionMotivo = document.createElement('option');
			//opcionMotivo.className = "";
			opcionMotivo.value = element.id;
			opcionMotivo.text = element.nombre;
			opcionMotivo.className = "swal-validar-combustibles";
			motivosValidezSelect.appendChild(opcionMotivo);
		});

		var errorMotivo = document.createElement("div");
		errorMotivo.className = "help-block text-danger field-message error-motivo mb-1";
		errorMotivo.innerText = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla_obligatorio', defaultMessage: 'El motivo es obligatorio' });
		errorMotivo.hidden = true;

		div.append(motivosValidezSelect);
		div.append(errorMotivo);

		var observaciones = document.createElement('textarea');
		observaciones.placeholder= props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.observaciones.placeholder', defaultMessage: 'Observaciones' });
		observaciones.className = "swal-content__textarea";
		observaciones.id = "confirm-observacionesCambioEstado";

		div.append(observaciones);
 
		
		function recursiveCall(index) {
			return new Promise((resolve)=>{
				swal({
					title: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.information.pasar_valida', defaultMessage: 'Pasar a Válida' }),
					text: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.confirma_validar_carga', defaultMessage: '¿Confirma que desea validar la carga?' }),
					icon: "warning",
					content: div,
					buttons: {
						confirm: {
							text: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.confirma_validar_carga.si', defaultMessage: 'Si' }),
							value: true,
							visible: true,
							className: "btn btn-primary",
							closeModal: false
						},
						cancel: {
							text: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.confirma_validar_carga.no', defaultMessage: 'No' }),
							value: null,
							visible: true,
							className: "btn btn-danger",
							closeModal: true,
						}
					}
				}).then((isConfirm) => {
					if (isConfirm) {
						if (!$('#confirm-motivoCambioEstado').val()) {
							swal.stopLoading();
							$(".error-motivo").attr('hidden', false);

							return resolve(recursiveCall(++index));
						} else {
							ajaxHandler.fetch('/combustibles/'+ combId +'/validar', {
								method: 'POST',
								body: JSON.stringify({
									observaciones: $('#confirm-observacionesCambioEstado').val(),
									motivo_validez_id: $('#confirm-motivoCambioEstado').val()
								}),
								headers: {
									'Accept': 'application/json',
									'Content-Type': 'application/json',
									'Authorization-Token': localStorage.getItem("token")
								}
							}).then((response) => {
								if (response.status === 200) {
									//se cambia el valor de changeQuery para que se dispare el efecto de actualización de la tabla
									props.changeTable();
									swal(props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.carga_validada', defaultMessage: 'Carga validada.' }), "", "success");
								}
							}).catch((error) => {
								ajaxHandler.handleError(error);
							});
						}
					}
				});//then
			});//promise
		}//recursive function
		recursiveCall(0);
		
	};

	return (
		<React.Fragment>
			<TableRow key={combustible.id}>
				<StyledTableCell show={props.generacionMasiva} align="left" style={{ maxWidth: '30px' }}>
          <input type="checkbox" checked={props.selectedCombustibles.includes(combustible.id)} onChange={props.handleChecked(combustible.id)} style={{ maxWidth: "22px" }} className="customCheckbox cursor-pointer" id={combustible.id} />
        </StyledTableCell>
				<TableCell classes={{ root: classes.root }}>
					<Dominio combustible={combustible} intl={intl}></Dominio>
				</TableCell>

				{(columnUnidad) &&
					<TableCell align="center" classes={{ root: classes.root }}>
						{ combustible.unidad || '' }
					</TableCell>
				}
				
				<Estado columnData={combustible.estado} motivo={combustible.motivo_validez_forzado} intl={intl}/>
				
				<TableCell align="center" classes={{ root: classes.root }} style={{ minWidth: "165px", padding: "0" }}>
					<Controles combustible={combustible} intl={intl} litrosCada100km={props.litrosCada100km}/>
				</TableCell>

				<TableCell align="center" classes={{ root: classes.root }}>
					{(combustible.tarjeta) ?
						<div>
							{ (combustible.fecha_hora_carga ? moment(combustible.fecha_hora_carga).format('YYYY-MM-DD HH:mm') : "") +" "}
							<div style={{ position: "relative", width: "auto",display: "inline-block" }} data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.carga.con.tarjeta.combustible', defaultMessage: 'Carga realizada con tarjeta de combustible' })} data-placement="top" data-togle="tooltip"
							className="row-tooltip">
							<i className="fa fa-credit-card" ></i>
							</div>
						</div>
						:
						<div>
							{ combustible.fecha_hora_carga ? moment(combustible.fecha_hora_carga).format('YYYY-MM-DD HH:mm') : "" }
						</div>
					}

				</TableCell>

				{(columnTarjetaSaldo) && 
				<TableCell align="center" classes={{ root: classes.root }}>
					{combustible.tarjeta_saldo ? '$ '+parseFloat(combustible.tarjeta_saldo).toFixed(2) : ''}
				</TableCell>
				}

				<TableCell align="center" className="utilizacion" classes={{ root: classes.root }}>
					<Utilizacion combustible={combustible} intl={intl}></Utilizacion>{/*TODO: Revisar que no le estoy pasando combustible para probar si es necesario*/}
				</TableCell>
				<TableCell align="center" classes={{ root: classes.root }}>
					<Litros combustible={combustible} intl={intl}></Litros>
				</TableCell>
				<TableCell align="center" classes={{ root: classes.root }}>
					{(combustible.total) ?
						"$ " + combustible.total
						: ""}
				</TableCell>

				<TableCell align="center" classes={{ root: classes.root }} style={{lineHeight:"35px"}}>
					{(combustible.diferencia_precio_litro <= 0) ?
						<div className="row" style={{ margin: "auto", flexWrap: "nowrap" }}>
							$ {combustible.precio_litro}
							<i className="fa fa-caret-down fa-xs combustibles-precio-alta"></i>
						</div>
						:
						<div className="row" style={{ margin: "auto", flexWrap: "nowrap" }}>
							$ {combustible.precio_litro} <i className="fa fa-caret-up fa-xs combustibles-precio-baja"></i>
						</div>
					}
				</TableCell>
				<TableCell align="center" classes={{ root: classes.root }}>
					<Direccion combustible={combustible} intl={intl}></Direccion>
				</TableCell>
				{columnOrigen &&
				<TableCell align="center" classes={{ root: classes.root }}>
					{combustible.origen}
				</TableCell>
				}
				<TableCell align="center" classes={{ root: classes.root }}>
					{combustible.km_litro}
				</TableCell>
				<TableCell align="center" className="text-center costo_km" classes={{ root: classes.root }}>
					{(combustible.costo_km) ?
						"$ " + combustible.costo_km
						: ""}
				</TableCell>
				{columnBase &&
				<TableCell align="center" className="text-center" classes={{ root: classes.root }}>
					{(combustible.base) ? combustible.base : ""}
				</TableCell>
				}
				{(columnConductorInformado) &&
				  <TableCell align="center" className="text-center" classes={{ root: classes.root }}>
					  {combustible.conductor_informado}
				  </TableCell>
				}

				<TableCell align="center" classes={{ root: classes.root }}>
					<div style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
						{Security.hasPermission('COMBUSTIBLES_VISUALIZAR') ?
							<button className="action view btn btn-sm btn-icon btn-dt-grid text-success" title={intl.formatMessage({ id: 'combustiblesGrid.column.action.ver.title', defaultMessage: 'Ver' })} data-togle="tooltip" data-placement="top" onClick={() => handleView(combustible.id)}>
								<i className="fa fa-search fa-xs"></i>
							</button> : ""}

						{(Security.hasPermission('COMBUSTIBLES_MODIFICAR')) ?
							<button className="action edit btn btn-sm btn-icon btn-dt-grid text-success" title={intl.formatMessage({ id: 'combustiblesGrid.column.action.editar.title', defaultMessage: 'Editar' })} data-togle="tooltip" data-placement="top" onClick={() => handleEdit(combustible.id)}>
								<i className="fa fa-pencil fa-xs"></i>
							</button> : ''}

						{((combustible.estado === 'SIN_COMPROBACION' || combustible.estado === 'INVALIDA') && Security.hasPermission('COMBUSTIBLES_VALIDAR')) ?
							<button className="action approve btn btn-sm btn-icon btn-dt-grid text-success" title={intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.title', defaultMessage: 'Validar' })} data-togle="tooltip" data-placement="top" onClick={() => handleApprove(combustible.id)}>
								<i className="fa fa-thumbs-up fa-xs"></i>
							</button> : ''}
					</div>
				</TableCell>
			</TableRow>

		</React.Fragment>
	);
}

const Utilizacion = (props) => {
	const { combustible } = props;

	useLayoutEffect(() => {
		$(".row-tooltip").tooltip();
	  }, []);

	 let title = props.intl.formatMessage({ id: 'combustiblesGrid.column.utilizacion_full.title.km_recorridos_ultima_carga', defaultMessage: '-Km recorridos desde última carga ' }) + '<br>' + props.intl.formatMessage({ id: 'combustiblesGrid.column.utilizacion_full.title.kilometraje_móvil', defaultMessage: '-Kilometraje del móvil ' }) ;
	
		return (
			combustible.utilizacion ?
			<div data-original-title={title} data-html="true" data-placement="top" data-html="true" data-togle="tooltip" style={{ position: "relative" }} className="row-tooltip"> {parseFloat(combustible.utilizacion).toFixed(2)} Km
				{(combustible.odometro) ?
					<div style={{fontSize:"0.8rem"}}> {parseFloat(combustible.odometro).toFixed(2)} Km
					</div>
					: null}
			</div>
			: null
		);
	   
};

const Litros = (props) => {

	useLayoutEffect(() => {
		$(".row-tooltip").tooltip();
	  }, []);

	return (
		<div style={{lineHeight: "16px"}}>
			<div style={{display: "inline-flex"}}>
				<div style={{ position: "relative", width: "auto" }} data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.litros.informados', defaultMessage: 'Litros Informados' })} data-placement="top" data-togle="tooltip"
				className="row-tooltip">
					{props.combustible.litros}
				</div>
			</div>
			{props.combustible.combustible_tipo ?
				<div style={{fontSize:"0.8rem"/*, marginTop: "-1.6rem"*/}}>
					{props.combustible.combustible_tipo}
				</div>
				: null}
		</div>
	);
}

const Direccion = (props) => {

	useLayoutEffect(() => {
		$(".row-tooltip").tooltip();
	  }, []);

	let calle = props.combustible.direccion_carga.split(" - ");
	let tooltip = calle.length > 1;

	return (
		<div style={{lineHeight: "16px"}}>
			<div style={{display: "inline-flex"}}>
				{tooltip ?
				<div style={{ position: "relative", width: "auto" }} data-html="true" data-original-title={props.combustible.direccion_carga} data-placement="top" data-togle="tooltip"
				className="row-tooltip">
					{calle[0]}
				</div>
				: calle[0]}
			</div>
		</div>
	);
}

const Dominio = (props) => {

	useLayoutEffect(() => {
		$(".row-tooltip").tooltip();
	  }, []);

	const handleOpenMarcaModelo = (event) => {            
		setAnchorEl(event.currentTarget);        
	};
	
	const handleCloseMarcaModelo = (event) => {            
		setAnchorEl(null);        
	};

	useEffect((component) => {
		if(openPopper)
		{
		}
	},[openPopper]);

	const aHover = () => { 
		setLinkStyle({color: 'white',textDecoration: 'underline', fontSize: '0.875rem', fontWeight: '400'});
	}
	const aLeave = () => {
		setLinkStyle({color: 'white', fontSize: '0.875rem', fontWeight: '400'});
	}

	const [linkStyle, setLinkStyle] = useState({color: 'white', fontSize: '0.875rem', fontWeight: '400'});
	const [anchorEl, setAnchorEl] = useState(null);
	const openPopper = Boolean(anchorEl);
	const idPopper = openPopper ? 'simple-popper' : undefined;
	const [arrowRef, setArrowRef] = useState(null);

	let hrefMarcaModelo = "/modelos/"+props.combustible.modelo_id+"/edit";

	let popperComponent = <Popper 
		id={idPopper} 
		open={openPopper} 
		anchorEl={anchorEl} 
		keepMounted={true} 
		placement={'top-start'}
		>
		<div ref={setArrowRef} style={{padding: "0.4rem 0"}} onMouseLeave={handleCloseMarcaModelo} >
			<div style={{padding: "0.25rem 0.5rem", textAlign:"center", backgroundColor: "#000", borderRadius:"0.25rem"}}>
				<a style={linkStyle} onMouseOver={aHover} onMouseLeave={aLeave} href={hrefMarcaModelo}>{props.combustible.marca} - {props.combustible.modelo}</a>
			</div>
		<i className="fa fa-caret-down fa-xs" style={{fontSize:"1.2rem", marginLeft: "1.5rem", marginTop:"-0.4rem",position: "absolute", color: "#000"}}></i>
		</div>
	</Popper>
	return (
		<div style={{lineHeight: "16px"}} onMouseLeave={handleCloseMarcaModelo}>
			<div style={{display: "inline-flex"}}>
				<div id={arrowRef} data-toggle="popover" data-html="true" data-placement="top" onMouseEnter={handleOpenMarcaModelo} aria-describedby={idPopper}>
					{props.combustible.dominio}
				</div>                
				{popperComponent}
			</div>
		</div>
	);
}

const CombustiblesGridNew = (props) => {

	const intl = useIntl()
	const [apiBaseUrl, setApiBaseUrl] = useState(process.env.REACT_APP_VEC_FLEET_API_BASE_URL);
	//this.columnsToPrint = [0, 1, 2, 3, 4, 5, 6, 7];

	const [redirectTo, setRedirectTo] = useState(null);
	const [ajaxHandler, setAjaxHandler] = useState(props.ajaxHandler);
	const dataService = "/combustibles/grid";
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({page: 0, count: 1, perPage: 10});
	const [changePagination, setChangePagination] = useState(false);
	const [switchEstadoVehiculo,setSwitchEstadoVehiculo] = useState(false);
	const [query, setQuery] = useState({
		page: 0, 
		marca: "", 
		modelo: "", 
		base: "",
		pais: "",
		centroCosto: "", 
		region: "",
		subRegion:"",
		dominio: "",      
		estado: "",
		estadoVehiculo: true,
		movilDetail: props.location.state && props.location.state.filterMovilDetail ? props.location.state.filterMovilDetail : null,
		lugarCarga: "",
		filtroPeriodoInicio: moment().startOf('year').format('YYYY-MM-DD'),
		filtroPeriodoFin: moment().format('YYYY-MM-DD'),
		fechaHoraCargaSort: "",
		dominioSort: "",
		litrosSort: "",
		importeSort: "",
		precioLitroSort: "",
		utilizacionSort: "",
		direccionInformadaSort: "",
		litros100Sort: "",
		kmsLitroSort: "",
		costoKmSort: "",
		tarjetaSaldo:"",
		control:"",
		conductorInformadoSort:"",
		unidad:"",
		unidadSort:"",
		baseSort: '',
		origenSort: ''
	});
	const [changeQuery, setChangeQuery] = useState(false);

	moment.locale('es');

	const [combustibles, setCombustibles] = useState([]);
	const [filtroPeriodoInicio, setFiltroPeriodoInicio] = useState(moment().startOf('year').format('YYYY-MM-DD'));//useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
	const [filtroPeriodoFin, setFiltroPeriodoFin] = useState(moment().format('YYYY-MM-DD'));// useState(moment().format('YYYY-MM-DD'));
	
	const periodos = [

		{ id: 1, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.hoy.label', defaultMessage: 'Hoy' }) },
		{ id: 2, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ayer.label', defaultMessage: 'Ayer' }) },
		{ id: 7, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_siete_dias.label', defaultMessage: 'Últimos 7 días' }) },
		{ id: 14, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_catorce_dias.label', defaultMessage: 'Últimos 14 días' }) },
		{ id: 20, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_treinta_dias.label', defaultMessage: 'Últimos 30 días' }) },
		{ id: 30, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimo_mes.label', defaultMessage: 'Este mes' }) },
		{ id: 60, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.mes_pasados.label', defaultMessage: 'Mes Pasado' }) },
		{ id: 365, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.este_anio.label', defaultMessage: 'Este año' }) },
		{ id: 400, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.anio_pasado.label', defaultMessage: 'Año Pasado' }) },
		{ id: 0, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.todo.label', defaultMessage: 'Todo' })  },
		{ id: 12, label: "" }
	];
	const controlesInit = [
		{ value: 'ubicacion_valida', label: 'Ubicación Válida' },
		{ value: 'ubicacion_sin_comprobacion', label: 'Ubicación Sin Comprobación' },
		{ value: 'ubicacion_invalida', label: 'Ubicación Inválida' },
		{ value: 'tanque_valida', label: 'Tamaño de Tanque Válida' },
		{ value: 'tanque_sin_comprobacion', label: 'Tamaño de Tanque Sin Comprobación' },
		{ value: 'tanque_invalida', label: 'Tamaño de Tanque Inválida' },
		{ value: 'rendimiento_valida', label: 'Rendimiento Válida' },
		{ value: 'rendimiento_sin_comprobacion', label: 'Rendimiento Sin Comprobación' },
		{ value: 'rendimiento_invalida', label: 'Rendimiento Inválida' },
		{ value: 'tarjeta_valida', label: 'Tarjeta Válida' },
		{ value: 'tarjeta_sin_comprobacion', label: 'Tarjeta Sin Comprobación' },
		{ value: 'tarjeta_invalida', label: 'Tarjeta Inválida' },
		
	];
	const addControlesDistancia = [
		{ value: 'distancia_valida', label: 'Distancia Válida' },
		{ value: 'distancia_sin_comprobacion', label: 'Distancia Sin Comprobación' },
		{ value: 'distancia_invalida', label: 'Distancia Inválida' }
	];

	const origenes = [
		{value: 'MANUAL', label: props.intl.formatMessage({ id: 'combustibles.select.origen_manual', defaultMessage: 'MANUAL' })},
		{value: 'IMPORTADOR', label: props.intl.formatMessage({ id: 'combustibles.select.origen_importador', defaultMessage: 'IMPORTADOR' })},
		{value: 'API', label: props.intl.formatMessage({ id: 'combustibles.select.origen_api', defaultMessage: 'API' })}
	]

	
	const [unidadTiempo, setUnidadTiempo] = useState('day');
	const [estadisticas, setEstadisticas] = useState([]);
	const [invalidas, setInvalidas] = useState([]);
	const [filtersActive, setFiltersActive] = useState(false);
	
	const [dataToExport, setDataToExport] = useState({
		fechaHoraCarga: true,
		movil: true,
		unidad: ConfigBusiness.get('combustibles.unidad.grilla') === 'true' ? true : false,
		utilizacion: true,
		litros: true,
		importe: true,
		precioLitro: true,
		direccionInformada: true,
		kmLitros: true,
		costoKm: true,
		estado: true,
		base: ConfigBusiness.get('combustibles.base.grilla') === 'true' ? true : false,
		centroCostosNombre: true,
		responsable1NombreApellido: true,
		responsable2NombreApellido: true,
		conductor: true,
		conductorInformado: ConfigBusiness.get('combustibles.controlConductor.grilla') === 'true' ? true : false,
		region:true,
		subRegion:true,
		combustibleTipos:true,
		odometro:true,
		rendimiento:true,
		tarjetaSaldo:true,
		origen: ConfigBusiness.get('combustibles.origen.grilla') === 'true' ? true : false
	});

	const [bases, setBases] = useState([]);
	const [paises, setPaises] = useState([]);
	const [regiones, setRegiones] = useState([]);
	const [subRegiones, setSubRegiones] = useState([]);
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [centroCostos, setCentroCostos] = useState([]);
	const estados = [{ value: '', label: 'Todos' }, { value: 'INVALIDA', label: 'Inválidas' }, { value: 'VALIDA', label: 'Válidas' }];
	const [controles, setControles] = useState(controlesInit);
	 
	const [base, setBase] = useState(null);
	const [pais, setPais] = useState(null);
	const [centroCosto, setCentroCosto] = useState(null);
	const [region, setRegion] = useState(null);
	const [subRegion, setSubRegion] = useState(null);
	const [modelo, setModelo] = useState(null);
	const [dominio, setDominio] = useState("");
	const [unidad, setUnidad] = useState("");
	const [lugarCarga, setLugarCarga] = useState("");
	const [estado, setEstado] = useState(null);
	const [control, setControl] = useState(null);
	const [movilDetail, setMovilDetail] = useState(props.location.state && props.location.state.filterMovilDetail ?
		props.location.state.filterMovilDetail : null );
	const [estadoVehiculo,setEstadoVehiculo] = useState(true);
	const [columnConductorInformado,setColumnConductorInformado] = useState(false);
	const [origen, setOrigen] = useState("");

	const paisEnabled = ConfigBusiness.get('combustibles.pais.habilitado') === 'true' ? true : false;
	const [columnUnidad,setColumnUnidad] = useState(false);
	const columnBase = ConfigBusiness.get('combustibles.base.grilla') === 'true' ? true : false;
	const columnOrigen = ConfigBusiness.get('combustibles.origen.grilla') === 'true' ? true : false;

	//inicialización del filtro de período: por defecto es 365 - Este año
	var periodoInicial = periodos.find(element => element.id == 365)
	const [periodoSelect, setPeriodoSelect] = useState( periodoInicial );
	let periodo = periodoInicial;

	const [porcentajeLitrosCargados, setPorcentajeLitrosCargados] = useState(0);
	const [litrosControlEntreCargas, setLitrosControlEntreCargas] = useState(0);
	const [toleranciaUbicacion, setToleranciaUbicacion] = useState(0);
	const [modelosLoading, setModelosLoading] = useState(false);
	const [variabilidadRendimientoInferior, setVariabilidadRendimientoInferior] = useState(0);
	const [variabilidadRendimientoSuperior, setVariabilidadRendimientoSuperior] = useState(0);
	const [marca, setMarca] = useState(null);
	const [motivosValidez, setMotivosValidez] = useState([]);
	const [reporteImportacion, setReporteImportacion] = useState(null);
	const [mensajeImportacion, setMensajeImportacion] = useState("");
	const [columnTarjetaSaldo, setColumnTarjetaSaldo] = useState(true);
	const [typeImport, setTypeImport] = useState("");
	const litrosCada100km = ConfigBusiness.get('moviles.rendimientoLitrosCada100km');
	const [selectedCombustibles, setSelectedCombustibles] = useState([])
	const [allChecked, setAllCheked] = useState(false)
	const [validatorSearch, setValidatorSearch] = useState(false)

	const textos = [];
	textos['widget_gasto_total'] = props.intl.formatMessage({ id: 'combustiblesGrid.card.widget_gasto_total', defaultMessage: 'Gasto total de combustible' });
	textos['widget_litros_consumidos'] = props.intl.formatMessage({ id: 'combustiblesGrid.card.widget_litros_consumidos', defaultMessage: 'Total de Litros Consumidos' });
	textos['widget_kms_litros'] = props.intl.formatMessage({ id: 'combustiblesGrid.periodos.card.widget_kms_litros', defaultMessage: 'Promedio de Kms por Litro' });
	textos['widget_promedio_costo_litros'] = props.intl.formatMessage({ id: 'combustiblesGrid.card.widget_promedio_costo_litros', defaultMessage: 'Promedio del Costo por Litro' });

	const map = null;
	let marker = null;

	let abortController = new AbortController();
	let signal = abortController.signal;
	//estructura que guarda las cargas que aun no se validaron con el AVL para actualizar su valor en la grilla, si es que se valida mientas se está en la pantalla
	let cargasAControlar = [];
	let stopControles = false;
	const classes = useStyles();

	const getData = (service, completo = false) => {
	   
		let serviceURL = "";
		if (!completo){
			serviceURL = Config.get('apiUrlBase') + service;
		}else{
			serviceURL = service;
		}
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

	useEffect(() => {
		//componentDidMount
		let filterEstado = localStorage.getItem("filterInvalida");
		if(filterEstado === "true"){
			handleInvalidas('INVALIDA');
		} else {
			setEstado(null);
		}

		if (!Security.hasPermission('COMBUSTIBLES_LISTAR')) {
			setRedirectTo('/error');
		}
		window.scrollTo(0, 0);
		
		setLoading(true);

		if( ConfigBusiness.get('combustibles.controlDistanciaEntreCargas.habilitado') === 'true' ){
			setControles(controles.concat(addControlesDistancia))
		}
		
		  
		Promise.all([
			getData('/bases/select'),
			getData('/centros-costos/select'),
			getData('/regiones/select'),
			getData('/subregiones/select-all'),
			ConfigBusiness.get('combustibles.porcentajeLitrosCargados'),
			ConfigBusiness.get('combustibles.litrosControlEntreCargas'),
			ConfigBusiness.get('combustibles.toleranciaUbicacion'),
			getData('/marcas/select'),
			ConfigBusiness.get('combustibles.toleranciaRendimientoInferior'),
			ConfigBusiness.get('combustibles.toleranciaRendimientoSuperior'),
			getData('/combustibles-motivos-validez/select'),
			ConfigBusiness.get('combustibles.tarjetaSaldo'),
			ConfigBusiness.get('combustibles.controlConductor.grilla'),            
			ConfigBusiness.get('combustibles.unidad.grilla'),            
			getData('/paises/select'),
		]).then((data) => {

		   /* let filters = {
				estado: [{ value: '', label: 'Todos' }, { value: 'INVALIDA', label: 'Inválidas' }, { value: 'VALIDA', label: 'Válidas' }]//, {value: 'SIN_COMPROBACION', label: 'Sin Comprobación'}
			};*/
			
			setBases(data[0] ? data[0] : []);
			setCentroCostos(data[1] ? data[1] : []);
			setRegiones(data[2] ? data[2] : []);
			setSubRegiones(data[3] ? data[3] : []);
			setPorcentajeLitrosCargados(data[4] ? data[4] : 0);
			setLitrosControlEntreCargas(data[5] ? data[5] : 0);
			setToleranciaUbicacion(data[6] ? data[6] : 0);
			setMarcas(data[7] ? data[7] : []);
			setVariabilidadRendimientoInferior(data[8] ? data[8] : 0);
			setVariabilidadRendimientoSuperior(data[9] ? data[9] : 0);
			setMotivosValidez(data[10] ? data[10] : []);
			setColumnTarjetaSaldo((data[11] && data[11] === 'true') ? true : false);
			setColumnConductorInformado((data[12] && data[12] === 'true') ? true : false);
			setColumnUnidad((data[13] && data[13] === 'true') ? true : false);
			setPaises(data[14] ? data[14] : []);
		}).catch(function (error) {
			ajaxHandler.handleError(error);
		}).finally(() => {
			setLoading(false);
		});

		//componentWillUnmount
		return () => {
			localStorage.setItem('filterInvalida', "false");

			stopControles = true;

			abortController.signal.addEventListener('abort', (event) => {

				ajaxHandler.unsubscribe();
			});
			abortController.abort();
			abortController = null;
			cargasAControlar = [];
			signal = null;
			$('div.tooltip[role="tooltip"]').tooltip('dispose');
			$('div.tooltip[role="tooltip"]').remove();
			$('div.popover[role="tooltip"]').remove();
		};
	}, []);

	/**Efecto para carga y recarga de la grilla */
	useEffect(() => {
		setLoading(true);
		let urlService = Config.get('apiUrlBase') + dataService + '/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + dataTableQuery();
		getData(urlService,true).then((data)=>{
			setLoading(false);
			setCombustibles(data.combustibles);
			setPagination(data.pagination);
			getWidgets();
		}).catch(function(error) {
			setLoading(false);
			ajaxHandler.handleError(error);
		}).finally(() => {
			setLoading(false);
		});

	},[query, changePagination]);

	useEffect(() => {
		setValidatorSearch(validator());
	},[
		query,
		filtroPeriodoInicio,
		filtroPeriodoFin,
		marca,
		modelo,
		region,
		subRegion,
		base,
		pais,
		estado,
		estadoVehiculo,
		control,
		lugarCarga,
		unidad,
		origen,
		centroCosto,
		dominio
	])

	const validator = () => {
		if(filtroPeriodoInicio) return false;
		if(filtroPeriodoFin) return false;

		if(marca) return false;
		
		if(modelo) return false;

		if(region && region.length > 0)  return false;

		if(subRegion && subRegion.length > 0) return false;

		if(centroCosto && centroCosto.length > 0) return false;

		if(base && base.length > 0) return false; 

		if(pais && pais.length > 0) return false; 

		if(estado) return false;

		if(estadoVehiculo) return false;

		if(control && control.length > 0) return false; 

		if(lugarCarga) return false;

		if(dominio) return false;

		if(unidad) return false;

		if(origen) return false

		return true
	}

	const getWidgets = () => {
		calcularUnidadTiempoForWidgets().then((unidad) => {
			let url = new URL(Config.get('apiUrlBase') + '/combustibles/sparkline/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + '/' + unidad);
			let urlInvalidas = new URL(Config.get('apiUrlBase') + '/combustibles/cargas-invalidas/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + '/' + unidad);

			if (query.base) {
				url.searchParams.append('bases[0][value]', query.base);
				urlInvalidas.searchParams.append('bases[0][value]', query.base);
			} else {
				url.searchParams.append('bases', '');
				urlInvalidas.searchParams.append('bases', '');
			}

			if (query.pais) {
				url.searchParams.append('paises[0][value]', query.pais);
				urlInvalidas.searchParams.append('paises[0][value]', query.pais);
			} else {
				url.searchParams.append('paises', '');
				urlInvalidas.searchParams.append('paises', '');
			}

			if (query.region){
				url.searchParams.append('regiones[0][value]', query.region);
				urlInvalidas.searchParams.append('regiones[0][value]', query.region);
			}else {
				url.searchParams.append('regiones', '');
				urlInvalidas.searchParams.append('regiones', '');
			}

			if (query.subRegion){
				url.searchParams.append('subRegiones[0][value]', query.subRegion);
				urlInvalidas.searchParams.append('subRegiones[0][value]', query.subRegion);
			}else {
				url.searchParams.append('subRegiones', '');
				urlInvalidas.searchParams.append('subRegiones', '');
			}

			if (query.centroCosto){
				url.searchParams.append('centroCostos[0][value]', query.centroCosto);
				urlInvalidas.searchParams.append('centroCostos[0][value]', query.centroCosto);
			}else {
				url.searchParams.append('centroCostos', '');
				urlInvalidas.searchParams.append('centroCostos', '');
			}

			if(query.dominio){
				url.searchParams.append('dominio', query.dominio);
				urlInvalidas.searchParams.append('dominio', query.dominio);
			} else {
				url.searchParams.append('dominio', '');
				urlInvalidas.searchParams.append('dominio', '');
			}

			if(query.unidad){
				url.searchParams.append('unidad', query.unidad);
				urlInvalidas.searchParams.append('unidad', query.unidad);
			} else {
				url.searchParams.append('unidad', '');
				urlInvalidas.searchParams.append('unidad', '');
			}

			if(query.estado){
				url.searchParams.append('estado', query.estado);
				urlInvalidas.searchParams.append('estado', query.estado);
			} else {
				url.searchParams.append('estado', '');
				urlInvalidas.searchParams.append('estado', '');
			}

			if (query.control){
				url.searchParams.append('controles[0][value]', query.control);
				urlInvalidas.searchParams.append('controles[0][value]', query.control);
			}else {
				url.searchParams.append('controles', '');
				urlInvalidas.searchParams.append('controles', '');
			}

			if (query.modelo) {
				url.searchParams.append('modelos[0][value]', query.modelo);
				urlInvalidas.searchParams.append('modelos[0][value]', query.modelo);

			} else {
				url.searchParams.append('modelos', '');
				urlInvalidas.searchParams.append('modelos', '');
			}

			if (query.marca) {
				let index = 0;
				url.searchParams.append('marcas[value]', query.marca);
				urlInvalidas.searchParams.append('marcas[value]', query.marca);
			} else {
				url.searchParams.append('marcas', '');
				urlInvalidas.searchParams.append('marcas', '');
			}

			if (query.estadoVehiculo !== null) {
				url.searchParams.append('estadoVehiculo', query.estadoVehiculo);
				urlInvalidas.searchParams.append('estadoVehiculo', query.estadoVehiculo);
			} else {
				url.searchParams.append('estadoVehiculo', '');
				urlInvalidas.searchParams.append('estadoVehiculo', '');
			}

			if (query.lugarCarga !== null) {
				url.searchParams.append('lugarCarga', query.lugarCarga);
				urlInvalidas.searchParams.append('lugarCarga', query.lugarCarga);
			} else {
				url.searchParams.append('lugarCarga', '');
				urlInvalidas.searchParams.append('lugarCarga', '');
			}

			if (query.movilDetail) {
				let index = 0;
				url.searchParams.append('extraFilterMovilDetail', query.movilDetail.movil);
				urlInvalidas.searchParams.append('extraFilterMovilDetail', query.movilDetail.movil);
			} else {
				url.searchParams.append('extraFilterMovilDetail', '');
				urlInvalidas.searchParams.append('extraFilterMovilDetail', '');
			}

			Promise.all([
				getData(url, true),
				getData(urlInvalidas, true)

			]).then((data) => {
				setEstadisticas(data[0]);
				setInvalidas(data[1]);
			}).catch(function(error) {
				ajaxHandler.handleError(error);
			}).finally(() => {
				setLoading(false);
			});

		});
	}

	const dataTableQuery = () => {
		let myQuery = '?page=' + pagination.page;
		myQuery += '&perPage=' + pagination.perPage;

		myQuery += base && base.length ? '&bases[0][value]=' + base.map((val) => val.value) : '&bases=';
		myQuery += pais && pais.length ? '&paises[0][value]=' + pais.map((val) => val.value) : '&paises=';
		myQuery += centroCosto && centroCosto.length ? '&centroCostos[0][value]=' + centroCosto.map((val) => val.value) : '&centroCostos=';
		myQuery += region && region.length ? '&regiones[0][value]=' + region.map((val) => val.value) : '&regiones=';
		myQuery += subRegion && subRegion.length ? '&subRegiones[0][value]=' + subRegion.map((val) => val.value) : '&subRegiones=';
		myQuery += modelo ? '&modelos[0][value]=' + modelo.value : '&modelos=';
		myQuery += marca ? '&marcas[0][value]=' + marca.value : '&marcas=';
		myQuery += estadoVehiculo ? '&estadoVehiculo=' + estadoVehiculo : '&estadoVehiculo=';
		myQuery += dominio ? '&dominio=' + dominio : '&dominio=';
		myQuery += unidad ? '&unidad=' + unidad : '&unidad=';
		myQuery += estado ? '&estado=' + estado.value : '&estado=';
		myQuery += control && control.length ? '&controles[0][value]=' + control.map((val) => val.value) : '&controles=';
		myQuery += origen ? '&origen=' + origen.value : '&origen=';
		myQuery += movilDetail ? '&extraFilterMovilDetail=' + movilDetail.movil : '&extraFilterMovilDetail=';
		myQuery += lugarCarga ? '&lugarCarga=' + lugarCarga : '&lugarCarga=';
		myQuery += '&fechaHoraCargaSort=' + query.fechaHoraCargaSort;
		myQuery += '&dominioSort=' + query.dominioSort;
		myQuery += '&unidadSort=' + query.unidadSort;
		myQuery += '&litrosSort=' + query.litrosSort;
		myQuery += '&importeSort=' + query.importeSort;
		myQuery += '&precioLitroSort=' + query.precioLitroSort;
		myQuery += '&utilizacionSort=' + query.utilizacionSort;
		myQuery += '&direccionInformadaSort=' + query.direccionInformadaSort; 
		myQuery += '&litros100Sort=' + query.litros100Sort;
		myQuery += '&kmsLitroSort=' + query.kmsLitroSort;
		myQuery += '&costoKmSort=' + query.costoKmSort;
		myQuery += '&tarjetaSaldo=' + query.tarjetaSaldo;
		myQuery += '&conductorInformadoSort=' + query.conductorInformadoSort;
		myQuery += '&baseSort=' + query.baseSort;
		myQuery += '&origenSort=' + query.origenSort;
		
		return myQuery;
										
	}

	const dataTableUpdate = () => {
		let urlService = Config.get('apiUrlBase') + dataService + '/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + dataTableQuery();
		
		fetch(urlService, {
			method: 'GET',
			headers: {
				'Authorization-Token': localStorage.getItem("token")
			}
		}).then(
			response => response.json()
		).then(
			data => {
				let comb = data.combustibles;
				setCombustibles(comb);
				let pagination = data.pagination;
				setPagination(pagination);
			}
		);
	}
	
	const handleChangePage = (event, newPage) => {
		pagination.page = newPage;
		setChangePagination(!changePagination);
	};

	const handleChangeRowsPerPage = (event) => {
		pagination.perPage = parseInt(event.target.value, 10);
		pagination.page = 0;
		setChangePagination(!changePagination);
	};

	function calcularUnidadTiempoForWidgets() {

		return new Promise((resolve) => {
			let diferencia = (filtroPeriodoFin && filtroPeriodoInicio) ? moment(filtroPeriodoFin).diff(moment(filtroPeriodoInicio), "days") : 366; //se setea un valor para que entre en el último else
			let unidad = "";
			if (diferencia <= 14) {
				unidad = null;
			} else if (diferencia > 14 && diferencia <= 63) {
				unidad = "week";
			} else if (diferencia > 63 && diferencia <= 365) {
				unidad = "month";
			} else {
				unidad = "year";
			}

			setUnidadTiempo(unidad);
			resolve(unidad);
		});
	}

	function handleEstadoVehiculo (value){
	  if(value){
		let data = {
		  value : false,
		}
		handleSelectFormChange('estadoVehiculo',data)
	  }
	  else{
		let data = {
		  value : true,
		}
		handleSelectFormChange('estadoVehiculo',data)
	  }

	  setSwitchEstadoVehiculo(value)
	}

	function handleSelectFormChange(name, object) {
		setMovilDetail(null);
		if (name === "periodo") {
			setPeriodoSelect(object);
			periodo = object;
			let inicio = "";
			let fin = "";
			if (object && object.id !== 12) {

				switch (object.id) {
					case 1:
						inicio = moment().format('YYYY-MM-DD');
						fin = moment().format('YYYY-MM-DD');
						break;
					case 2:
						inicio = moment().subtract(1, 'days').format('YYYY-MM-DD');
						fin = inicio;
						break;
					case 7:
						inicio = moment().subtract(7, 'days').format('YYYY-MM-DD');
						fin = moment().format('YYYY-MM-DD');
						break;
					case 14:
						inicio = moment().subtract(14, 'days').format('YYYY-MM-DD');
						fin = moment().format('YYYY-MM-DD');
						break;
					case 20:
						inicio = moment().subtract(30, 'days').format('YYYY-MM-DD');
						fin = moment().format('YYYY-MM-DD');
						break;
					case 30:
						inicio = moment().startOf('month').format('YYYY-MM-DD');
						fin = moment().format('YYYY-MM-DD');
						break;
					case 60:
						inicio = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
						fin = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
						break;
					case 400:
						inicio = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
						fin = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
						break;
					case 0:
						inicio = null;
						fin = null;
						break;
					default:
						inicio = moment().startOf('year').format('YYYY-MM-DD');
						fin = moment().format('YYYY-MM-DD');
				}

			} else if (object.inicio) {
				inicio = moment(object.inicio).format('YYYY-MM-DD');
				fin = moment(object.fin).format('YYYY-MM-DD');
			}
			setFiltroPeriodoInicio(inicio);
			setFiltroPeriodoFin(fin);

		} else {
			let valor;
			valor = object ? object : "";
			switch (name) {
				case 'marca':
					setMarca(valor); 
					handleMarcaChange(valor);
					break;
				case 'modelo':
					setModelo(valor); break;
				case 'region': 
					setRegion(valor);break;
				case 'subRegion': 
					setSubRegion(valor);break;
				case 'centroCosto': 
					setCentroCosto(valor); break;
				case 'base': 
					setBase(valor); break;
				case 'pais': 
					setPais(valor); break;
				case 'estado':
					setEstado(valor); localStorage.setItem('filterInvalida', "false"); break;
				case 'estadoVehiculo':
					setEstadoVehiculo(valor.value);break;                
				case 'control': 
					setControl(valor);break;
				case 'origen': 
					setOrigen(valor); break;
			}
		}
	}

	function updateQuery(){
		query['movilDetail'] = movilDetail ? movilDetail : "";
		query['marca'] = marca ? marca.value : "";
		query['modelo'] = modelo ? modelo.value : ""
		query['region'] = region && region.length ? region.map((val) => val.value) : ""
		query['subRegion'] = subRegion && subRegion.length ? subRegion.map((val) => val.value) : ""
		query['centroCosto'] = centroCosto && centroCosto.length ? centroCosto.map((val) => val.value) : ""
		query['base'] = base && base.length ? base.map((val) => val.value) : ""
		query['pais'] = pais && pais.length ? pais.map((val) => val.value) : ""
		query['estado'] = estado ? estado.value : ""
		query['estadoVehiculo'] = estadoVehiculo ? estadoVehiculo : ""
		query['control'] = control && control.length ? control.map((val) => val.value) : ""
		query['lugarCarga'] = lugarCarga ? lugarCarga : ""
		query['dominio'] = dominio ? dominio : ""
		query['unidad'] = unidad ? unidad : ""
		query['origen'] = origen ? origen.value : ""
		query['filtroPeriodoInicio'] = filtroPeriodoInicio ? filtroPeriodoInicio : ''
		query['filtroPeriodoFin'] = filtroPeriodoFin ? filtroPeriodoFin : ''
		setQuery(JSON.parse(JSON.stringify(query)));
	}

	function handleInputFormChange(name, object){
		pagination.page = 0;        
		if(name === "dominio")
			setDominio(object.target.value);

		if(name === "unidad")
			setUnidad(object.target.value);

		if(name === "lugarCarga")
			setLugarCarga(object.target.value);
	}

	function checkFiltersActive(){
		if(query.base || query.pais || query.centroCosto || query.region || query.subRegion || query.modelo || query.marca || query.dominio || query.unidad || query.lugarCarga
			|| query.estado || periodo.id != 0 || !query.estadoVehiculo || query.control ){
			setFiltersActive(true);
		} else {
			setFiltersActive(false);
		}
	}

	function handleMarcaChange(object) {

		return new Promise((resolve, reject) => {
			if (object) {

				setModelo(null);
				setModelos([]);

				//setModelosLoading(true);
				ajaxHandler.getJson('/modelos/marca/' + object.value + '/select')
					.then((data) => {
						setModelos(data);
						resolve();
					}).finally(() => {
						//setModelosLoading(false);
					});
		
			} else {
				setModelo(null);
				resolve();
			}
		});
	}

	function handleCancelModal(message, subtitle, error) {
		$("#import_modal").modal('toggle');
	}

	function handleCloseModal(message, filasFallaron, error) {
		$("#import_modal").modal('toggle');

		if (message !== "" && error) {
			if (error.codigo === 4010) {
				swal(props.intl.formatMessage({ id: 'combustiblesGrid.alert.sesion_expiro', defaultMessage: 'La sesión expiró' }), props.intl.formatMessage({ id: 'combustiblesGrid.alert.ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' }), "error").then(() => {
					window.location.reload();
				});
			} else if (error.codigo) {
				swal(props.intl.formatMessage({ id: 'combustiblesGrid.alert.error', defaultMessage: 'Error' }), message, "error");
			} else {
				swal(message, error, "error");
			}
		} else {
			//{"filas_fallaron":{"1":[{"id":"4","fila":"6","dominio":"AA008FU","motivo_falla":"Carga duplicada","created_at":"2020-08-25 16:54:43","updated_at":"2020-08-25 16:54:43","combustibles_archivo_importacion_id":"3"},{"id":"5","fila":"7","dominio":"AA008FU","motivo_falla":"Carga duplicada","created_at":"2020-08-25 16:54:43","updated_at":"2020-08-25 16:54:43","combustibles_archivo_importacion_id":"3"},{"id":"6","fila":"8","dominio":"AA008FR","motivo_falla":"No se encontr\u00f3 el m\u00f3vil","created_at":"2020-08-25 16:54:43","updated_at":"2020-08-25 16:54:43","combustibles_archivo_importacion_id":"3"}]}}

			if (filasFallaron.filas_fallaron.length == 0) {
				swal(message, "", "success").then((data) => {
					reloadTable();
				});
			} else {
				setReporteImportacion(filasFallaron.filas_fallaron);
				
				setMensajeImportacion(message);
				$('#import_report_modal').modal({ backdrop: 'static' });
			}


		}
	}

	function handleReportCloseModal() {
		$("#import_report_modal").modal('toggle');
		reloadTable();
	}

	function handleInvalidas(estado) {
		setEstado({ value: 'INVALIDA', label: 'Inválidas' });
		query['estado'] = estado;
		setQuery(JSON.parse(JSON.stringify(query)));
	}

	const handleDataExport = (event, name) => {
		let dataToExportCopy = JSON.parse(JSON.stringify(dataToExport));
		dataToExportCopy[name] = !dataToExportCopy[name];
		setDataToExport(dataToExportCopy);
	  };

	function handleExportExcel() {

		setLoading(true);
		ajaxHandler.fetch('/combustibles/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				colsAExportar: dataToExport,
				fechaInicio: Timezone.getDateForServer(filtroPeriodoInicio),
				fechaFin: Timezone.getDateForServer(filtroPeriodoFin),
				regiones: query.region ? [{'value': query.region.toString()}] : null,
				subRegiones: query.subRegion ? [{'value': query.subRegion.toString()}] : null,
				centroCostos: query.centroCosto ? [{'value': query.centroCosto.toString()}] : null,
				bases: query.base ? [{'value': query.base.toString()}] : null,
				paises: query.pais ? [{'value': query.pais.toString()}] : null,
				modelos: query.modelo ? [{'value': query.modelo}] : null,
				marcas: query.marca ? {'value': query.marca} : null,
				dominio: query.dominio ? query.dominio : null,
				unidad: query.unidad ? query.unidad : null,
				estado: query.estado ? query.estado : null,
				lugarCarga: query.lugarCarga ? query.lugarCarga : null,
				estadoVehiculo: query.estadoVehiculo ? query.estadoVehiculo : null,
				controles: query.control ? [{'value': query.control.toString()}] : null,
				origen: query.origen ? query.origen : null,
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-combustibles.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			handleExportCancel();
			setLoading(false);
		});
	}

	function handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	function handleNew(event) {
		setRedirectTo(props.match.url + '/add');
	}

	const controlCargasPendientesAVL = (async () => {

		while (this.cargasAControlar.length > 0 && !this.stopControles) {
			for (let element in this.cargasAControlar) {
				let row = this.cargasAControlar[element].row;
				let data = this.cargasAControlar[element].data;

				await this.ajaxHandler.getJsonOptions('/combustibles/controles-carga/' + data.id, {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
					},
					signal: this.signal,

				})
					.then((result, data) => {
						if (result.valido_direccion_avl == 1) {

							$(".km_litro", row).text(result['km_litro']);
							if (result['costo_km']) {
								$(".costo_km", row).text("$ " + result['costo_km']);
							} else {
								$(".costo_km", row).text("");
							}

							let ulilizacionText = this.getUtilizacion(result);
							$(".utilizacion", row).html(ulilizacionText);
							if (result['utilizacion']) {
								let ulilizacionText = this.getUtilizacion(result);
								$(".utilizacion", row).html(ulilizacionText);
								$(".utilizacion>div", row).tooltip("enable");
							}
							else {
								$(".utilizacion>div", row).tooltip("disable");
								$(".utilizacion", row).text("");
							}

							let title = result.estado === 'SIN_COMPROBACION' ? props.intl.formatMessage({ id: 'combustiblesGrid.column.createdCell.sin_comprobacion', defaultMessage: 'Sin Comprobación' }) : result.estado;

							if (result.motivo_validez_forzado) {
								title += " | " + result.motivo_validez_forzado;
							}

							$(".embudo", row).attr('data-original-title', title);
							$(".embudo", row).tooltip();

							if (result.estado == 'INVALIDA') {
								$(".embudo", row).addClass('status-red');
								$(".embudo", row).removeClass('status-green');
								$(".embudo", row).removeClass('status-yellow');
							} else if (result.estado == 'VALIDA') {
								$(".embudo", row).addClass('status-green');
								$(".embudo", row).removeClass('status-red');
								$(".embudo", row).removeClass('status-yellow');
							} else {
								$(".embudo", row).addClass('status-yellow');
								$(".embudo", row).removeClass('status-green');
								$(".embudo", row).removeClass('status-red');
							}

							/* POSITION */
							if (result['direccion_coincide'] === null) {
								$(".div-position", row).tooltip("enable");
								$("div .fa-map-marker", row).css("cursor", "auto");

								if (result['razon_invalidez'] == 'GPS sin posición en el momento de la carga') {
									$(".div-position", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_momento_carga', defaultMessage: 'GPS sin posición en el momento de la carga' }));
									$(".div-position i", row).removeClass("combustibles-carga-gris");
									$(".div-position i", row).addClass("combustibles-tooltip-amarillo");
								}
								else {
									$(".div-position", row).attr("data-original-title", !result['latitud_informada'] ? props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_direccion_informada', defaultMessage: 'Sin coordenadas de dirección informada' }) : props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_satelital', defaultMessage: 'Sin Posición de Disp. Satelital' }));
									$(".div-position i", row).removeClass("combustibles-carga-amarillo");
									$(".div-position i", row).addClass("combustibles-tooltip-gris");
								}
								$(".div-position i", row).removeClass("combustibles-carga-rojo");
								$(".div-position i", row).removeClass("combustibles-carga-verde");
								$(".fa-map-marker[data-toggle='popover']", row).popover('dispose');

							} else {
								let metros = parseFloat(result['direccion_coincide']);
								$("div .fa-map-marker", row).css("cursor", "pointer");
								$(".div-position[data-toggle='tooltip']", row).tooltip("disable");

								$(".fa-map-marker", row).attr("data-toggle", "popover");
								$(".fa-map-marker", row).attr("data-html", true);
								$(".fa-map-marker", row).attr("data-placement", "right");
								if (metros > parseFloat(toleranciaUbicacion)) {
									$(".fa-map-marker", row).removeClass("combustibles-tooltip-gris");
									$(".fa-map-marker", row).removeClass("combustibles-tooltip-amarillo");
									$(".fa-map-marker", row).removeClass("combustibles-carga-verde");
									$(".fa-map-marker", row).addClass("combustibles-carga-rojo");

									$(".fa-map-marker", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.direccion_informada', defaultMessage: 'Dirección informada a ' }) + (metros > 1000 ? (metros / 1000).toFixed(2) + ' kilómetros' : metros.toFixed(2) + ' metros') + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.posicion_obtenida_avl', defaultMessage: ' de la posición obtenida en el AVL.' }));

								} else {

									$(".fa-map-marker", row).attr("data-original-title", (result['bandera'] ? result['bandera'] : '') + 'Dir: ' + result['direccion_carga']);
									$(".fa-map-marker", row).removeClass("combustibles-carga-rojo");
									$(".fa-map-marker", row).removeClass("combustibles-tooltip-gris");
									$(".fa-map-marker", row).removeClass("combustibles-tooltip-amarillo");
									$(".fa-map-marker", row).addClass("combustibles-carga-verde");
								}
								$(".fa-map-marker", row).on('inserted.bs.popover', function () {
									if (parseFloat(result['direccion_coincide']) > 100) {
										this.initMap(result['id'], { latitudInformada: parseFloat(result['latitud_informada']), longitudInformada: parseFloat(result['longitud_informada']) }, { latitudDetectada: parseFloat(result['latitud_detectada_avl']), longitudDetectada: parseFloat(result['longitud_detectada_avl']) });
									} else {
										this.initMap(result['id'], { latitudInformada: parseFloat(result['latitud_informada']), longitudInformada: parseFloat(result['longitud_informada']) });
									}
								});
								$(".fa-map-marker", row).popover({ content: '<div id="map' + result["id"] + '" className="map-popover"></div>' }).addClass('popover-combustibles');

							}

							/* DIFERENCIA CAPACIDAD DE TANQUE */
							if (result['capacidad_tanque']) {
								if (parseFloat(result['capacidad_tanque']) >= result['litros']) {
									$(".div-diferencia-tanque", row).attr("src", TanqueVerde);
									$(".div-diferencia-tanque", row).removeAttr("data-togle");
									$(".div-diferencia-tanque", row).removeAttr("data-placement");
									$(".div-diferencia-tanque", row).removeAttr("data-original-title");
									$(".div-diferencia-tanque[data-toggle='tooltip']", row).tooltip("disable");
								} else {
									$(".div-diferencia-tanque", row).attr("src", TanqueRojo);
									$(".div-diferencia-tanque", row).attr("data-togle", "tooltip");
									$(".div-diferencia-tanque", row).attr("data-placement", "top");
									$(".div-diferencia-tanque", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.litros_cargados_mayor_capacidad_tanque', defaultMessage: 'Litros Cargados mayor a la capacidad del tanque' }));
									$(".div-diferencia-tanque", row).tooltip("enable");
								}
							} else {
								$(".div-diferencia-tanque", row).attr("src", TanqueGris);
								$(".div-diferencia-tanque", row).attr("data-togle", "tooltip");
								$(".div-diferencia-tanque", row).attr("data-placement", "top");
								$(".div-diferencia-tanque", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.no_datos_capacidad_tanque', defaultMessage: 'No hay datos de la capacidad del tanque' }));
								$(".div-diferencia-tanque", row).tooltip("enable");
							}

							if (result['dif_litros_avl'] != null && result['litros_despues_carga'] != null && result['litros_despues_carga_anterior'] != null && result['litros'] != null) {

								let litrosInformados = parseFloat(result['litros_despues_carga_anterior']) - parseFloat(result['dif_litros_avl']) + parseFloat(result['litros']);
								let dif = Math.abs(litrosInformados - parseFloat(result['litros_despues_carga']));

								if (dif <= parseFloat(litrosControlEntreCargas)) {
									$(".div-carga", row).attr("src", MangueraVerde);
									$(".div-carga", row).removeAttr("data-original-title");
								} else {
									$(".div-carga", row).attr("src", MangueraRoja);
									$(".div-carga", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.faltante_de', defaultMessage: 'Faltante de ' }) + Math.floor(dif) + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.de_litros', defaultMessage: ' litros' }));
								}
							} else {
								$(".div-carga", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.datos_insuficientes', defaultMessage: 'Datos Insuficientes' }));
								$(".div-carga", row).attr("src", MangueraGris);
							}

							/**RENDIMIENTO ESPERADO */
							if (result['km_litro']) {
								if (!result.rendimiento_esperado) {
									$(".div-rendimiento", row).attr("src", SurtidorHojaGris);
									$(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.no_datos_rendimiento_esperado', defaultMessage: 'No hay datos del rendimiento esperado' }));
								} else if (parseFloat(result.km_litro) > 0 && parseFloat(result.km_litro) <= parseFloat(result.rendimiento_esperado * (1 + parseFloat(variabilidadRendimientoInferior) / 100))) {
									$(".div-rendimiento", row).attr("src", SurtidorHojaVerde);
									$(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.rendimiento_esperado', defaultMessage: 'Rendimiento Esperado: ' }) + result.rendimiento_esperado + " LT/100Km + " + variabilidadRendimientoInferior + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.tolerancia', defaultMessage: '% de tolerancia' }));
								} else {
									$(".div-rendimiento", row).attr("src", SurtidorHojaRojo);
									$(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.else_rendimiento_esperado', defaultMessage: 'Rendimiento Esperado: ' }) + result.rendimiento_esperado + " LT/100Km + " + variabilidadRendimientoInferior + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.else_tolerancia', defaultMessage: '% de tolerancia' }));
								}
							} else if (result.rendimiento_esperado && result.utilizacion === "0.000") {
								$(".div-rendimiento", row).attr("src", SurtidorHojaRojo);
								$(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.no_full.title.rendimiento_menor_esperado', defaultMessage: 'Rendimiento menor al esperado: realizó carga sin recorrer kilómetros' }));
							}
							else {
								$(".div-rendimiento", row).attr("src", SurtidorHojaGris);
								$(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.no_hay_datos', defaultMessage: 'No hay datos de ' }) + "LT/100Km");
							}

							this.cargasAControlar.splice(element, 1);
						}

					})
					.catch((result, data) => { });
			}

			await this.sleep(3000)//cada 3 segundos se vuelven a controlar las cargas
		}
	});

	function sleep(time) {
		return new Promise(r => setTimeout(r, time))
	}

	const [checkedFilter, setCheckedFilter] = React.useState(false);

	const handleChange = () => {
	  setCheckedFilter((prev) => !prev);
	};
  
	const handleFilterReset = () => {
		query.page = 0;
		query.marca = "";
		query.modelo = "";
		query.base = "";
		query.pais = "";
		query.centroCosto = "";
		query.region = "";
		query.subRegion = "";
		query.dominio = "";
		query.unidad = "";
		query.estado = "";
		query.estadoVehiculo = true;
		query.movilDetail = "";
		query.lugarCarga = "";
		query.fechaHoraCargaSort = "";
		query.control = "";
		query.origen = "";
		query.filtroPeriodoInicio = moment().startOf('year').format('YYYY-MM-DD');
		query.filtroPeriodoFin = moment().format('YYYY-MM-DD');

		query.dominioSort = "";
		query.unidadSort = "";
		query.litrosSort = "";
		query.importeSort = "";
		query.precioLitroSort = "";
		query.utilizacionSort = "";
		query.direccionInformadaSort = "";      //{ id: 20, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_treinta_dias.periodoSelect', defaultMessage: 'Últimos 30 días' }) }
		query.litros100Sort = "";
		query.kmsLitroSort = "";
		query.costoKmSort = "";
		query.tarjetaSaldo = "";
		query.conductorInformadoSort = "";
		query.baseSort = "";
		query.origenSort = "";
		setPeriodoSelect(periodoInicial);
		periodo = periodoInicial;


		setFiltroPeriodoInicio(moment().startOf('year').format('YYYY-MM-DD'));
		setFiltroPeriodoFin(moment().format('YYYY-MM-DD'));
		setMarca(null);
		setModelo(null);
		setBase(null);
		setPais(null);
		setCentroCosto(null);
		setRegion(null);
		setSubRegion(null);
		setDominio("");
		setUnidad("");
		setEstado("");
		setEstadoVehiculo(true);
		setSwitchEstadoVehiculo(false);
		setMovilDetail("");
		setLugarCarga("");
		setControl(null);
		setOrigen("");
		setFiltersActive(false);
		setCheckedFilter(false);
		setQuery(JSON.parse(JSON.stringify(query)))
	};

	const requestSort = (key) => {
		let aux = query[key];
		
		query["fechaHoraCargaSort"] = "";
		query["dominioSort"] = "";
		query["unidadSort"] = "";
		query["litrosSort"] = "";
		query["importeSort"] = "";
		query["precioLitroSort"] = "";
		query["direccionInformadaSort"] = ""; 
		query["utilizacionSort"] = "";
		query["litros100Sort"] = "";
		query["kmsLitroSort"] = "";
		query["costoKmSort"] = "";
		query["tarjetaSaldo"] = "";
		query["conductorInformadoSort"] = "";
		query["baseSort"] = "";
		query["origenSort"] = "";
		
		pagination["page"] = 0;
		
		if (!aux) query[key] = "asc";
		else if (aux === "desc") query[key] = "asc";
		else query[key] = "desc";
		setPagination(pagination);
		setQuery(JSON.parse(JSON.stringify(query)));
	};

	const reloadTable = ()=>{
		setChangeQuery(!changeQuery);
	}

	const handleChecked = combustibleId => e => {
	  let selected = [...selectedCombustibles]
	  
	  if (combustibleId == 'all') {
			selected = combustibles.map(ticket => ticket.id)
			setAllCheked(true)
	  
	  } else if (combustibleId == 'none') {
			selected = []
			setAllCheked(false)
	  
	  } else {
			let idx = selected.indexOf(combustibleId)
			
			if(selected.includes(combustibleId)) selected.splice(idx, 1)
			else selected.push(combustibleId)
			setAllCheked(true)
	  }
	  setSelectedCombustibles(selected);
	}

	const checkCombustibleAction = () => {
		swal({
			title: props.intl.formatMessage({ id: '¿Desea_recalcular_los_controles_de_las_cargas_seleccionadas?', defaultMessage: '¿Desea recalcular los controles de las cargas seleccionadas?' }),
			text: props.intl.formatMessage({ id: 'Esta_acción_puede_demorar_varios_minutos.', defaultMessage: 'Esta acción puede demorar varios minutos.' }),
			buttons: {
				cancel: {
					text: props.intl.formatMessage({ id: 'Cancelar', defaultMessage: 'Cancelar' }),
					value: null,
					visible: true,
					className: "",
					closeModal: true,
				},
				confirm: {
					text: props.intl.formatMessage({ id: 'Aceptar', defaultMessage: 'Aceptar' }),
					value: true,
					visible: true,
					className: "",
					closeModal: false
				}
			},
		})
		.then((isConfirm) => {
			if(isConfirm){
				if (!selectedCombustibles) throw null;
				return Utils.sendData('/combustibles/recalcularControlesMasiva', 'POST', { combustibles: selectedCombustibles })
				.then(() => {
					reloadTable();
					setSelectedCombustibles([]);
					setAllCheked(false);
					swal(intl.formatMessage({ id: 'Se_reejecutaron_los_controles_correctamente', defaultMessage: 'Se reejecutaron los controles correctamente' }), '', 'success');
				}).catch((err) => {
					console.log(err);
					swal(intl.formatMessage({ id: 'Error', defaultMessage: 'Error' }), err.response.data, 'error')
				})
			}
		})
		.catch(err => {
			if (err) {
				swal("Oh noes!", "The AJAX request failed!", "error");
			} else {
				swal.stopLoading();
				swal.close();
			}
		});
	}

	const handleSubmit = (event) => {
		setLoading(true);
		pagination.page = 0;
		setPagination(pagination);
		updateQuery();
		setFiltersActive(true)
		return;
	}

	const handleSubmitInto = (event) => {
		if(!props.loading && !validatorSearch){
			handleSubmit(event);
		}
		event.preventDefault();
	}

	return (
		<React.Fragment>
			{redirectTo && <Redirect push to={redirectTo} />}
			{loading && <Loading />}
			<section id="minimal-gradient-statistics-bg" className="combustiblesWidget">
				<div className="row">
					{estadisticas.map((widget, index) => {
						return (
							<div key={index} className="col-xl-3 col-lg-6 col-12">
								<WidgetSparkline id={index} text={textos[widget.text]} value={widget.value} description={widget.description} iconClass={widget.iconClass} mainColorClass={widget.mainColorClass} hasProgressBar={widget.hasProgressBar} progressBarValue={widget.progressBarValue} datos={widget.datos} entidad="combustibles" />
							</div>
						);
					})}
				</div>
			</section>
			<div className="row">
				<div className="col-12">
					<div className="card bg-gradient-x-danger combustibles-reporte-invalidas">
						<div className="card-content">
							<div className="row">
								{invalidas.map((widget, index) => {
									if (invalidas.length - 1 == index) {//quito borde derecho del último card
										return (
											<div key={index} className="col-lg-3 col-md-6 col-sm-12" onClick={handleInvalidas.bind(this, widget.link)}>
												<div className="card-body card-body-comb-invalidas text-center">
													<h1 className="text-white" style={{ fontSize: '3rem' }}> {widget.value}</h1>
													<span className="text-white"><FormattedMessage {...(backendStrings[widget.description] ? backendStrings[widget.description] : backendStrings['errors.default'])}/></span>
												</div>
											</div>
										);
									}
									else {
										return (
											<div key={index} className={"col-lg-3 col-md-6 col-sm-12 border-right-danger border-right-lighten-3 "+ (index == 1 ? "combustibles-reporte-invalidas-centro" : '')} onClick={handleInvalidas.bind(this, widget.link)}>
												<div className="card-body card-body-comb-invalidas 4 text-center">
													<h1 className="text-white" style={{ fontSize: '3rem' }}> {widget.value}</h1>
													<span className="text-white"><FormattedMessage {...(backendStrings[widget.description] ? backendStrings[widget.description] : backendStrings['errors.default'])}/></span>
												</div>
											</div>
										);
									}
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row buttons-grid dt-icons">
										<div className="col-10 d-flex">
											{Security.renderIfHasPermission("COMBUSTIBLES_CREAR",
											<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={handleNew.bind(this)}>
												<i className="ft-plus"></i>
											</div>)}
											<div
													style={{ maxHeight: "52px" }}
													className="btn btn-grey-blue btn-round box-shadow-2 btn-dt-main round-icon"
													onClick={handleChange}
													data-toggle="tooltip"
													data-placement="right"
													title={props.intl.formatMessage({
													id: "Filtros",
													defaultMessage: "Filtros",
													})}
												>
													{checkedFilter ? <UpIcon /> : <ExpandMoreIcon />}
											</div>
											{filtersActive ? (
												<div className="btn-dt-main round-icon">
													<div className="text-center combustibles-resultados-texto"
															style={{
															height: "25px",
															borderRadius: "3px",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															paddingLeft: "8px",
															backgroundColor: "#5AAC43",
															color: "white",
															}}
														>
														<div className="text-center d-flex" style={{ width: "100%", fontSize: "12px", fontWeight: "500" }}>
															{combustibles.length + ' ' + props.intl.formatMessage({ id: 'combustiblesGrid.filters.apply.separator', defaultMessage: 'de' }) + ' ' + pagination.count }<div className="combustibles-resultados">&nbsp; {props.intl.formatMessage({ id: 'combustiblesGrid.filters.apply.title', defaultMessage: 'resultados de búsqueda' })}</div>
														</div>

														<div className="resultados-busqueda-notif">
															<i className="las la-times la-xs cursor-pointer" onClick={handleFilterReset}></i>
														</div>
													</div>
												</div>
												) : null}
										</div>
										<div className="col-2">
										
											<div className="dt-buttons btn-group">
												{(ConfigBusiness.get('frontend.restringirExports') === "false") ||
													(ConfigBusiness.get('frontend.restringirExports') === "true" && Security.hasPermission('COMBUSTIBLES_EXPORTAR')) ?
													<button data-toggle="modal" data-target="#export_modal" title="Exportar Excel" className="btn btn-secondary buttons-collection" tabIndex="0" aria-controls="dataTable" type="button" aria-haspopup="true">
														<span>
															<i className="ft-upload"></i>
														</span>
													</button>
												: null}
												<button data-toggle="modal" data-target="#import_modal" title="Importar Excel" className="btn btn-secondary buttons-collection" tabIndex="0" aria-controls="dataTable" type="button" aria-haspopup="true">
													<span>
														<i className="ft-download"></i>
													</span>
												</button>
											</div>
										</div>
									</div>
									<Collapse in={checkedFilter}>
										<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
													{/*PERIODO */}
													 <Grid component={Box} item xs={3}>
													 	<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Select
																id="periodo"
																name="periodo"
																clearable={false}
																options={periodos}
																optionComponent={GravatarOption}
																valueKey="id"
																labelKey="label"
																className="select-periodo-filter"
																value={periodoSelect ? periodoSelect : null}
																onChange={(e) => handleSelectFormChange("periodo", e)}
															/>
														</form>
													</Grid>

													{/*BASE */}
													{(columnBase) && (
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																multiple
																size="small"
																name="base"
																loading={false}
																options={bases}
																value={base ? base : []}
																defaultValue={[]}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("base", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_base.label", defaultMessage: "Base"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_base.placeholder", defaultMessage: "Base"})}                                                                
																/>
																
																)}
															/>
														</form>
													</Grid>
													)}

													{/*PAIS */}
													{(paisEnabled) && (
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																multiple
																size="small"
																name="pais"
																loading={false}
																options={paises}
																value={pais ? pais : []}
																defaultValue={[]}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("pais", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_pais.label", defaultMessage: "Pais"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_pais.placeholder", defaultMessage: "Pais"})}                                                                
																/>
																
																)}
															/>
														</form>
													</Grid>
													)}

													{/*CENTRO DE COSTOS */}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																multiple
																size="small"
																name="centroCosto"
																loading={false}
																options={centroCostos}
																value={centroCosto ? centroCosto : []}
																defaultValue={[]}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("centroCosto", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_centro_costos.label", defaultMessage: "Centro de Costos"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_centro_costos.placeholder", defaultMessage: "Centro de Costos"})}
																/>
																
																)}
															/>
														</form>
													</Grid>

													{/*REGIÓN */}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																multiple
																size="small"
																name="region"
																loading={false}
																options={regiones}
																value={region ? region : []}
																defaultValue={[]}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("region", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_region.label", defaultMessage: "Región"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_region.placeholder", defaultMessage: "Región"})}
																/>
																)}
															/>
														</form>
													</Grid>

													{/*SUB-REGIÓN*/}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																multiple
																size="small"
																name="subRegion"
																loading={false}
																options={subRegiones}
																value={subRegion ? subRegion : []}
																defaultValue={[]}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("subRegion", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_subregion.label", defaultMessage: "Sub-Región"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_subregion.placeholder", defaultMessage: "Sub-Región"})}
																/>
																)}
															/>
														</form>
													</Grid>
													
													{/*DOMINIO */}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Input
																id="standard-basic"
																label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_dominio.label", defaultMessage: "Dominio"})} 
																placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_dominio.placeholder", defaultMessage: "Dominio"})}
																name="dominio"
																value={dominio}
																onChange={(e) => setDominio(e.target.value)}
																onBlur={(e) => handleInputFormChange("dominio", e)}
																className="col-12"
															/>
														</form>
													</Grid>
												
												  {/*MARCA */}
												  <Grid component={Box} item xs={3}>
												  		<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																size="small"
																name="marca"
																loading={false}
																options={marcas}
																value={marca ? marca : ""}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("marca", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_marca.label", defaultMessage: "Marca"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_marca.placeholder", defaultMessage: "Marca"})}
																/>
																)}
															/>
														</form>
													</Grid>

													{/*MODELO */}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																size="small"
																name="modelo"
																options={modelos}
																value={modelo ? modelo : ""}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("modelo", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_modelos.label", defaultMessage: "Modelos"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_modelos.placeholder", defaultMessage: "Modelos"})}
																/>
																)}
															/>
														</form>
													</Grid>

													{/*ESTADO */}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																size="small"
																name="estado"
																options={estados}
																value={estado ? estado : ""}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("estado", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_estado.label", defaultMessage: "Estado"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_estado.placeholder", defaultMessage: "Estado"})} />
																)}
															/>
														</form>
													</Grid>

												  {/*LUGAR DE CARGA */}
												  <Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Input
																id="lugar_carga"
																label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_lugar_de_carga.label", defaultMessage: "Lugar de Carga"})} 
																placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_lugar_de_carga.placeholder", defaultMessage: "Lugar de Carga"})}
																name="lugarCarga"
																value={lugarCarga}
																onChange={(e) => handleInputFormChange("lugarCarga", e)}
																className="col-12"
															/>
														</form>
												  </Grid>

												  

													{/*CONTROLES */}
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																multiple
																size="small"
																name="controles"
																loading={false}
																options={controles}
																value={control ? control : []}
																defaultValue={[]}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("control", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_controles.label", defaultMessage: "Controles"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_controles.placeholder", defaultMessage: "Controles"})}
																/>
																)}
															/>
														</form>
													</Grid>

													{/*UNIDAD */}
													{(columnUnidad) &&
														<Grid component={Box} item xs={3}>
															<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
																<Input
																	id="standard-basic"
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_unidad.label", defaultMessage: "Unidad"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_unidad.placeholder", defaultMessage: "Unidad"})}
																	name="unidad"
																	value={unidad}
																	onChange={(e) => setUnidad(e.target.value)}
																	onBlur={(e) => handleInputFormChange("unidad", e)}
																	className="col-12"
																/>
															</form>
														</Grid>
													}

												{/* ORIGEN */}
												{(columnOrigen) && 
													<Grid component={Box} item xs={3}>
														<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmitInto}>
															<Autocomplete
																size="small"
																name="origen"
																loading={false}
																options={origenes}
																value={origen ? origen : ''}
																getOptionLabel={(option) => option ? option.label : ""}
																onChange={(event, value) => handleSelectFormChange("origen", value)}
																renderInput={(params) => (
																<TextField {...params} variant="standard" 
																	label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_origen.label", defaultMessage: "Origen"})} 
																	placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_origen.placeholder", defaultMessage: "Origen"})}
																/>
																)}
															/>
														</form>
													</Grid>
												}

												<Grid
													xs={12}
													container
													justifyContent="space-between"
													alignItems="center"
													flexDirection={{ xs: 'column', sm: 'row' }}
													sx={{ fontSize: '12px' }}
												>
													<Grid component={Box} item xs={2} className="align-self-end">
														<div className="d-flex inline justify-content-around align-items-center">
															<FormattedMessage id="formulariosGridFilters.render.filtros.ver_inactivos" defaultMessage="Ver Inactivos" />
															<Switch
																onChange={(value) => handleEstadoVehiculo(value)}
																checked={switchEstadoVehiculo}
																disabled={loading}
																offColor="#FF4961"
																onColor="#28D094"
															/>
														</div>
													</Grid>
													<Grid component={Box} item xs={1} className="align-self-end"
													>
														<button
															disabled={loading || validatorSearch}
															className="btn btn-primary mr-1"
															onClick={(e) => handleSubmit(e)}
														>
															<i className="fa fa-search fa-xs"></i>   <FormattedMessage id="Buscar" defaultMessage="Buscar"/>
														</button>
													</Grid>
												</Grid>
												


										</Grid>
										</Collapse>
								</div>
								<TableContainer component={Paper}>
									<Table  aria-label="collapsible table" classes={{root: classes.rootTable}} width="100%">
									{(Security.hasPermission('COMBUSTIBLES_RECALCULAR') && combustibles.length > 0 && selectedCombustibles.length > 0 ) ? (
										<TableHead>
											<TableRow>
												<StyledTableCell show={Security.hasPermission('COMBUSTIBLES_RECALCULAR') && selectedCombustibles.length > 0 } colSpan={18}>
													<input type="checkbox" checked={allChecked} onChange={handleChecked('none')} style={{marginRight: "15px"}} className="customCheckbox"/>
													<span style={{marginRight: "10px"}}>{selectedCombustibles.length}</span>
													<FormattedMessage id="Seleccionados" defaultMessage="Seleccionados"/>
														| 
								
													<StyledTooltip 
														title={intl.formatMessage({
															id: 'Reejecutar_Controles',
															defaultMessage: 'Reejecutar Controles',
														})}
														arrow
														placement="top"
													>
														<div style={{ marginLeft: "10px", position: 'relative', display: 'inline-block' }}>
															<span 
																type="button"
																onClick={checkCombustibleAction}
																className="fa-stack fa-lg cursor-pointer"
															>
																<i className="la la-redo-alt" style={{fontSize:"36px"}}></i>
															</span>
														</div>
													</StyledTooltip>
												</StyledTableCell>
											</TableRow>
										</TableHead>
										) : (
											<TableHead >
												<TableRow>
													<StyledTableCell show={Security.hasPermission('COMBUSTIBLES_RECALCULAR') && selectedCombustibles.length == 0 } style={{ maxWidth: "22px" }}>
														<StyledTooltip
															title={intl.formatMessage({
																id: 'Seleccionar_Todos',
																defaultMessage: 'Seleccionar Todos',
															})}
															arrow
															placement="top"
														>
															<input type="checkbox" checked={allChecked} onChange={handleChecked('all')} style={{marginRight: "15px"}} className="customCheckbox"/>
														</StyledTooltip>
													</StyledTableCell>
													<StyledTableCell classes={{root: classes.head}}>
														<div onClick={()=>requestSort("dominioSort")}>
																{props.intl.formatMessage({ id: 'combustiblesGrid.column.dominio.title', defaultMessage: 'Dominio' })}
																<img className="ml-1"
																	src={query.dominioSort === "" ? Sort : query.dominioSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>
													</StyledTableCell>
													{(columnUnidad) &&
														<StyledTableCell classes={{root: classes.head}} align="center">
															<div onClick={()=>requestSort("unidadSort")}>
															{props.intl.formatMessage({ id: 'combustiblesGrid.column.movil_unidad.title', defaultMessage: 'Unidad' })}
																<img className="ml-1"
																		src={query.unidadSort === "" ? Sort : query.unidadSort === "asc" ? SortAsc : SortDesc}
																		alt={"/"}
																		style={{ height: "12px", width: "22px" }}
																	/>
															</div>
														</StyledTableCell>
													}
													<StyledTableCell classes={{root: classes.head}} align="center" style={{ maxWidth: "3px" }}><div></div></StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center"><div>{props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.title', defaultMessage: 'Controles' })}</div></StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("fechaHoraCargaSort")}>
														{props.intl.formatMessage({ id: 'combustiblesGrid.column.fecha_hora.title', defaultMessage: 'Fecha y Hora' })}
															<img className="ml-1"
																	src={query.fechaHoraCargaSort === "" ? Sort : query.fechaHoraCargaSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>
													</StyledTableCell>
													{(columnTarjetaSaldo) &&
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("tarjetaSaldo")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.tarjeta_saldo.title', defaultMessage: 'Saldo Tarjeta' })}
															<img className="ml-1"
																	src={query.tarjetaSaldo === "" ? Sort : query.tarjetaSaldo === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div></StyledTableCell>
													}
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("utilizacionSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.utilizacion.title', defaultMessage: 'Utilización' })}
															<img className="ml-1"
																	src={query.utilizacionSort === "" ? Sort : query.utilizacionSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div></StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("litrosSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.litros.title', defaultMessage: 'Litros' })}
															<img className="ml-1"
																	src={query.litrosSort === "" ? Sort : query.litrosSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div></StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("importeSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.total.title', defaultMessage: 'Total' })}
															<img className="ml-1"
																	src={query.importeSort === "" ? Sort : query.importeSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div></StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("precioLitroSort")}>
															{props.intl.formatMessage({ id: 'combustiblesGrid.column.precio_por_litro.title', defaultMessage: 'Precio por Litro' })}
															<img className="ml-1"
																	src={query.precioLitroSort === "" ? Sort : query.precioLitroSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div></StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("direccionInformadaSort")}>
															{props.intl.formatMessage({ id: 'combustiblesGrid.column.lugar_carga', defaultMessage: 'Lugar de Carga' })}
															<img className="ml-1"
																	src={query.direccionInformadaSort === "" ? Sort : query.direccionInformadaSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div></StyledTableCell>
													{columnOrigen && 
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("origenSort")}>
															{props.intl.formatMessage({ id: 'combustiblesGrid.column.origen', defaultMessage: 'Origen' })}
															<img className="ml-1"
																	src={query.origenSort === "" ? Sort : query.origenSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>
													</StyledTableCell>
													}
													<StyledTableCell classes={{root: classes.head}} align="center">
														{ litrosCada100km === 'true' ? 
														<div onClick={()=>requestSort("litros100Sort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.LT_100Km', defaultMessage: 'LT/100Km' })}
															<img className="ml-1"
																	src={query.litros100Sort === "" ? Sort : query.litros100Sort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>
														:
														<div onClick={()=>requestSort("kmsLitroSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.kms_litro', defaultMessage: 'Kms/LT' })}
															<img className="ml-1"
																	src={query.kmsLitroSort === "" ? Sort : query.kmsLitroSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>}
													</StyledTableCell>
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("costoKmSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.costo_Km', defaultMessage: 'Costo/Km' })}
															<img className="ml-1"
																	src={query.costoKmSort === "" ? Sort : query.costoKmSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>
													</StyledTableCell>
													{columnBase &&
													<StyledTableCell classes={{root: classes.head}} align="center">
														<div onClick={()=>requestSort("baseSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.base', defaultMessage: 'Base' })}
															<img className="ml-1"
																	src={query.baseSort === "" ? Sort : query.baseSort === "asc" ? SortAsc : SortDesc}
																	alt={"/"}
																	style={{ height: "12px", width: "22px" }}
																/>
														</div>
													</StyledTableCell>
													}
													{(columnConductorInformado) &&
														<StyledTableCell classes={{root: classes.head}} align="center">
															<div onClick={()=>requestSort("conductorInformadoSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.conductor_informado', defaultMessage: 'Conductor' })}
																<img className="ml-1"
																		src={query.conductorInformadoSort === "" ? Sort : query.conductorInformadoSort === "asc" ? SortAsc : SortDesc}
																		alt={"/"}
																		style={{ height: "12px", width: "22px" }}
																	/>
															</div>
														</StyledTableCell>
													}
													<StyledTableCell classes={{root: classes.head}} align="center"></StyledTableCell>
												</TableRow>
											</TableHead>
										)}
										<TableBody>
											{combustibles.map((comb) => (
												<Row
													//redirectToCorrectivo={redirectToCorrectivo}
													combustible={comb}
													key={comb.id}
													dataTableUpdate={dataTableUpdate}
													intl={props.intl}
													changeTable={reloadTable}
													motivosValidez={motivosValidez}
													ajaxHandler={props.ajaxHandler}
													setRedirectTo={setRedirectTo}
													litrosCada100km={litrosCada100km}
													tarjetaSaldo={columnTarjetaSaldo}
													columnConductorInformado={columnConductorInformado}
													columnUnidad={columnUnidad}
													columnBase={columnBase}
													columnOrigen={columnOrigen}

													generacionMasiva={Security.hasPermission('COMBUSTIBLES_RECALCULAR')}
													selectedCombustibles={selectedCombustibles}
													setSelectedCombustibles={setSelectedCombustibles}
													handleChecked={handleChecked}
												/>
											))}
										</TableBody>
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[10, 25, 50, 100]}
									component="div"
									count={pagination.count }
									rowsPerPage={pagination.perPage}
									page={pagination.page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
									labelRowsPerPage="Mostrar"
									labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
								/>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="combustiblesGrid.tools.export.modal_title.exportar_combustibles" defaultMessage=" Exportar Combustibles" /></h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<div className="row">
								<div className="col-md-12">
									{/*<div className="alert alert-danger" role="alert" hidden={errors.length===0}>
								{errors.map((e, i) => <li key={i}>{e}</li>)}
							</div>*/}
									<form className="form form-horizontal">
										<div className="form-body">
											<div className="card pull-up">
												<div className="card-content">
													<div className="card-body">
														<h4 className="form-section">
															<i className="la la-columns"></i><FormattedMessage id="combustiblesGrid.tools.export.modal_content.columnas_a_exportar" defaultMessage=" Columnas a exportar" />
														</h4>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="fecha_hora_carga">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.fecha_hora" defaultMessage="Fecha y Hora:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "fechaHoraCarga")}
																	checked={dataToExport.fechaHoraCarga ? true : false}
																	value={dataToExport.fechaHoraCarga}
																	id="fecha_hora_carga"
																	name="fecha_hora_carga"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="dominio">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.dominio" defaultMessage="Dominio:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "movil")}
																	checked={dataToExport.movil ? true : false}
																	value={dataToExport.movil}
																	id="dominio"
																	name="dominio"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="utilizacion">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.utilizacion" defaultMessage="Utilizacion:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "utilizacion")}
																	checked={dataToExport.utilizacion ? true : false}
																	value={dataToExport.utilizacion}
																	id="utilizacion"
																	name="utilizacion"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="litros">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.litros" defaultMessage="Litros:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "litros")}
																	checked={dataToExport.litros ? true : false}
																	value={dataToExport.litros}
																	id="litros"
																	name="litros"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="importe">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.total" defaultMessage="Total:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "importe")}
																	checked={dataToExport.importe ? true : false}
																	value={dataToExport.importe}
																	id="importe"
																	name="importe"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="precio_por_litro">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.precio_por_litro" defaultMessage="Precio por Litro:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "precioLitro")}
																	checked={dataToExport.precioLitro ? true : false}
																	value={dataToExport.precioLitro}
																	id="precio_por_litro"
																	name="precio_por_litro"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="direccion_carga">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.lugar_de_carga" defaultMessage="Lugar de Carga" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "direccionInformada")}
																	checked={dataToExport.direccionInformada ? true : false}
																	value={dataToExport.direccionInformada}
																	id="direccion_carga"
																	name="direccion_carga"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="km_litro">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.km-Litro" defaultMessage="Km/Litro:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "kmLitros")}
																	checked={dataToExport.kmLitros ? true : false}
																	value={dataToExport.kmLitros}
																	id="km_litro"
																	name="km_litro"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="costo_km">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.costo-Km" defaultMessage="Costo/Km:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "costoKm")}
																	checked={dataToExport.costoKm ? true : false}
																	value={dataToExport.costoKm}
																	id="costo_km"
																	name="costo_km"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="estado">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.estado" defaultMessage="estado:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "estado")}
																	checked={dataToExport.estado ? true : false}
																	value={dataToExport.estado}
																	id="estado"
																	name="estado"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															{columnBase &&
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="base">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.base" defaultMessage="Base:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "base")}
																	checked={dataToExport.base ? true : false}
																	value={dataToExport.base}
																	id="base"
																	name="base"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															}
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.centroCostosNombre" defaultMessage="Centro de Costos:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "centroCostosNombre")}
																	checked={dataToExport.centroCostosNombre ? true : false}
																	value={dataToExport.centroCostosNombre}
																	id="centroCostosNombre"
																	name="centroCostosNombre"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="responsable1NombreApellido">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.responsable1NombreApellido" defaultMessage="Responsable 1:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "responsable1NombreApellido")}
																	checked={dataToExport.responsable1NombreApellido ? true : false}
																	value={dataToExport.responsable1NombreApellido}
																	id="responsable1NombreApellido"
																	name="responsable1NombreApellido"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="responsable2NombreApellido">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.responsable2NombreApellido" defaultMessage="Responsable 2:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "responsable2NombreApellido")}
																	checked={dataToExport.responsable2NombreApellido ? true : false}
																	value={dataToExport.responsable2NombreApellido}
																	id="responsable2NombreApellido"
																	name="responsable2NombreApellido"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="conductor">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.conductor" defaultMessage="Conductor:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "conductor")}
																	checked={dataToExport.conductor ? true : false}
																	value={dataToExport.conductor}
																	id="conductor"
																	name="conductor"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="region">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.region" defaultMessage="Región:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "region")}
																	checked={dataToExport.region ? true : false}
																	value={dataToExport.region}
																	id="region"
																	name="region"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.sub_region" defaultMessage="Sub-Región:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "subRegion")}
																	checked={dataToExport.subRegion ? true : false}
																	value={dataToExport.subRegion}
																	id="subRegion"
																	name="subRegion"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="combustibleTipos">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.tipo_combustible" defaultMessage="Tipo Combustible:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "combustibleTipos")}
																	checked={dataToExport.combustibleTipos ? true : false}
																	value={dataToExport.combustibleTipos}
																	id="combustibleTipos"
																	name="combustibleTipos"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="odometro">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.odometro_carga" defaultMessage="Odómetro Carga:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "odometro")}
																	checked={dataToExport.odometro ? true : false}
																	value={dataToExport.odometro}
																	id="odometro"
																	name="odometro"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="rendimiento">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.target_rendimiento" defaultMessage="Target Rendimiento:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "rendimiento")}
																	checked={dataToExport.rendimiento ? true : false}
																	value={dataToExport.rendimiento}
																	id="rendimiento"
																	name="rendimiento"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
														  {columnTarjetaSaldo ? (
															  <div className=" col-md-3">
																  <label className="col-md-6 label-control col-form-label" htmlFor="tarjetaSaldo">
																	  <FormattedMessage id="combustiblesGrid.tools.export.modal_content.tarjetaSaldo" defaultMessage="Saldo Tarjeta:" />
																  </label>
																  <Switch
																	  onChange={(event) => handleDataExport(event, "tarjetaSaldo")}
																	  checked={dataToExport.tarjetaSaldo ? true : false}
																	  value={dataToExport.tarjetaSaldo}
																	  id="tarjetaSaldo"
																	  name="tarjetaSaldo"
																	  offColor="#FF4961"
																	  onColor="#28D094"
																	  disabled={false}
																	  className="col-md-6 align-middle"
																  />
															  </div>
														  ): dataToExport.tarjetaSaldo = false}
														  {columnConductorInformado && (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="tarjetaSaldo">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.conductorInformado" defaultMessage="Conductor Informado:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "conductorInformado")}
																	checked={dataToExport.conductorInformado ? true : false}
																	value={dataToExport.conductorInformado}
																	id="conductorInformado"
																	name="conductorInformado"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														  )}
														  {columnUnidad && (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="unidad">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.unidad" defaultMessage="Unidad:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "unidad")}
																	checked={dataToExport.unidad ? true : false}
																	value={dataToExport.unidad}
																	id="unidad"
																	name="unidad"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														  )}
														  {columnOrigen && (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="origen">
																	<FormattedMessage id="combustiblesGrid.tools.export.modal_content.origen" defaultMessage="Origen:" />
																</label>
																<Switch
																	onChange={(event) => handleDataExport(event, "origen")}
																	checked={dataToExport.origen ? true : false}
																	value={dataToExport.origen}
																	id="origen"
																	name="origen"
																	offColor="#FF4961"
																	onColor="#28D094"
																	disabled={false}
																	className="col-md-6 align-middle"
																/>
															</div>
														  )}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-12 alert alert-info small" role="alert">
											<FormattedMessage id="combustiblesGrid.tools.export.modal_content.esta_accion_puede_demorar" defaultMessage="Esta acción puede demorar unos minutos." />
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="modal-footer modal-grey">
							<button type="button" className="btn btn-default btn-fleet" onClick={handleExportExcel} ><i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}></i> Exportar a Excel</button>
							<button type="button" className="btn btn-danger" onClick={handleExportCancel} ><i className="fa fa-times-circle"></i> Cancelar</button>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade col-8" tabIndex="-1" id="import_modal" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel1">{/*<i className="la la-history align-middle icon-modal-title"></i>*/}
								<FormattedMessage id="combustiblesGrid.tools.import.button.importar" defaultMessage=" Importar" />
							</h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<form className="form">
								{<CombustiblesImportar callbackClose={handleCloseModal} callbackCancel={handleCancelModal} setTypeImport={setTypeImport}/>}
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade col-8" tabIndex="-1" id="import_report_modal" role="dialog" aria-labelledby="importReportLabel" style={{ margin: "auto" }}>
				<div className="modal-lg modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="importReportLabel">Reporte de la Importación</h4>
							<button type="button" className="close" onClick={handleReportCloseModal} aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							{reporteImportacion ?
								<CombustiblesImportarReporte typeImport={typeImport} data={reporteImportacion} callbackReportClose={handleReportCloseModal}></CombustiblesImportarReporte>
								: null}
						</div>
						<div className="modal-footer modal-grey">
							<button type="button" className="btn btn-default btn-fleet" onClick={handleReportCloseModal}>Ok</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);

}

export default injectIntl(CombustiblesGridNew);
