import React, { useState, useEffect } from "react";
import { useIntl } from 'react-intl'
import {
	Collapse,
	TextField,
	Grid,
	Box,
	Select,
	MenuItem,
} from '@material-ui/core';
import Switch from "react-switch";
import * as Utils from '../../commons/utils/Utils.js';

const FILTER_STATE_INIT = {
	nombre: '',
	categoria: "",
	showInactivos: false
}

const handleInitFilters = () => {
	const filterInit = FILTER_STATE_INIT;
	return filterInit;
}

const InsumosMarcasGridFiltros = (props) => {
	const intl = useIntl();
	const [init, setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState(handleInitFilters(props))
	const [comboCategoria, setComboCategoria] = useState([]);

	useEffect(() => {
		Utils.getData('/panol2/categorias/select')
			.then(categorias => {
				setComboCategoria(categorias || []);
			})
			.catch(err => {
				console.log(err);
				setComboCategoria([]);
			})
	}, []);

	useEffect(() => {
		if (props.filterResetInputs) {
			setFiltersState(FILTER_STATE_INIT);
		}
	}, [props.filterResetInputs])

	useEffect(() => {
		!props.dropdownActive && props.setFilterResetInputs(false)
		!props.dropdownActive && setFiltersLoading(false)
	}, [props.dropdownActive]);

	const handleChange = (name, value) => {
		props.handleFilterChange();
		setFiltersLoading(true);
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));

		if (query['nombre'] !== '') {
			props.setSearchActive(true);
		} else {
			props.setSearchActive(false);
		}

		query[name] = value

		setInit(true)

		props.setFilterQuery(query);
		setFiltersLoading(false)
		return;
	};

	const handleCategoriaChange = (e) => {
		props.handleFilterChange();
		setFiltersLoading(true);
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));

		if (e.target.value === "") {
			query['categoria'] = e.target.value;
		} else {
			query['categoria'] = e.target.value.id;
		}

		setInit(true)

		props.setFilterQuery(query);
		setFiltersLoading(false)
		return;
	};

	useEffect(() => {
		handleChange('showInactivos', filtersState.showInactivos);
	}, [filtersState.showInactivos])

	const handleOnEnter = (e, name, value) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			handleChange(name, value);
		}
	};

	const handleChangeInactivosSwitch = (value) => {
		setFiltersState({...filtersState, showInactivos : value});
		handleChange('showInactivos', value);
	  };

	return (
		<>
			{init &&
				<Collapse in={props.dropdownActive}>
					<Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({ id: "Marca", defaultMessage: "Marca" })}
									placeholder={intl.formatMessage({ id: "Marca", defaultMessage: "Marca" })}
									name="nombre"
									value={filtersState.nombre}
									onChange={(e) => setFiltersState({...filtersState, nombre: e.target.value})}
									onKeyDown={(e) => handleOnEnter(e, 'nombre', filtersState.nombre)}
									onBlur={(e) => handleChange("nombre", filtersState.nombre)}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
						{props.showCategoria ?
						<Grid component={Box} item xs={3} sx={{alignSelf: 'flex-end','& .Mui-focused .MuiSelect-select': {
      backgroundColor: 'transparent !important'
    }}}>
							<form noValidate autoComplete="off">
									<Select
										className="col-12"
										placeholder='Categoria'
										id="categoria"
										name="categoria"
										displayEmpty
										value={comboCategoria.find(c => c.id === filtersState.categoria) && ""}
										onChange={handleCategoriaChange}
										disabled={filtersLoading}
										renderValue={(selected) => {
											if (!selected) {
											  return "Seleccione una categoría";
											}
											return selected.nombre;
										  }}
										

									>
										<MenuItem key='ninguna' value="">
										  Ninguna
										</MenuItem>
										{comboCategoria.map(c => {
											return (<MenuItem key={c.nombre} value={c}>
														{c.nombre}
													</MenuItem>)
										})
										}
									</Select>
							</form>
						</Grid>
						: ''}
						<Grid component={Box} item xs={2} className="align-self-end">
							<form noValidate autoComplete="off">
								<div className="d-flex inline justify-content-around align-items-center">
									{intl.formatMessage({
										id: "insumoMarcaAbm.render.filtros.ver_inactivos",
										defaultMessage: "Ver Inactivas"
									})}
									<Switch
										onChange={(value) => handleChangeInactivosSwitch(value)}
										checked={filtersState.showInactivos}
										disabled={filtersLoading}
										offColor="#FF4961"
										onColor="#28D094"
									/>
								</div>
							</form>
						</Grid>
					</Grid>
				</Collapse>
			}
		</>
	)
}

export default InsumosMarcasGridFiltros
