import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  Collapse,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Select,
  NativeSelect,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StyledSelect } from '../../commons/utils/TableStyles'
import * as Constants from '../atributoDinamico/constants.js'
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";


const FILTER_STATE_INIT = {
  icono: '',
  estado: null,
  id: '',
  descripcion: '',
  fecha: '',
  entidad: '',
  usuario: '',
  fallas: '',
  base: [],
  subRegion: [],
  pais: null,
  chofer: '',
  showInactivos: false,
};

const getFilterState = () => {
  let filterStateInit = FILTER_STATE_INIT
  let file = JSON.parse(window.localStorage.getItem('FormulariosGridFilters'));

  if (file && file.activo && file.userFilters) {
    filterStateInit = file.userFilters
  }

  return filterStateInit
}

const getPersisteStoragedState = () => {
  let file = JSON.parse(window.localStorage.getItem('FormulariosGridFilters'));
  return (file && file.activo)
}

const FormulariosGridFilters = (props) => {
  const intl = useIntl();
  const [init, setInit] = useState(false)
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersState, setFiltersState] = useState(getFilterState);
  const [persisteFiltros, setPersisteFiltros] = useState(getPersisteStoragedState);
  const [bases, setBases] = useState([]);
  const [subRegiones, setSubRegiones] = useState([]);
  const [paises, setPaises] = useState([]);
  const estados = props.estados
  const columnsEnabled = props.columnsEnabled

  columnsEnabled.pais = ConfigBusiness.get("checklist.pais.habilitado") === 'true' ? true : false;


  useEffect(() => {

    // Validacion de filtros persistidos para la carga inicial
    if (init === false) {
      props.setSearchActive(true);
      setPersisteFiltros(true);
      let query = JSON.parse(JSON.stringify(filtersState));

      query['estado'] = query['estado'] ? query['estado']['value'] : ''
      query['base'] = query['base'] ? query['base'].map(e => e.value).join(',') : ''
      query['subRegion'] = query['subRegion'] ? query['subRegion'].map(e => e.value).join(',') : ''
      query['pais'] = query['pais'] ? query['pais']['value'] : ''

      //control filtro por url. Alert Checklist (Preventivo)
      if(props.filterEntidad){
        query['entidad'] = props.filterEntidad
        setFiltersState({ ...filtersState, entidad: props.filterEntidad })
      }
      
      props.setFilterQuery(query);
      setInit(true)
    }

    // Cambios "colaterales" al estado de persisteFiltros
    if (persisteFiltros) {
      props.setSearchActive(true);

    } else {
      props.setSearchActive(false);
      setFiltersState(FILTER_STATE_INIT)
      props.setDropdownActive(false)
    }

    window.localStorage.setItem('FormulariosGridFilters', JSON.stringify({ activo: persisteFiltros, userFilters: filtersState }));
  }, [persisteFiltros])

  useEffect(() => {
    props.dropdownActive && fetchData()
    !props.dropdownActive && props.setResetFilterInputs(false)
  }, [props.dropdownActive]);

  useEffect(() => {
    if (props.resetFilterInputs) {
      setFiltersState(FILTER_STATE_INIT)
      setPersisteFiltros(false)
    }
  }, [props.resetFilterInputs]);


  const fetchData = () => {
    Utils.getData('/paises/select').then((paises) => {
      setPaises(paises)

    }).catch((err) => {
      console.log(err);
    })
    Utils.getData('/bases/select-filtered-by-user')
      .then((bases) => {
        console.log(bases)
        setBases(bases)

      }).catch((err) => {
        console.log(err);
      })
      Utils.getData('/subregiones/select-all')
      .then((subregion) => {
        setSubRegiones(subregion)

      }).catch((err) => {
        console.log(err);
      })
  }

  const handleChange = (key, object) => {
    //avisar siempre al componente padre cuando el filtro cambie 
    props.onFilterChange();

    props.setSearchActive(true);
    let query = { ...props.filterQuery };
    let queryLast = { ...props.filterQueryLast };

    switch (key) {
      // Selects
      case 'estado':
      case 'base':
      console.log('base', object)
        query[key] = object ? object.map(e => e.value).join(',') : ''; 
        break;
      case 'subRegion': 
      console.log('subRegion', object)
        query[key] = object ? object.map(e => e.value).join(',') : '';
        break;
      case 'pais':
        query[key] = object ? object.value : '';
        break;
      // Inputs
      default:
        query[key] = object;
        break
    }

    window.localStorage.setItem('FormulariosGridFilters', JSON.stringify({ activo: persisteFiltros, userFilters: filtersState }));
    // Aplica filtros solo si se detectan cambios en la query
    let queryChanged = JSON.stringify(queryLast) !== JSON.stringify(query);
    queryChanged && props.setFilterQuery(query);
  };
  
  const handleChangeInactivosSwich = (value) => {
    setFiltersState({...filtersState, showInactivos : value});
    handleChange('showInactivos', value);
  };

  const handleOnEnter = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleChange(name, value);
    }
  };

  return (
    <>
      {init && (
        <Collapse in={props.dropdownActive}>
          <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
            <Grid {...(!columnsEnabled.icono && { display: 'none' })} component={Box} item xs={1}>
              <FormControl>
                <InputLabel id="input-tipo-label">
                  <FormattedMessage
                    id="formulariosGridFilters.render.filtros_tipo_formulario.label"
                    defaultMessage="Tipo"
                  />
                </InputLabel>
                <Select
                  labelId="input-tipo-label"
                  name="icono"
                  value={filtersState.icono}
                  onChange={(e, obj) =>
                    setFiltersState({ ...filtersState, icono: obj.props.value })
                  }
                  onKeyDown={(e) => handleOnEnter(e, 'icono', filtersState.icono)}
                  onBlur={(e) => handleChange('icono', filtersState.icono)}
                  disabled={filtersLoading}
                  className="col-12"
                  style={{ fontSize: '0.7em' }}
                >
                  <MenuItem value="">TODOS</MenuItem>
                  <MenuItem value="fa fa-bus">
                    <i className="fa-2x fa fa-bus"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-motorcycle">
                    <i className="fa-2x fa fa-motorcycle"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-car">
                    <i className="fa-2x fa fa-car"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-truck">
                    <i className="fa-2x fa fa-truck"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-user">
                    <i className="fa-2x fa fa-user"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-users">
                    <i className="fa-2x fa fa-users"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-wrench">
                    <i className="fa-2x fa fa-wrench"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-cog">
                    <i className="fa-2x fa fa-cog"></i>
                  </MenuItem>
                  <MenuItem value="fa fa-map-marker">
                    <i className="fa-2x fa fa-map-marker"></i>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="estado"
                  disabled={filtersLoading}
                  options={estados}
                  value={filtersState.estado}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  onChange={(e, value) => setFiltersState({ ...filtersState, estado: value })}
                  onKeyDown={(e) => handleOnEnter(e, 'estado', filtersState.estado)}
                  onBlur={(e) => handleChange('estado', filtersState.estado)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: 'formulariosGrid.render.filtros_estado.label',
                        defaultMessage: 'Estado',
                      })}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.id && { display: 'none' })} component={Box} item xs={1}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_id.label',
                    defaultMessage: 'Id',
                  })}
                  name="id"
                  value={filtersState.id}
                  onChange={(e) => setFiltersState({ ...filtersState, id: e.target.value })}
                  onKeyDown={(e) => handleOnEnter(e, 'id', filtersState.id)}
                  onBlur={(e) => handleChange('id', filtersState.id)}
                  disabled={filtersLoading}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid
              {...(!columnsEnabled.descripcion && { display: 'none' })}
              component={Box}
              item
              xs={3}
            >
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_descripcion.label',
                    defaultMessage: 'Descripcion',
                  })}
                  name="descripcion"
                  value={filtersState.descripcion}
                  onChange={(e) =>
                    setFiltersState({ ...filtersState, descripcion: e.target.value })
                  }
                  onKeyDown={(e) => handleOnEnter(e, 'descripcion', filtersState.descripcion)}
                  onBlur={(e) => handleChange('descripcion', filtersState.descripcion)}
                  disabled={filtersLoading}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.fecha && { display: 'none' })} component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_fecha.label',
                    defaultMessage: 'Fecha',
                  })}
                  name="fecha"
                  value={filtersState.fecha}
                  onChange={(e) => setFiltersState({ ...filtersState, fecha: e.target.value })}
                  onKeyDown={(e) => handleOnEnter(e, 'fecha', filtersState.fecha)}
                  onBlur={(e) => handleChange('fecha', filtersState.fecha)}
                  disabled={filtersLoading}
                  className="col-12"
                  type="date"
                  // isClearable={true}
                  format={'DD-MM-YYYY'}
                  // disableFuture
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.entidad && { display: 'none' })} component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_entidad.label',
                    defaultMessage: 'Entidad',
                  })}
                  name="entidad"
                  value={filtersState.entidad}
                  onChange={(e) => setFiltersState({ ...filtersState, entidad: e.target.value })}
                  onKeyDown={(e) => handleOnEnter(e, 'entidad', filtersState.entidad)}
                  onBlur={(e) => handleChange('entidad', filtersState.entidad)}
                  disabled={filtersLoading}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.usuario && { display: 'none' })} component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_usuario.label',
                    defaultMessage: 'Usuario',
                  })}
                  name="usuario"
                  value={filtersState.usuario}
                  onChange={(e) => setFiltersState({ ...filtersState, usuario: e.target.value })}
                  onKeyDown={(e) => handleOnEnter(e, 'usuario', filtersState.usuario)}
                  onBlur={(e) => handleChange('usuario', filtersState.usuario)}
                  disabled={filtersLoading}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.fallas && { display: 'none' })} component={Box} item xs={1}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_fallas.label',
                    defaultMessage: 'Fallas',
                  })}
                  name="fallas"
                  value={filtersState.fallas}
                  onChange={(e) => setFiltersState({ ...filtersState, fallas: e.target.value })}
                  onKeyDown={(e) => handleOnEnter(e, 'fallas', filtersState.fallas)}
                  onBlur={(e) => handleChange('fallas', filtersState.fallas)}
                  disabled={filtersLoading}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="base"
                  multiple={true}
                  disabled={filtersLoading}
                  options={bases}
                  defaultValue={[]}
                  value={filtersState.base ? filtersState.base : []}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  onChange={(e, value) => setFiltersState({ ...filtersState, base: value })}
                  onKeyDown={(e) => handleOnEnter(e, 'base', filtersState.base)}
                  onBlur={(e) => handleChange('base', filtersState.base)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: 'formulariosGrid.render.filtros_base.label',
                        defaultMessage: 'Base',
                      })}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="subRegion"
                  multiple={true}
                  disabled={filtersLoading}
                  options={subRegiones}
                  defaultValue={[]}
                  value={filtersState.subRegion ? filtersState.subRegion : []}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  onChange={(e, value) => setFiltersState({ ...filtersState, subRegion: value })}
                  onKeyDown={(e) => handleOnEnter(e, 'subRegion', filtersState.subRegion)}
                  onBlur={(e) => handleChange('subRegion', filtersState.subRegion)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: 'formulariosGrid.render.filtros_subRegion.label',
                        defaultMessage: 'Subregion',
                      })}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.pais && { display: 'none' })} component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="pais"
                  disabled={filtersLoading}
                  options={paises}
                  value={filtersState.pais}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  onChange={(e, value) => setFiltersState({ ...filtersState, pais: value })}
                  onKeyDown={(e) => handleOnEnter(e, 'pais', filtersState.pais)}
                  onBlur={(e) => handleChange('pais', filtersState.pais)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: 'formulariosGrid.render.filtros_pais.label',
                        defaultMessage: 'Pais',
                      })}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid {...(!columnsEnabled.chofer && { display: 'none' })} component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({
                    id: 'formulariosGridFilters.render.filtros_chofer.label',
                    defaultMessage: 'Chofer',
                  })}
                  name="chofer"
                  value={filtersState.chofer}
                  onChange={(e) => setFiltersState({ ...filtersState, chofer: e.target.value })}
                  onKeyDown={(e) => handleOnEnter(e, 'chofer', filtersState.chofer)}
                  onBlur={(e) => handleChange('chofer', filtersState.chofer)}
                  disabled={filtersLoading}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2} className="align-self-end">
              <form noValidate autoComplete="off">
                <div className="d-flex inline justify-content-around align-items-center">
                  <FormattedMessage
                    id="formulariosGridFilters.render.filtros.ver_inactivos"
                    defaultMessage="Ver Inactivos"
                  />
                  <Switch
                    onChange={(value) => handleChangeInactivosSwich(value)}
                    checked={filtersState.showInactivos}
                    disabled={filtersLoading}
                    offColor="#FF4961"
                    onColor="#28D094"
                  />
                </div>
              </form>
            </Grid>
            <Grid component={Box} item xs={2} className="align-self-end">
              <form noValidate autoComplete="off">
                <div className="d-flex inline justify-content-around align-items-center">
                  <FormattedMessage
                    id="formulariosGridFilters.render.filtros.persistir_filtros"
                    defaultMessage="Persistir Filtros"
                  />
                  <Switch
                    onChange={(value) => setPersisteFiltros(value)}
                    checked={persisteFiltros}
                    disabled={filtersLoading}
                    offColor="#FF4961"
                    onColor="#28D094"
                  />
                </div>
              </form>
            </Grid>
          </Grid>
        </Collapse>
      )}
    </>
  );
}


export default FormulariosGridFilters
