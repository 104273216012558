import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import { TableRow } from '@material-ui/core'
import 'moment/min/locales'
import Swal from 'sweetalert2'
import * as Utils from '../../commons/utils/Utils'
import { StyledTableCellProps as StyledTableCell } from '../../commons/utils/TableStyles';

const ItemCategoriaGridRow = (props) => {
	const intl = props.intl
	const categoria = props.categoria;
	const baseUrl = props.baseUrl;

	const [redirectTo, setRedirectTo] = useState(null)

	const handleCategoriaDelete = () => {
		Swal.fire({
			title: intl.formatMessage({ id: '¿Confirma la eliminación?', defaultMessage: '¿Confirma la eliminación?' }),
			html: intl.formatMessage({ id: 'item_categoria_abm.eliminar.se_eliminara_la_categoria', defaultMessage: 'Se eliminará la Categoría' }) + ': ' + "<b>" + categoria.nombre + "</b>",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: intl.formatMessage({ id: 'Si', defaultMessage: 'Si' }),
			cancelButtonText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
		}).then((result) => {
			if (result.value) {
				Utils.deleteData('/panol2/categorias/' + categoria.id)
					.then(() => {
						props.fetchCategorias();
						Swal(intl.formatMessage({ id: 'item_categoria_abm.eliminar.eliminada', defaultMessage: 'Eliminada!' }), "", "success");
					})
					.catch((err) => {
						Swal(
							err.getMessage(),
							'',
							'error',
						);
					});
			}
		})
	};
	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<TableRow>
				<StyledTableCell align="left">
					{categoria.id}
				</StyledTableCell>
				<StyledTableCell align="left">
					{categoria.nombre}
				</StyledTableCell>
				<StyledTableCell>
					<div className="d-inline-flex">
						{props.permissions.itemsCategoriasVisualizar &&
							<button
								className="action view btn btn-sm btn-icon btn-dt-grid text-success"
								title="Ver"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + categoria.id)}
							>
								<i className="fa fa-search fa-xs" />
							</button>

						}
						{ props.permissions.itemsCategoriasModificar &&
							<button
								className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
								title="Editar"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + categoria.id + '/edit')}
							>
								<i className="fa fa-pencil fa-xs" />
							</button>
						}
						{ (props.permissions.itemsCategoriasEliminar && categoria.activa === 1 ) ?
							<button
								className="action delete btn btn-sm btn-icon btn-dt-grid text-danger"
								title="Eliminar"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => handleCategoriaDelete()}
							>
								<i className="fa fa-trash fa-xs" />
							</button>
							: ''
						}
					</div>
				</StyledTableCell>
			</TableRow>
		</>
	)
}

export default ItemCategoriaGridRow;
