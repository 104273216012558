import React, { useState } from "react";

const Notificacion = ({ closeable, title, message, top }) => {
  const [show, setShow] = useState(true);

  if (!show) return null;

  const close = () => {
    setShow(false)
    localStorage.setItem('cerrarNotificacion', true);
  }

  return (
    <div
      className="container position-absolute"
      style={{
        top: top ? top : "0",
        left: "50%",
        transform: "translateX(-50%)",
        width: "90%",
        maxWidth: "600px",
        zIndex: 100000,
        marginTop: "10px",
      }}
    >
      <div
        className="d-flex align-items-start p-2 shadow-sm"
        style={{
          backgroundColor: "white",
          borderLeft: "5px solid red",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          className="mr-2 d-flex align-items-center justify-content-center"
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            backgroundColor: "white",
            color: "red",
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <i className="far fa-times-circle" style={{ color: "red" }}></i>
        </div>

        <div className="flex-grow-1">
          <h4><strong>{title}</strong></h4>
          <p className="mt-1">{message}</p>
        </div>

        {closeable && (
          <button
            type="button"
            className="close"
            onClick={() => close()}
            style={{ marginLeft: "10px" }}
          >
            <span>&times;</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Notificacion;