import $ from 'jquery'
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import Dropzone from "./Dropzone.js";
import Loading from "../ui/Loading.js";
import { Redirect } from "react-router-dom";
import * as Utils from '../../commons/utils/Utils'
import Dialog from '../../commons/dialog/Dialog.js';
import backendStrings from '../../lang/backendStrings.js';
import { FormattedMessage, injectIntl } from "react-intl";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import React, { useState, useEffect, useLayoutEffect } from "react";
import PresupuestoDetalladoItem from "../presupuestoDetallado/PresupuestoDetalladoItem.js";
import PresupuestoDetalladoTarea from "../presupuestoDetallado/PresupuestoDetalladoTarea.js";

const FORM_DATA_INIT = {
	id: null,
	manoDeObra: 0,
	repuestos: 0,
	impuestos: 0,
	otros: 0,
	adjunto: null,
	adicional: false,
	presupuestoItems: [],
	presupuestoTareas: []

}

const requiredSymbol = ' *';

const TicketPresupuestoDetallado = (props) => {
  	const intl = props.intl;
	const [loading, setLoading] = useState(false);
  	const [redirectTo, setRedirectTo] = useState(null);
	const [presupuestoItems, setPresupuestoItems] = useState([]);
	const [presupuestoTareas, setPresupuestoTareas] = useState([]);
	const [formData, setFormData] =  useState({ ...FORM_DATA_INIT });
	const [totalRepuestos, setTotalRepuestos] = useState(0);
	const [totalManoObra, setTotalManoObra] = useState(0);
	const [impuestos, setImpuestos] = useState(0);
	const [otros, setOtros] = useState(0);
	const [total, setTotal] = useState(0);
	const [adjunto, setAdjunto] = useState(null);
	const [errors, setErrors] = useState([]);
	const [enabledItems, setEnabledItems] = useState(false);
	const [enabledTareas, setEnabledTareas] = useState(false);
	const [showItems, setShowItems] = useState([]);
	const [obligatoriosItem, setObligatoriosItem] = useState([]);
	const [showTareas, setShowTareas] = useState([]);
	const [obligatoriosTarea, setObligatoriosTarea] = useState([]);
	const obligatoriedadMutuaTareaItem = ConfigBusiness.get('tickets.presupuesto.obligatoriedadMutuaTareaItem') === 'true' ? true : false;

	useEffect(() => {
	  	init();
  	}, []);
	
	const init = () => {
		setImpuestos(0)
		setOtros(0)
		setAdjunto(null)
		setErrors([])
		let enabledItems = ConfigBusiness.get('tickets.presupuesto.items.habilitado') === 'true';
		let showItems = [];
		if(enabledItems){
			showItems = ConfigBusiness.get('tickets.presupuesto.items.show').split(',');
			setShowItems(showItems);
			let obligatorios = ConfigBusiness.get('tickets.presupuesto.items.obligatorios').split(',');
			obligatorios = obligatorios.filter((element) => showItems.includes(element));
			setObligatoriosItem(obligatorios);
		}
		setEnabledItems(enabledItems);

		let enabledTareas = ConfigBusiness.get('tickets.presupuesto.tareas.habilitado') === 'true'
		let showTareas = [];
		if(enabledTareas){
			showTareas = ConfigBusiness.get('tickets.presupuesto.tareas.show').split(',');
			setShowTareas(showTareas);
			let obligatorios = ConfigBusiness.get('tickets.presupuesto.tareas.obligatorios').split(',');
			obligatorios = obligatorios.filter((element) => showTareas.includes(element));
			setObligatoriosTarea(obligatorios);
		}
		setEnabledTareas(enabledTareas);

		if(!props.ticket.presupuestoNombre){
			setLoading(true)
			Promise.all([
				((props.ticket.ticketTipo === 'PREVENTIVO' || props.ticket.ticketTipo === 'CORRECTIVO') && props.ticket.servicio && enabledItems) ? Utils.getData(`/items/items-and-servicio-by-ticket/${props.ticket.id}`) : [],
				(props.ticket.ticketTipo === 'PREVENTIVO' || props.ticket.ticketTipo === 'CORRECTIVO') ? Utils.getData(`/tareas/tareas-and-servicio-by-ticket/${props.ticket.id}`) : [],
			])
			.then((data) => {
				let items = [];
				if(data[0]){
					data[0].forEach(element => {
						let cantidad = showTareas.includes('cantidad') ? (element.cantidad ? element.cantidad : "1") : "1"
						items.push({
							'cantidad': cantidad,
							'costo': element.costo ? element.costo : "0",
							'cuenta_mayor': element.cuenta_mayor,
							'cuenta_mayor_descripcion': element.cuenta_mayor_descripcion,
							'descripcion': element.descripcion,
							'external_code': element.external_code,
							'id': element.id,
							'id_item': element.id_item,
							'marca': element.marca,
							'marcaNombre': element.marcaNombre,
							'numero_parte': element.numero_parte,
							'referencia': element.referencia,
							'servicio_id': element.servicio_id,
							'servicio_nombre': element.servicio_nombre,
							'subcategoria': element.subcategoria,
							'tipo': element.tipo,
							'unidad': element.unidad,
						});
					});
				}
				setPresupuestoItems(items);
				let tareas = [];
				if(data[1]){
					data[1].forEach(element => {
						let cantidad = showItems.includes('cantidad') ? (element.cantidad ? element.cantidad : "1") : "1"
						tareas.push({
							'id_tarea': element.id,
							'external_code': element.external_code,
							'nombre': element.nombre,
							'cuenta_mayor': element.servicio_external_code,
							'cuenta_mayor_descripcion': element.servicio_nombre,
							'precio': element.precio ? element.precio : "0",
							'cantidad': cantidad,
							'servicio_id': element.servicio_id,
							'servicio_nombre': element.servicio_nombre,
						});
					});
				}
				setPresupuestoTareas(tareas);
				calculateTotal(items, tareas, 0, 0);
			})
			.catch((err) => {
				setLoading(false)
			}).finally(() => {
				setLoading(false)
			});
		}
	};

	const calculateTotal = (items, tareas, impuestosActual, otrosActual) => {
	let totalRepuestosNew = 0;
		items.forEach(element => {
			if(element.cantidad){
				if(element.costo){
					totalRepuestosNew += element.cantidad*element.costo
				}
			}
		});
		setTotalRepuestos(totalRepuestosNew);

		let totalManoObraNew = 0;
		tareas.forEach(element => {
			if(element.precio){
				totalManoObraNew += element.cantidad*parseFloat(element.precio)
			}
		});
		setTotalManoObra(totalManoObraNew);

		let totalNew = 0;
		totalNew = totalRepuestosNew + totalManoObraNew + parseFloat(impuestosActual) + parseFloat(otrosActual);

		setTotal(totalNew)
  };

	const deleteServicioItem = (indexArray) => {
		let items = [];
		presupuestoItems.forEach((element, index) => {
			if (index!==indexArray) {
				items.push(element);
			}
		});
		calculateTotal(items, presupuestoTareas, impuestos, otros);
		setPresupuestoItems(items);
  };

	const addPresupuestoRepuesto = (newServicioItem) => {
		let copy = JSON.parse(JSON.stringify(presupuestoItems));
		copy.push(newServicioItem)
		calculateTotal(copy, presupuestoTareas, impuestos, otros);
		setPresupuestoItems(copy);
  };

	const addPresupuestoTarea = (newPresupuestoTarea) => {
		let copy = JSON.parse(JSON.stringify(presupuestoTareas));
		copy.push(newPresupuestoTarea)
		calculateTotal(presupuestoItems, copy, impuestos, otros);
		setPresupuestoTareas(copy);
  };

	const handleCancel = (newServicioItem) => {
		props.callbackClose();
		init();
  };

	const deletePresupuestoTareas = (indexArray) => {
		let tareas = [];
		presupuestoTareas.forEach((element, index) => {
			if (index!==indexArray) {
				tareas.push(element);
			}
		});
		calculateTotal(presupuestoItems, tareas, impuestos, otros);
		setPresupuestoTareas(tareas);
  };

	const handleFormChangeImpuestos = (value) =>  {
		calculateTotal(presupuestoItems, presupuestoTareas, value ? value : 0, otros);
		setImpuestos(value ? value : 0)
	}

	const handleFormChangeOtros = (value) =>  {
		calculateTotal(presupuestoItems, presupuestoTareas, impuestos, value ? value : 0);
		setOtros(value ? value : 0)
	}

	const validate = () => {
		if(ConfigBusiness.get('tickets.presupuesto.adjuntoObligatorio') === 'true' && !adjunto)
			return true;
		if(obligatoriedadMutuaTareaItem){
			if(enabledItems){
				if (presupuestoItems.length === 0) {
					return true;
				}
			}
			if(enabledTareas){
				if (presupuestoTareas.length === 0) {
					return true;
				}
			}
		}else{
			if(enabledItems && enabledTareas){
				if (!(presupuestoItems.length > 0 || presupuestoTareas.length > 0)) {
					return true;
				}
			}
		}
		return false;
	}
	const handleSubmit = () =>  {
		setLoading(true);
		let formDataCopy = JSON.parse(JSON.stringify(formData));
		formDataCopy.id = props.ticket.id
		formDataCopy.manoDeObra = totalManoObra
		formDataCopy.repuestos = totalRepuestos
		formDataCopy.impuestos = impuestos
		formDataCopy.otros = otros
		formDataCopy.adicional = false
		formDataCopy.presupuestoItems = presupuestoItems
		formDataCopy.presupuestoTareas = presupuestoTareas
		formDataCopy.adjunto = adjunto
		Utils.sendData(
			`/ticket-presupuestos/ticket/${props.ticket.id}`,
			'POST',
			formDataCopy,
		)
		.then(response => {
			if(response.status === 400) {
				response.json()
				.then(data => {
					setErrors(data.detalle)
				});
			}else {
				if (response.status === 202) {
					Dialog.alert(intl, {
						title: intl.formatMessage({ id: 'Aprobado', defaultMessage: 'Aprobado' }),
						text: intl.formatMessage({ id: 'Su_presupuesto_fue_aprobado._Puede_comenzar_a_trabajar', defaultMessage: 'Su presupuesto fue aprobado. Puede comenzar a trabajar' }),
						icon: 'success'
					});
				}
				resetPresupuesto();
				setLoading(false)
				props.callbackSave();
				props.callbackClose();
			}
		})
		.catch((err) => {
			setLoading(false);
		});
	}

	const resetPresupuesto = (data, index) => {
		setFormData({ ...FORM_DATA_INIT })
		setTotalRepuestos(0)
		setPresupuestoItems([]);
		setPresupuestoTareas([]);
		calculateTotal([],[], 0, 0)
		setImpuestos(0);
		setOtros(0);
		setAdjunto(0);
	}

	const updateServicioItem = (data, index) => {
		presupuestoItems[index].cantidad = data.cantidad
		presupuestoItems[index].costo = data.costo
		calculateTotal(presupuestoItems, presupuestoTareas, impuestos, otros);
	}

	const updatePresupuestoTarea = (data, index) => {
		presupuestoTareas[index].cantidad = data.cantidad
		presupuestoTareas[index].precio = data.costo
		calculateTotal(presupuestoItems, presupuestoTareas, impuestos, otros);
	}
	
  return (
	<React.Fragment>
	  	{redirectTo && <Redirect push to={redirectTo} />}
	  	{loading && <Loading />}
			<div>
				<div className="alert alert-danger" role="alert" hidden={errors.length===0}>
					{errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
				</div>


				{enabledItems ? 
					<div className="card pull-up">
						<div className="card-content">
							<div className="card-body">
								<h4 className="form-section">
									<i className="la la-file-text"></i><FormattedMessage id="Repuesto" defaultMessage="Repuesto"/>
								</h4>
								<div className="row">
									<div className="col-md-12">
										<PresupuestoDetalladoItem
											ticket={props.ticket} 
											presupuestoItems={presupuestoItems}
											deleteItem={deleteServicioItem}
											addItemServicio={addPresupuestoRepuesto}
											totalRepuestos={totalRepuestos}
											updateServicioItem={updateServicioItem}
											showItems={showItems}
											obligatorios={obligatoriosItem}
										>
										</PresupuestoDetalladoItem>
									</div>
								</div>
							</div>
						</div>
					</div>
					: null
				}
				{enabledTareas ? 
					<div className="card pull-up">
						<div className="card-content">
							<div className="card-body">
								<h4 className="form-section">
									<i className="la la-file-text"></i><FormattedMessage id="Mano_de_Obra" defaultMessage="Mano de Obra"/>
								</h4>
								<div className="row">
									<div className="col-md-12">
										<PresupuestoDetalladoTarea
											ticket={props.ticket}
											presupuestoTareas={presupuestoTareas}
											deleteTarea={deletePresupuestoTareas}
											addTarea={addPresupuestoTarea}
											totalManoObra={totalManoObra}
											updatePresupuestoTarea={updatePresupuestoTarea}
											showTareas={showTareas}
											obligatorios={obligatoriosTarea}
										>
										</PresupuestoDetalladoTarea>
									</div>
								</div>
							</div>
						</div>
					</div>
					: null
				}
				<div className="card pull-up">
					<div className="card-content">
						<div className="card-body">
							<h4 className="form-section">
								<i className="la la-file-text"></i>
							</h4>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-12 label-control col-form-label text-left">
											<FormattedMessage id="Adjunto" defaultMessage="Adjunto"/>
											{ ConfigBusiness.get('tickets.presupuesto.adjuntoObligatorio') === 'true' ? requiredSymbol : ''}:
										</label>
										<div className="col-md-3"></div>
										<Dropzone actions={props.actions} setAdjunto={setAdjunto}></Dropzone>
									</div>
								</div>
								<div className="col-md-6">
									{ConfigBusiness.get('tickets.presupuesto.impuestos.habilitado')==="true" ?
										<div className="col-md-12">
											<div className="row p-1" style={{justifyContent: "right"}}>
												<label className="col-md-6 label-control col-form-label" htmlFor="impuestos">
													<FormattedMessage id="Impuestos" defaultMessage="Impuestos"/> :
												</label>
												<div className="col-md-6">
													<div>
														<input
															type="number"
															className="form-control text-right pr-1"
															id="impuestos"
															name="impuestos"
															value={impuestos}
															onChange={(e) => handleFormChangeImpuestos(e.target.value)}
														/>
													</div>
												</div>
											</div>
										</div>
									: null}
									{ConfigBusiness.get('tickets.presupuesto.otros.habilitado')==="true" ?
										<div className="col-md-12">
											<div className="row p-1" style={{justifyContent: "right"}}>
												<label className="col-md-6 label-control col-form-label" htmlFor="otros">
													<FormattedMessage id="Otros" defaultMessage="Otros"/> :
												</label>
												<div className="col-md-6">
													<div>
														<input
															type="number"
															className="form-control text-right pr-1"
															id="otros"
															name="otros"
															value={otros}
															onChange={(e) => handleFormChangeOtros(e.target.value)}
														/>
													</div>
												</div>
											</div>
										</div>
									: null}
									<div className="col-md-12">
										<div className="row p-1" style={{justifyContent: "right"}}>
											<label className="col-md-6 label-control col-form-label" htmlFor="total">
												<b><FormattedMessage id="Total" defaultMessage="Total"/></b> :
											</label>
											<div className="col-md-6">
												<div>
													<input disabled 
														type="number"
														className="form-control text-right pr-1"
														id="total"
														name="total"
														value={total}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card pull-up">
					<div className="card-content">
						<div className="card-body">
							<div className="text-cd text-right">
								<button 
									type="submit"
									className="btn btn-primary mr-1" 
									onClick={handleSubmit}
									disabled={validate()}
								>
									<i className="fa fa-check-circle"></i> <FormattedMessage id="Guardar" defaultMessage="Guardar"/>
								</button>
								<button type="button" className="btn btn-danger" onClick={handleCancel}>
									<i className="fa fa-times-circle"></i> <FormattedMessage id="Cancelar" defaultMessage="Cancelar"/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
	</React.Fragment>
  );
};

export default injectIntl(TicketPresupuestoDetallado);
