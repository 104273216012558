import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../ui/Loading.js";
import $ from "jquery";
import "xlsx/dist/xlsx.full.min.js";
import {Grid} from '@material-ui/core';
import { FormattedMessage, injectIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from '../../commons/utils/Utils';
import PresupuestoDetalladoEditModal from "./PresupuestoDetalladoEditModal.js";
import PresupuestoDetalladoTareaGrid from "./PresupuestoDetalladoTareaGrid.js";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

const requiredSymbol = ' *';

const NEW_TAREA_INIT = {
	id: null,
	external_code: '',
	nombre: '',
	cuenta_mayor: '',
	cuenta_mayor_descripcion: '',
	precio: '',
	cantidad: '',
	servicio_id: null,
	servicio: ''
};

const PresupuestoDetalladoTarea = (props) => {
	const intl = props.intl;
	const [loading, setLoading] = useState(false);
	const [redirectTo, setRedirectTo] = useState(null);
	const [newPresupuestoTarea, setNewPresupuestoTarea] = useState({ ...NEW_TAREA_INIT })
	const [tareas, setTareas] = useState([]);
	const [servicios, setServicios] = useState([]);
	const [servicio, setServicio] = useState('');

	useEffect(() => {
		init();
	}, []);
	
	const init = () => {
		loadInit(null)
	}

	const renderField = (field) => {
		switch (field) {
			case 'id':
				return (
					<Grid component={Box} item xs={2} key="id">
						<form noValidate autoComplete="off">
							<Autocomplete
								clearText="Clear"
								type="number"
								id="id"
								name="id"
								options={tareas}
								value={newPresupuestoTarea}
								getOptionLabel={(option) => option.id ? option.id.toString() : ''}
								onChange={(event, value) => handleFormChangeSelectObject(value)}
								renderInput={(params) => (
									<TextField {...params}
										type="number"
										label={intl.formatMessage({ id: "Presupuesto.Id", defaultMessage: "Id" })}
										placeholder={intl.formatMessage({id: 'Presupuesto.Id', defaultMessage: 'Id'})}
										onChange={handleInputChange}
										
									/>
								)}
							/>
						</form>
					</Grid>
				);
			case 'external_code':
				return (
					<Grid component={Box} item xs={2} key="external_code">
						<form noValidate autoComplete="off">
							<TextField
								id="external_code"
								InputLabelProps={newPresupuestoTarea.external_code ? { shrink: true} : {}}
								label={intl.formatMessage({ id: 'Presupuesto.Tarea', defaultMessage: 'Tarea' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Tarea', defaultMessage: 'Tarea' })}
								value={newPresupuestoTarea.external_code}
								onChange={e => handleMaterialChange('external_code', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'nombre':
				return (
					<Grid component={Box} item xs={2} key="nombre">
						<form noValidate autoComplete="off">
							<Autocomplete
								id="nombre"
								name="nombre"
								options={tareas}
								value={newPresupuestoTarea}
								getOptionLabel={(option) => option.nombre ? option.nombre : ''}
								onChange={(event, value) => handleFormChangeSelectObject(value)}
								onBlur={(e) => resetSelect(newPresupuestoTarea, 'nombre')}
								renderInput={(params) => (
									<TextField {...params}
										label={intl.formatMessage({ id: "Presupuesto.Descripción", defaultMessage: "Descripción" })}
										placeholder={intl.formatMessage({id: 'Presupuesto.Descripción', defaultMessage: 'Descripción'})}
										onChange={handleInputChange}
									/>
								)}
							/>
						</form>
          			</Grid>
				);
			case 'cuenta_mayor':
				return (
					<Grid component={Box} item xs={2} key="cuenta_mayor">
						<form noValidate autoComplete="off">
							<TextField
								disabled={newPresupuestoTarea.external_code || newPresupuestoTarea.descripcion ? false : true}
								id="cuenta_mayor"
								type="text"
								select
								label={intl.formatMessage({ id: 'Presupuesto.Cuenta_Mayor', defaultMessage: 'Cuenta Mayor' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Cuenta_Mayor', defaultMessage: 'Cuenta Mayor' })}
								onChange={(e) => handleChangeSelectServicio(e.target.value)}
								value={newPresupuestoTarea.cuenta_mayor}
								className="col-12"
								InputLabelProps={newPresupuestoTarea.cuenta_mayor ? { shrink: true} : {}}
							>
								{servicios.map(option => (
									<MenuItem key={option.id} value={option}>
										{option.external_code}
									</MenuItem>
								))}
							</TextField>
						</form>
					</Grid>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<Grid component={Box} item xs={2} key="cuenta_mayor_descripcion">
						<form noValidate autoComplete="off">
							<TextField
								disabled={true}
								id="cuenta_mayor_descripcion"
								type="text"
								InputLabelProps={newPresupuestoTarea.cuenta_mayor_descripcion ? { shrink: true} : {}}
								label={intl.formatMessage({ id: 'Presupuesto.descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
								value={newPresupuestoTarea.cuenta_mayor_descripcion}
								onChange={e => handleMaterialChange('cuenta_mayor_descripcion', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'cantidad':
				return (
					<Grid component={Box} item xs={1} key="cantidad">
						<form noValidate autoComplete="off">
							<TextField
								id="cantidad"
								type="number"
								InputLabelProps={newPresupuestoTarea.cantidad ? { shrink: true} : {}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label={intl.formatMessage({ id: 'Presupuesto.Cantidad', defaultMessage: 'Cantidad' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Cantidad', defaultMessage: 'Cantidad' })}
								value={newPresupuestoTarea.cantidad}
								onChange={e => handleMaterialChange('cantidad', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'precio':
				return (
					<Grid component={Box} item xs={2} key="precio">
						<form noValidate autoComplete="off">
							<TextField
								id="precio"
								type="precio"
								InputLabelProps={newPresupuestoTarea.precio ? { shrink: true} : {}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label={intl.formatMessage({ id: 'Presupuesto.Precio', defaultMessage: 'Precio' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Precio', defaultMessage: 'Precio' })}
								value={newPresupuestoTarea.precio}
								onChange={e => handleMaterialChange('precio', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'servicio':
				return (
					<Grid component={Box} item xs={2} key="servicio">
						<form noValidate autoComplete="off">
							<TextField
								disabled={newPresupuestoTarea.nombre ? false : true}
								id="servicio"
								type="text"
								select
								label={intl.formatMessage({ id: 'Presupuesto.Servicio', defaultMessage: 'Servicio' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Servicio', defaultMessage: 'Servicio' })}
								onChange={(e) => handleChangeSelectServicio(e.target.value)}
								value={newPresupuestoTarea.servicio}
								className="col-12"
								InputLabelProps={newPresupuestoTarea.servicio ? { shrink: true} : {}}
							>
							{servicios.map(option => (
								<MenuItem key={option.id} value={option}>
									{option.nombre}
								</MenuItem>
							))}
							</TextField>
						</form>
					</Grid>
				);
			default:
				return null;
		}
	}

	const resetSelect = (item, column) => {
		if(!item.nombre){
			loadInit(column);
		}
	};

	const setLoadingAndUpdate = (state) => {
		setLoading(state);
	};

	const handleMaterialChange = (name, value) => {
		let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
		if (name === "precio") {
			if ((value && value >= 0) || !value) {
				copy[name] = value;
			}
		}else{
			copy[name] = value;
		}
		copy.servicio = newPresupuestoTarea.servicio
		setNewPresupuestoTarea(copy)
	};

	const handleFormChangeSelectObject = (value) => {
		let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
		if(value){
			copy.external_code = value.external_code ? value.external_code : ''
			copy.nombre = value.nombre ? value.nombre : ''
			copy.cuenta_mayor = value.cuenta_mayor ? value.cuenta_mayor : ''
			copy.cuenta_mayor_descripcion = value.cuenta_mayor_descripcion ? value.cuenta_mayor_descripcion : ''
			copy.precio = value.precio ? value.precio : "0"
			copy.id_tarea = value.id
			copy.id = value.id
			loadServices(value.id)
		}else{
			copy.id = '';
			copy.nombre = ''
			copy.id_tarea = null
			copy.servicio = null;
			copy.servicio_id = null;
			copy.cantidad = '';
			copy.precio = '';
			loadInit(null);
		}
		setNewPresupuestoTarea(copy)
	};

	const addTarea = () => {
		newPresupuestoTarea.cantidad = newPresupuestoTarea.cantidad ? newPresupuestoTarea.cantidad : 1;
		props.addTarea(newPresupuestoTarea)
		setNewPresupuestoTarea({ ...NEW_TAREA_INIT })
		setServicio('');
		setServicios([]);
	};

	const handleInputChange = (e) => {
		let value = e.target.value;
		let column = e.target.id;
		Utils.getData('/tareas/simple-search-presupuesto?column=' + column + '&value=' + value)
		.then((data) => {
			setTareas(data)
		});
	};
	
	const validate = () => {
		for (let i = 0; i < props.obligatorios.length; i++) {
			if(!newPresupuestoTarea[props.obligatorios[i]])
				return true
		}
		return false;
	};

	const handleChangeSelectServicio = (value) => {
		setServicio(value);
		let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
		if(value){
			copy['cuenta_mayor'] = value.external_code
			copy['cuenta_mayor_descripcion'] = value.nombre
			copy['servicio_id'] = value.id;
			copy['servicio'] = value;
		}else{
			copy['cuenta_mayor'] = ''
			copy['cuenta_mayor_descripcion'] = ''
			copy['servicio_id'] = null;
			copy['servicio'] = '';
		}
		setNewPresupuestoTarea(copy)
	};

	const loadServices = (tareaId) => {
		Utils.getData('/servicios/simple-search-presupuesto?tipo=tarea&ticketTipo=' + props.ticket.ticketTipo + '&tareaId=' + tareaId)
		.then((data) => {
			setServicios(data)
		});
	}

	const loadInit = (column) => {
		if(props.showTareas && props.showTareas.length > 0){
			if (column) {
				setLoading(true);
				Utils.getData('/tareas/simple-search-presupuesto?column=' + column + '&value=' + '')
				.then((data) => {
					setTareas(data)
				})
				.finally(() => {
					setLoading(false)
				});
			}else{
				if(props.showTareas.includes('id')){
					column = 'id'
				}else if (props.showTareas.includes('nombre')) {
					column = 'nombre'
				}
				if (column) {
					setLoading(true);
					Utils.getData('/tareas/simple-search-presupuesto?column=' + column + '&value=' + '')
					.then((data) => {
						setTareas(data)
					})
					.finally(() => {
						setLoading(false)
					});
				}
			}	
		}
		
	}

	const renderTableThead = (field, index) => {
		switch (field) {
			case 'index':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Items" defaultMessage="Items" />
					</th>
				);
			case 'id':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Id" defaultMessage="Id" />
					</th>
				);
			case 'external_code':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Tarea" defaultMessage="Tarea" />
					</th>
				);
			case 'nombre':
				return (
					<th key={index} scope="col" style={{ maxWidth: "3px" }}>
						<FormattedMessage id="Presupuesto.Descripción" defaultMessage="Descripción" />
					</th>
				);
			case 'cuenta_mayor':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
					</th>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
					</th>
				);
			case 'cantidad':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
					</th>
				);
			case 'precio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
					</th>
				);
			case 'total':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Total" defaultMessage="Total" />
					</th>
				);
			case 'servicio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Servicio" defaultMessage="Servicio" />
					</th>
				);
			default:
				return null;
		}
	}

	return (
		<React.Fragment>
			{redirectTo && <Redirect push to={redirectTo} />}
			{loading && <Loading />}
			<div className="table-responsive" style={{padding: "15px"}}>
				<div className="row" style={{marginRight: "3px", marginLeft: "3px", padding: "6px"}}>
					<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
						{props.showTareas.map(field => renderField(field))}
						<Grid component={Box} item xs={1}>
							<form noValidate autoComplete="off">
								<button
									className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main"
									style={{ height: 49 }}
									onClick={() => addTarea()}
									disabled={validate()}
								>
									<i className="ft-plus"></i>
								</button>
							</form>
						</Grid>
					</Grid>
				</div>
				<PresupuestoDetalladoTareaGrid
					intl={props.intl}
					presupuestoTareas={props.presupuestoTareas}
					loading={(state) => setLoadingAndUpdate(state)}
					deleteTarea={props.deleteTarea}
					updatePresupuestoTarea={props.updatePresupuestoTarea}
					showTareas={props.showTareas}
				/>
				<div className="row p-1" style={{justifyContent: "right"}}>
					<label className="col-md-2 label-control col-form-label" htmlFor="total_mano_de_obra">
						<FormattedMessage id="Presupuesto.Total_Mano_de_Obra" defaultMessage="Total Mano de Obra"/> {requiredSymbol}:
					</label>
					<div className="col-md-3">
						<div>
							<input disabled 
								type="number"
								className="form-control text-right pr-1"
								id="total_mano_de_obra"
								name="total_mano_de_obra"
								value={props.totalManoObra}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default injectIntl(PresupuestoDetalladoTarea);
