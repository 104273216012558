import { TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import iconoCheklist from '../../../src/assets/images/iconos/icono-checklist.png';
import Timezone from '../../commons/timezone/Timezone.js';
import {
  StyledTableCellProps as StyledTableCell,
  StyledTooltip,
} from '../../commons/utils/TableStyles';
import { PRIORIDAD } from '../../commons/constants/prioridad.js';
import Security from '../../commons/security/Security.js';
import swal2 from 'sweetalert2';
import * as Utils from '../../commons/utils/Utils';

const TicketsGridTableRow = (props) => {
  const intl = useIntl();
  const [ticket, setTicket] = useState(props.ticket);
  const columnsEnabled = props.columnsEnabled;
  const [redirectTo, setRedirectTo] = useState(null);
  const [totalPresupuesto, setTotalPresupuesto] = useState(0);
  const prioridadOptions = PRIORIDAD.map(option => ({
    ...option,
    nombre: intl.formatMessage({ id: `ticketsGrid.column_priority.${option.id}`, defaultMessage: option.nombre })
  }));
  const [prioridades, setPrioridades] = useState(prioridadOptions);

  useEffect(() => {
    if (columnsEnabled.mostrarTotalPresupuesto) {
      setTotalPresupuesto(ticket.presupuesto_total);
    }
  } ,[]);

  const goToTicket = (e) => {
    e.preventDefault();
    setRedirectTo(props.ticketUrl);
  };

  const TicketGridCell = ({
    show = true,
    className,
    onClick = goToTicket,
    align = 'left',
    style,
    children,
  }) => {
    return (
      <StyledTableCell
        show={show}
        className={className}
        onClick={onClick}
        align={align}
        style={style}
      >
        {children}
      </StyledTableCell>
    );
  };

  const cambiarPrioridad = (prioridad) => {

    swal2.fire({
      title: intl.formatMessage({ id: 'tickets.cambiar_prioridad.titulo_modal', defaultMessage: 'Prioridad de Tickets' }),
      text: intl.formatMessage({ id: 'tickets.cambiar_prioridad.texto_modal', defaultMessage: 'Vas a realizar un cambio de prioridad de Tickets' }),
      type: "warning",
      confirmButtonColor: "#0ABFBC",
      confirmButtonText: intl.formatMessage({ id: 'tickets.cambiar_prioridad.boton.cambiar', defaultMessage: 'Cambiar' }),
      cancelButtonColor: "#FF3145",
      cancelButtonText: intl.formatMessage({ id: 'tickets.cambiar_prioridad.boton.cancelar', defaultMessage: 'Cancelar' }),
      showCancelButton: true,
    }).then((result) => {
      if (result.value && result.value === true) {
        Utils.sendData("/tickets/cambiarPrioridad/" + ticket.ticket_id, "POST", JSON.stringify({ prioridad }))
          .then(() => {
            let ticketCopy = JSON.parse(JSON.stringify(ticket));
            ticketCopy.prioridad = prioridad;
            setTicket(ticketCopy);
            swal2.fire(
              intl.formatMessage({ id: 'El_cambio_fue_guardado_con_Exito!', defaultMessage: 'El cambio fue guardado con Éxito!' }),
              " ",
              'success'
            );
          });
      }
    }
    );
  }

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}

      <TableRow className="all cursor-pointer action edit">
        <StyledTableCell show={props.generacionMasiva} style={{ maxWidth: '30px' }}>
          <input
            type="checkbox"
            checked={props.selectedTickets.includes(ticket.ticket_id)}
            onChange={props.handleChecked(ticket.ticket_id)}
            style={{ maxWidth: '22px' }}
            className="customCheckbox cursor-pointer"
            id={ticket.ticket_id}
          />
        </StyledTableCell>

        <TicketGridCell>
          <div className="d-flex">
            <StyledTooltip title={props.ticketTipo} arrow placement="top">
              <div className={getAvatarTipo(ticket.tipo) + ' avatar dt-user-avatar'}>
                {props.ticketTipo ? props.ticketTipo.substr(0, 1) : ''}
              </div>
            </StyledTooltip>
          </div>
        </TicketGridCell>

        <TicketGridCell
          onClick={() => {
            setRedirectTo(`formulario/${ticket.formulario}`);
          }}
        >
          {ticket.origen === 'Checklist' && ticket.formulario_id ? (
            <StyledTooltip title={ticket.formulario_id} arrow placement="top">
              <div
                className=""
                style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}
              >
                <img
                  src={iconoCheklist}
                  alt={ticket.origen}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />
                <span>{ticket.ticket_id}</span>
              </div>
            </StyledTooltip>
          ) : (
            <div>
              <span style={{ marginLeft: '10px' }}>{ticket.ticket_id}</span>
            </div>
          )}
        </TicketGridCell>

        <TicketGridCell
          className={
            ticket.estado_color === 4
              ? 'status-yellow'
              : ticket.estado_color === 2
                ? 'status-red'
                : ticket.estado_color === 1
                  ? 'status-green'
                  : 'status-grey'
          }
        ></TicketGridCell>

        <TicketGridCell show={columnsEnabled.estado}>{props.ticketEstado}</TicketGridCell>
        <TicketGridCell show={columnsEnabled.prioridad} onClick={false}>
        <div className="d-flex align-items-center"
            style={{
              backgroundColor: prioridades.find(p => p.id === ticket.prioridad).color,
              padding: "4px",
              borderRadius: "7px",
              color: "white",
              fontSize: "9px",
              backgroundSize: "auto",
              width: "fit-content",
              border: "1px solid"
            }}
          >
            {prioridades.find(p => p.id === ticket.prioridad).nombre}
            {Security.hasPermission('TICKETS_CAMBIAR_PRIORIDAD') ?
              <div>
                <i
                  id="dLabel"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="fa fa-chevron-down cursor-pointer"
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                ></i>
                <div className="dropdown-menu" aria-labelledby="dLabel">
                  {prioridades ?
                    prioridades.map((prioridad, i) => {
                      return prioridad.id !== ticket.prioridad ? (
                        <p
                          key={i}
                          className="dropdown-item"
                          style={{
                            cursor: "pointer",
                            margin: "0",
                            padding: "5px 5px",
                            borderLeft: "solid " + prioridad.color
                        }}
                          onClick={() => cambiarPrioridad(prioridad.id)}
                        >
                          {prioridad.nombre}
                        </p>
                      ) : null;
                    })
                    : ""}
                </div>
              </div>
              : null}
          </div>
        </TicketGridCell>
        <TicketGridCell show={columnsEnabled.nivelAprobacionPresupuesto}>
          {ticket.nivel_aprobacion}
        </TicketGridCell>

        <TicketGridCell>
          {ticket.fecha_hora_alta
            ? Timezone.getDateForClient(ticket.fecha_hora_alta, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')
            : ''}
        </TicketGridCell>

        <TicketGridCell>
          <StyledTooltip title={ticket.detalle || ''} arrow placement="top">
            <div className="text-truncate" style={{ maxWidth: '150px' }}>
              {ticket.detalle}
            </div>
          </StyledTooltip>
        </TicketGridCell>

        <TicketGridCell>{ticket.servicio}</TicketGridCell>

        <TicketGridCell>
          <button
            className="px-0 btn btn-sm btn-ic n btn-dt-grid text-dark"
            title={intl.formatMessage({
              id: 'ticketsGrid.column_actions.button_title_trabajar_con_ticket',
              defaultMessage: 'Trabajar con ticket',
            })}
            data-togle="tooltip"
            data-placement="top"
          >
            <GetEntidadIcon ticket={ticket} />
            {ticket.entidad}
          </button>
        </TicketGridCell>

        <TicketGridCell>{ticket.gerenciador}</TicketGridCell>
        <TicketGridCell show={columnsEnabled.mostrarTotalPresupuesto}>
          { totalPresupuesto === 0 || 
            totalPresupuesto === "0.000" ||
            totalPresupuesto === null ? 'N/A'
            : parseFloat(totalPresupuesto).toFixed(2) }
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.region}>{ticket.region}</TicketGridCell>

        <TicketGridCell>{ticket.subRegion}</TicketGridCell>

        <TicketGridCell>{ticket.base}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.pais}>{ticket.pais}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.centroCostos}>{ticket.centro_costos}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.centroBeneficios}>
          {ticket.centro_beneficios}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.responsable1}> {ticket.responsable1}</TicketGridCell>

        <TicketGridCell>
          <EnTaller enTaller={ticket.en_taller} />
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.fechaprometida}>
          {ticket.fecha_prometida ? Timezone.getDateForClient(ticket.fecha_prometida, 'YYYY-MM-DD HH:mm:ss', 'L') : ""}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.diasReparacion}>
          {ticket.dias_reparacion ? ticket.dias_reparacion : ''}
        </TicketGridCell>
        <TicketGridCell>{ticket.mtt_total}</TicketGridCell>

        <TicketGridCell>{ticket.mtt_parcial}</TicketGridCell>

        <TicketGridCell show={columnsEnabled.encuestaSatisfaccion}>
          {ticket.encuesta_satisfaccion_nivel}
        </TicketGridCell>

        <TicketGridCell>
          {ticket.fecha_hora_realizado
            ? Timezone.getDateForClient(ticket.fecha_hora_realizado, 'YYYY-MM-DD HH:mm:ss', 'L')
            : ''}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.fechaHoraTurno}>
          {ticket.fecha_hora_turno
            ? Timezone.getDateForClient(ticket.fecha_hora_turno, 'YYYY-MM-DD HH:mm:ss', 'L')
            : ''}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.abono}>
          {ticket.abono === "1"
            ? intl.formatMessage({ id: 'ticketsGrid.column.abono,si', defaultMessage: 'Si' })
            : intl.formatMessage({ id: 'ticketsGrid.column.abono.no', defaultMessage: 'No' })}
        </TicketGridCell>

        <TicketGridCell show={columnsEnabled.tipoModelo}>{ticket.modelo_tipo}</TicketGridCell>

        <TicketGridCell>
          <button
            className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
            title={intl.formatMessage({
              id: 'ticketsGrid.column_actions.button_title_trabajar_con_ticket',
              defaultMessage: 'Trabajar con ticket',
            })}
            data-togle="tooltip"
            data-placement="top"
          >
            <i className="fa fa-pencil fa-xs"></i>
          </button>
        </TicketGridCell>
      </TableRow>
    </>
  );
};

const getAvatarTipo = (tipo) => {
  switch (tipo) {
    case "PREVENTIVO":
      return "paleta-preventivo";
    case "VENCIMIENTO":
      return "paleta-vencimiento";
    case "GESTORIA":
      return "paleta-gestoria";
    case "CORRECTIVO":
      return "paleta-correctivo";
    default:
      break;
  }
};


const EnTaller = ({ enTaller }) => {
  const intl = useIntl();

  if (enTaller === "1") {
    return (
      <div className="text-center default dt-info-icon">
        <i
          className="ft-check"
          data-togle="tooltip"
          data-placement="top"
          title={intl.formatMessage({
            id: 'ticketsGrid.column_taller.icon_default.label_taller_si_sin_verificacion',
            defaultMessage: 'SI - Sin Verificación',
          })}
        ></i>
      </div>
    );
  } else if (enTaller === "2") {
    return (
      <div className="text-center success dt-info-icon">
        <i
          className="ft-check"
          data-togle="tooltip"
          data-placement="top"
          title={intl.formatMessage({
            id: 'ticketsGrid.column_taller.icon_success.label_taller_si_verificado_gps',
            defaultMessage: 'SI - Verificado GPS',
          })}
        ></i>
      </div>
    );
  } else if (enTaller === "3") {
    return (
      <div className="text-center danger dt-info-icon">
        <i
          className="ft-check"
          data-togle="tooltip"
          data-placement="top"
          title={intl.formatMessage({
            id: 'ticketsGrid.column_taller.icon_danger.label_taller_no_verificado_gps',
            defaultMessage: 'No - Verificado GPS',
          })}
        ></i>
      </div>
    );
  } else {
    return '';
  }
};

const GetEntidadIcon = ({ ticket }) => {
  if (ticket.llanta_id !== null) {
    return (
      <img
        src="/images/llantas/tire.svg"
        style={{ height: '16px', width: '16px', marginRight: '4px', marginBlockEnd: '4px' }}
      ></img>
    );
  }

  if (ticket.movil_id !== null) {
    return <i className="la la-car" style={{ marginRight: '3px' }}></i>;
  }

  if (ticket.persona_id !== null) {
    return <i className="la la-user" style={{ marginRight: '3px' }}></i>;
  } else {
    return <i className="la la-dog" style={{ marginRight: '3px' }}></i>;
  }
};

export default TicketsGridTableRow;
