import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import '../../assets/css/styles.css'
import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Notificacion from '../app/Notificacion.js';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                usuario: null,
                clave: null
            },
            errors: [],
            loading: false,
            redirectTo: null,
            loginEnabled : ConfigBusiness.get("frontend.loginInstancia.habilitado"),
            loginServices: {},
            azureLoginRedirect: Config.get('apiUrlBase') + '/public/auth/azure/redirect',
            googleLoginRedirect: Config.get('apiUrlBase') + '/public/auth/google/redirect',
            loadingLogoLogin: true,
            logoLoginUrl: null,
            languages: [],
            languagesExtra: {
                "es": props.intl.formatMessage({id: 'login.languages.español', defaultMessage: 'Español'}),
                "en": props.intl.formatMessage({id: 'login.languages.ingles', defaultMessage: 'Inglés'})
            }
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);

        this.loginRandom = null;

        this.changeLocale = this.changeLocale.bind(this);
    }

    componentWillMount () {
        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'formData.usuario': (value) => Validator.notEmpty(value),
                'formData.clave': (value) => Validator.notEmpty(value)
            }
        });

        fetch(Config.get('apiUrlBase') + '/public/contextos_imagenes/logoLogin', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => {
            let contentType = response.headers.get('Content-Type');
            if (contentType && contentType.startsWith('image/')) {
                return response.blob();
            } else {
                throw new Error('La respuesta no es una imagen, Content-Type: ' + contentType);
            }
        }).then(blob => {
            let fileUrl = URL.createObjectURL(blob);
            this.setState({ logoLoginUrl: fileUrl });
        }).catch((error) => {
        }).finally(() => {
            this.setState({ loadingLogoLogin: false });
        });
    }

    componentDidMount () {

        document.body.className = 'vertical-layout vertical-menu 1-column menu-expanded blank-page blank-page';//bg-full-screen-image
        document.body.setAttribute('data-col', '1-column');

        fetch(Config.get('apiUrlBase') + '/public/auth/services', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if(response.status === 200) {
                response.json().then(data => {
                    this.setState({
                        loginServices: data
                    })
                });
            } else {
                this.error()
            }
        }).catch((error) => {
            this.error()
        }).finally(() => {
            this.setState({ loading: false });
            if (this.state.loginServices.classic === 'true') {
                this.refs.usuario.focus();
            }
        });


        let lang = ConfigBusiness.get("frontend.multilenguaje");
        const langJson = JSON.parse(lang);
        let langExtra = this.state.languagesExtra;

        if (langJson) {
                this.state.languages = langJson.map(function(elem) {
                    switch(elem.lang) {
                        case "es":
                                elem.title = langExtra[elem.lang]
                            break;
                        case "en":
                                elem.title = langExtra[elem.lang]
                            break;
                    }
                    return elem;
                });
        }
    }

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = value;
        this.setState({	formData: formDataCopy });
    }

    handleSubmit(event) {
        this.setState({ loading: true });
        this.setState({	errors: [] });

        fetch(Config.get('apiUrlBase') + '/public/auth/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...this.state.formData
            }),
        }).then(response => {
            if(response.status === 200) {
                response.json()
                    .then(data => {
                        if(data && data.usuario && data.usuario.token) {
                            localStorage.setItem('persona', JSON.stringify(data));
                            localStorage.setItem('token', data.usuario.token);
                            localStorage.setItem('cerrarNotificacion', false);
                            if (data.usuario.enable_twofa && ConfigBusiness.get("2fa.habilitado") == "true") {
                                if(!data.celularCodArea || !data.celularNumero){
                                    swal({
                                        title: this.props.intl.formatMessage({ id: 'login.errors.Contacte_al_administrador', defaultMessage: 'Contacte al administrador' }),
                                        text: this.props.intl.formatMessage({...(backendStrings['login.errors.No_se_encontro_numero_de_telefono_asociado_a_su_usuario'] ? backendStrings['login.errors.No_se_encontro_numero_de_telefono_asociado_a_su_usuario'] : backendStrings['errors.default']) }),
                                        icon: "error",
                                        buttons: {
                                            confirm: {
                                                text: this.props.intl.formatMessage({ id: 'login.errors.swal.buttons', defaultMessage: 'Aceptar' }),
                                                value: true,
                                                visible: true,
                                                className: "btn btn-primary",
                                                closeModal: true
                                            }
                                        }
                                    });
                                }else{
                                    this.setState({
                                        redirectTo: '/auth/2fa'
                                    })
                                }
                            } else {
                                window.location.reload();
                            }
                        } else {
                            this.error();
                        }
                    });
            } else if(response.status === 401) {
                response.json()
                    .then(data => {
                        this.setState({	errors: data.detalle });
                    });
            } else if(response.status === 400) {
                response.json()
                    .then(data => {
                        swal({
                            title: this.props.intl.formatMessage({ id: 'login.errors.Contacte_al_administrador', defaultMessage: 'Contacte al administrador' }),
                            text: this.props.intl.formatMessage({...(backendStrings[data.detalle[0]] ? backendStrings[data.detalle[0]] : backendStrings['errors.default']) }),
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: this.props.intl.formatMessage({ id: 'login.errors.swal.buttons', defaultMessage: 'Aceptar' }),
                                    value: true,
                                    visible: true,
                                    className: "btn btn-primary",
                                    closeModal: true
                                }
                            }
                        });
                    });
            }else {
                this.error();
            }
        }).catch((error) => {
            this.error();
        }).finally(() => {
            this.setState({ loading: false });
        });

        event.preventDefault();
    }

    handleForgotPassword(e) {
        this.setState({
            redirectTo: '/olvide-clave'
        });

        e.preventDefault();
    }

    changeLocale(locale) {
        localStorage.setItem('userLocale', locale);
        window.location.reload();
    }

    error() {
        swal({
            title: this.props.intl.formatMessage({ id: 'login.errors.swal.title', defaultMessage: 'Error interno del sistema.' }),
            text: this.props.intl.formatMessage({ id: 'login.errors.swal.text', defaultMessage: 'Contacte al administrador.' }),
            icon: "error",
            buttons: {
                confirm: {
                    text: this.props.intl.formatMessage({ id: 'login.errors.swal.buttons', defaultMessage: 'Aceptar' }),
                    value: true,
                    visible: true,
                    className: "btn btn-primary",
                    closeModal: true
                }
            }
        });
    }

    render() {
        this.formValidation.validate();
        let validationState = this.formValidation.state;
        if(!this.loginRandom){
            this.loginRandom = 1 + Math.floor((4 - 1) * Math.random());
        }
        return (
            <React.Fragment>
                {ConfigBusiness.get("generales.alertaSuspension")==='true' ? 
                    <Notificacion
                        closeable={false}
                        title={
                            this.props.intl.formatMessage({
                                id: 'layout.Alerta_de_suspension_por_falta_de_pago',
                                defaultMessage: 'Alerta de suspensión por falta de pago'
                            })
                        }
                        message={
                            this.props.intl.formatMessage({ 
                                id: 'layout.Tu_cuenta_tiene_un_pago_pendiente', 
                                defaultMessage: 'Tu cuenta tiene un pago pendiente. Por favor regularice la situación para poder seguir utilizando nuestro servicio sin interrupciones.'
                            })
                        }
                        top="0"
                    />
                
                : null}
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                {this.state.loading && <Loading hideSpinner />}
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row">
                        </div>
                        <div className="content-body">
                            <video className="video-login-bg" autoPlay muted loop type="video/mp4" poster={process.env.PUBLIC_URL + "/images/images-login/image"+ this.loginRandom +".png"}>
								<source src={process.env.PUBLIC_URL + "/images/videos-login/video"+ this.loginRandom +".mp4"} type="video/mp4"/>
								<img src={process.env.PUBLIC_URL + "/images/images-login/image"+ this.loginRandom +".png"} alt="Video no soportado"/>
                            </video>
                            <section className="flexbox-container">
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <div className="col-md-4 col-10 box-shadow-2 p-0">
                                        <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                            <div id="loginLogo" className="card-header border-0 text-center" style={{padding: 0}}>
                                                <img src={process.env.PUBLIC_URL + "/images/company/logo-login-app.png"} alt="" className="rounded-circle img-fluid center-block" />
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body" style={{paddingTop: 0}}>
                                                    {this.state.loginEnabled == "false" &&
                                                        <div class="text-center">
                                                            <h3>La plataforma no se encuentra disponible.</h3>
                                                            <span>Por favor, contacte a su Ejecutivo de Cuentas.</span>
                                                        </div>
                                                    }
                                                    {this.state.loginServices.classic === 'true' && this.state.loginEnabled == "true" &&
                                                        <form className="form-horizontal" noValidate ref="form" onSubmit={this.handleSubmit}>
                                                            <p id="loginSubtitle" className="card-subtitle line-on-side text-muted text-center font-small-3 mb-2"></p>
                                                            <fieldset className="form-group position-relative has-icon-left">
                                                                <input type="text" className="form-control form-control-lg input-lg" id="usuario" name="usuario" ref="usuario" placeholder={this.props.intl.formatMessage({ id: 'login.usuario.placeholder', defaultMessage: 'Usuario' })} tabIndex="1" required="" data-validation-required-message={this.props.intl.formatMessage({ id: 'login.usuario.data-validation-required-message', defaultMessage: 'Por favor ingrese su usuario.' })} aria-invalid="false" onChange={this.handleFormChange} />
                                                                <div className="form-control-position">
                                                                    <i className="la la-user"></i>
                                                                </div>
                                                                <div className="help-block text-danger font-small-3" hidden={validationState.formData.usuario.pristine || validationState.formData.usuario.valid}>{validationState.formData.usuario.message}</div>
                                                            </fieldset>
                                                            <fieldset className="form-group position-relative has-icon-left">
                                                                <input type="password" className="form-control form-control-lg input-lg" id="clave" name="clave" placeholder={this.props.intl.formatMessage({ id: 'login.contraseña.placeholder', defaultMessage: 'Contraseña' })} tabIndex="2" required onChange={this.handleFormChange} />
                                                                <div className="form-control-position">
                                                                    <i className="la la-key"></i>
                                                                </div>
                                                                <div className="help-block text-danger font-small-3" hidden={validationState.formData.clave.pristine || validationState.formData.clave.valid}>{validationState.formData.clave.message}</div>
                                                            </fieldset>
                                                            <div className="form-group row">
                                                                <div className="col-12 float-sm-left text-center text-sm-right">
                                                                    <button type="button" className="buttonAsLink card-link primary" onClick={e => this.handleForgotPassword(e)}>
                                                                        <i className="ft-unlock"></i>&nbsp;
                                                                        <FormattedMessage id="login.contraseña.olvido" defaultMessage="Olvidó su contraseña?"/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
                                                                {this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
                                                            </div>
                                                            <button type="submit" className="btn btn-outline-primary btn-lg btn-block" disabled={!validationState.form.valid}>
                                                                <i className={this.state.loading ? 'la la-spinner spinner' : 'la la-lock'}></i>
                                                                <FormattedMessage id="login.ingresar" defaultMessage="Ingresar"/>
                                                            </button>
                                                        </form>
                                                    }
                                                    {this.state.loginServices.google === 'true' && this.state.loginEnabled == "true" &&
                                                          <div className="mt-2">
                                                            <a href={this.state.googleLoginRedirect} className="my-2 text-center">
                                                                <div
                                                                  className="card d-flex align-items-center"
                                                                  style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    padding: '10px 20px',
                                                                    border: '1px solid #ddd',
                                                                    borderRadius: '8px',
                                                                    backgroundColor: '#f9f9f9',
                                                                    maxWidth: '300px',
                                                                    margin: '10px auto',
                                                                    cursor: 'pointer',
                                                                  }}
                                                                >
                                                                    <img
                                                                      style={{
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        marginRight: '5px', // Espaciado entre imagen y texto
                                                                      }}
                                                                      src={process.env.PUBLIC_URL + "/images/google.svg"}
                                                                      alt=""
                                                                    />
                                                                    <span style={{ fontSize: '16px', color: '#333', flex: 1 }}>
                                                                      <FormattedMessage id="login.google" defaultMessage="Sign in with Google" />
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                      }
                                                      {this.state.loginServices.azure === 'true' && this.state.loginEnabled == "true" &&
                                                          <div className="mt-2">
                                                            <a href={this.state.azureLoginRedirect} className="my-2 text-center">
                                                                <div
                                                                  className="card d-flex align-items-center"
                                                                  style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    padding: '10px 20px',
                                                                    border: '1px solid #ddd',
                                                                    borderRadius: '8px',
                                                                    backgroundColor: '#f9f9f9',
                                                                    maxWidth: '300px',
                                                                    margin: '10px auto',
                                                                    cursor: 'pointer',
                                                                  }}
                                                                >
                                                                    <img
                                                                      style={{
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        marginRight: '5px', // Espaciado entre imagen y texto
                                                                      }}
                                                                      src={process.env.PUBLIC_URL + "/images/microsoft.svg"}
                                                                      alt=""
                                                                    />
                                                                    <span style={{ fontSize: '16px', color: '#333', flex: 1 }}>
                                                                      <FormattedMessage id="login.microsoft" defaultMessage="Sign in with Microsoft" />
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    }
                                                    <div className="row mt-2">
                                                        <div className="col-6">
                                                            {!this.state.loadingLogoLogin ?
                                                                (this.state.logoLoginUrl ?
                                                                    <img src={this.state.logoLoginUrl} alt="" className="img-fluid center-block" />
                                                                    :<img src={process.env.PUBLIC_URL + "/images/company/logo-login-company.png"} alt="" className="img-fluid center-block" /> )
                                                            : null}
                                                        </div>
                                                        <div className="col-6 text-right"><img src={process.env.PUBLIC_URL + "/images/company/logo-login-powered-by.png"} alt="" className="img-fluid center-block" /></div>
                                                    </div>
                                                    <div className="row">
                                                        <h5 className="login-version">v{ process.env.REACT_APP_VERSION }</h5>
                                                    </div>
                                                    <div className="text-center">
                                                    {
                                                        this.state.languages.map(function(elem,index,arr) {

                                                            return(
                                                                <>
                                                                    <span
                                                                        onClick={e => this.changeLocale(elem.lang)}
                                                                        className="cursor-pointer"
                                                                        title={elem.title}>
                                                                            <img class="flag-multilang" src={process.env.PUBLIC_URL + "/images/flags/" + elem.code + ".svg"}/>
                                                                    </span>
                                                                    {   index < arr.length-1 &&
                                                                        <>&nbsp;|&nbsp;</>
                                                                    }
                                                                </>
                                                            )
                                                        }, this)
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(Login);
