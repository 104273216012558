import axios from 'axios';
import React , { useRef } from "react";
import ReactToPrint from "react-to-print";
import * as Utils from '../../commons/utils/Utils';
import Config from '../../commons/config/Config.js';
import FormularioViewPDF from './FormularioViewPDF.js';
import Security from '../../commons/security/Security.js';
import Validator from '../../commons/validation/Validator.js';
import * as Constants from './../atributoDinamico/constants.js';
import { castValoresDinamicos } from '../atributoDinamico/helpers.js';

const configAxios = {
	headers: {
		'Authorization-Token': localStorage.getItem("token")
	}
};

class FormularioPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: false,
      props: this.props,
      formData: {
        valores_dinamicos: [],
        medidor: 0,
        activo: 1,
        tipo_formulario: null,
        movil: null,
        chofer: null,
        persona: null,
        tareas: [],
        tareasEnResolucion: false,
      },
      valores_dinamicos: [],
      movil: null,
      movil_atributos_mostrar: [],
      movil_atributos: [
        {
          id: 'marca',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.marca",
            defaultMessage: "Marca",
          }),
        },
        {
          id: 'modelo',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.modelo",
            defaultMessage: "Modelo",
          }),
        },
        {
          id: 'chasis_nro',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.chasis_nro",
            defaultMessage: "Chasis",
          }),
        },
        {
          id: 'motor_nro',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.motor_nro",
            defaultMessage: "Motor",
          }),
        },
        {
          id: 'color',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.color",
            defaultMessage: "Color",
          }),
        },
        {
          id: 'centro_costos',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.centro_costos",
            defaultMessage: "Centro de Costos",
          }),
        },
        {
          id: 'plan_preventivo',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.plan_preventivo",
            defaultMessage: "Plan Preventivo",
          }),
        },
        {
          id: 'combustible',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.combustible",
            defaultMessage: "Combustible",
          }),
        },
        {
          id: 'proveedor_gps',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.proveedor_gps",
            defaultMessage: "Proveedor GPS",
          }),
        },
        {
          id: 'compania_origen',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.compania_origen",
            defaultMessage: "Compañia de Origen",
          }),
        },
        {
          id: 'titular',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.titular",
            defaultMessage: "Titular",
          }),
        },
        {
          id: 'telepeaje',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.telepeaje",
            defaultMessage: "Peaje",
          }),
        },
        {
          id: 'anio',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.anio",
            defaultMessage: "Año",
          }),
        },
      ],
    }
    this.uploadImages = this.uploadImages.bind(this);
  }

  reactToPrintTrigger(){
    return (<button
              className="btn btn-sm btn-icon btn-dt-grid text-success"
              type="button"
            >
              <i className="ft-printer"></i>
            </button>);
  }
  uploadImages(){
    let component = this;
    return new Promise((resolve) => {
      Utils.getData("/formulario/" + this.state.props.formulario_id).then((data) => {
        let formData = data;
        let valores_dinamicos = [];
        let movil_atributos_mostrar = [];


        if (formData) {
          let key_validator = formData.tipo_formulario.con_movil ? "formData.movil" : "formData.persona";
          let validator = {};
          validator["formData.tipo_formulario"] = (value) => Validator.notEmpty(value);
          validator[key_validator] = (value) => Validator.notEmpty(value);
          if(formData.tipo_formulario.movil_atributos_json && formData.tipo_formulario.con_movil){
            Utils.getData("/moviles/" + formData.movil.id).then((movil) => {

              let movil_atributos_array = JSON.parse(formData.tipo_formulario.movil_atributos_json);
              this.state.movil_atributos.forEach(function(element) {
                if(movil_atributos_array.includes(element.id))
                  movil_atributos_mostrar.push(element);
              });

              valores_dinamicos = castValoresDinamicos(
                formData.tipo_formulario.atributos_dinamicos,
                formData.valores_dinamicos
              );
              
              let userChofer = JSON.parse(localStorage.getItem("persona"));
              if (Security.hasPermission("USUARIO_POSEER_MOVIL")) {
                formData.chofer = userChofer;
              }

              let adjuntos = [];
              valores_dinamicos.forEach(function (valor, i) {
                if((valor.tipoValor===Constants.ID_IMAGEN || valor.tipoValor===Constants.ID_ADJUNTO) && valor.value && valor.value[0]){
                  adjuntos.push({indexValorDinamico: i, valores_dinamicos: valor});
                }
                if(valor.tipoValor===Constants.ID_FIRMA && valor.value){
                  adjuntos.push({indexValorDinamico: i, valores_dinamicos: valor});
                }
              });

              let promises = [];
              let config = Object.assign({}, configAxios, { responseType: 'blob' });
              
              adjuntos.forEach(function (element, i) {
                if(element.valores_dinamicos.tipoValor===Constants.ID_IMAGEN){
                  promises.push(axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + element.valores_dinamicos.value[0], config));
                }
                if(element.valores_dinamicos.tipoValor===Constants.ID_FIRMA){
                  promises.push(axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + element.valores_dinamicos.value, config));
                }
                if(element.valores_dinamicos.tipoValor===Constants.ID_ADJUNTO){
                  promises.push(axios.get(Config.get('apiUrlBase') + '/adjuntos/' + element.valores_dinamicos.value[0], configAxios));
                }
              });
              Promise.all(promises)
              .then((adjuntosArray) => {
                adjuntosArray.forEach(function (element, i) {
                  let adjunto = adjuntos[i];
                  if(adjunto && adjunto.valores_dinamicos.tipoValor===Constants.ID_IMAGEN){
                    valores_dinamicos[adjunto.indexValorDinamico].adjuntoUrlArray = [element ? URL.createObjectURL(element.data) : null]
                  }
                  if(adjunto && adjunto.valores_dinamicos.tipoValor===Constants.ID_FIRMA){
                    valores_dinamicos[adjunto.indexValorDinamico].adjuntoUrlArray = [element ? URL.createObjectURL(element.data) : null]
                  }
                  if(adjunto && adjunto.valores_dinamicos.tipoValor===Constants.ID_ADJUNTO){
                    valores_dinamicos[adjunto.indexValorDinamico].adjuntoArray = [element ? element.data : null]
                  }
                 
                });
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                component.setState({ 
                  formData: formData,
                  movil: movil,
                  valores_dinamicos: valores_dinamicos,
                  movil_atributos_mostrar: movil_atributos_mostrar,
                }, resolve);
              });

            }).catch((err) => {
              console.log(err)
            })
          }else{
            valores_dinamicos = castValoresDinamicos(
              formData.tipo_formulario.atributos_dinamicos,
              formData.valores_dinamicos
            );

            let adjuntos = [];
            valores_dinamicos.forEach(function (valor, i) {
              if((valor.tipoValor===Constants.ID_IMAGEN || valor.tipoValor===Constants.ID_ADJUNTO) && valor.value && valor.value[0]){
                adjuntos.push({indexValorDinamico: i, valores_dinamicos: valor});
              }
              if(valor.tipoValor===Constants.ID_FIRMA && valor.value){
                adjuntos.push({indexValorDinamico: i, valores_dinamicos: valor});
              }
            });

            let promises = [];
            let config = Object.assign({}, configAxios, { responseType: 'blob' });
            
            adjuntos.forEach(function (element, i) {
              if(element.valores_dinamicos.tipoValor===Constants.ID_IMAGEN){
                promises.push(axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + element.valores_dinamicos.value[0], config));
              }
              if(element.valores_dinamicos.tipoValor===Constants.ID_FIRMA){
               promises.push(axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + element.valores_dinamicos.value, config));
              }
              if(element.valores_dinamicos.tipoValor===Constants.ID_ADJUNTO){
                promises.push(axios.get(Config.get('apiUrlBase') + '/adjuntos/' + element.valores_dinamicos.value[0], configAxios));
              }
            });
            Promise.all(promises)
            .then((adjuntosArray) => {
              adjuntosArray.forEach(function (element, i) {
                let adjunto = adjuntos[i];
                if(adjunto && adjunto.valores_dinamicos.tipoValor===Constants.ID_IMAGEN){
                  valores_dinamicos[adjunto.indexValorDinamico].adjuntoUrlArray = [URL.createObjectURL(element.data)]
                }
                if(adjunto && adjunto.valores_dinamicos.tipoValor===Constants.ID_FIRMA){
                  valores_dinamicos[adjunto.indexValorDinamico].adjuntoUrlArray = [URL.createObjectURL(element.data)]
                }
                if(adjunto && adjunto.valores_dinamicos.tipoValor===Constants.ID_ADJUNTO){
                  valores_dinamicos[adjunto.indexValorDinamico].adjuntoArray = [element.data]
                }
                
              });
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              component.setState({ 
                formData: formData,
                valores_dinamicos: valores_dinamicos,
              }, resolve);
            });
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    });
  }
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={this.reactToPrintTrigger}
          content={() => this.componentRef}
          onBeforeGetContent={this.uploadImages}
          
        />
        <div style={{display: 'none'}}>
          <FormularioViewPDF 
            ref={el => (this.componentRef = el) }
            {...this.props}
            formData={this.state.formData}
            movil={this.state.movil}
            movil_atributos_mostrar={this.state.movil_atributos_mostrar}
            valores_dinamicos={this.state.valores_dinamicos}
            action="VIEW"
          />
        </div>
      </div>
    );
  }
}

export default FormularioPdf;