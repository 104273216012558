import React, { useState, useEffect } from "react";
import { useIntl } from 'react-intl'
import {
	Collapse,
	TextField,
	Grid,
	Box,
} from '@material-ui/core';
import Switch from "react-switch";
import * as Utils from '../../commons/utils/Utils.js';

const FILTER_STATE_INIT = {
	nombre: '',
	categoria: "",
	showInactivos: false
}

const handleInitFilters = () => {
	const filterInit = FILTER_STATE_INIT;
	return filterInit;
}

const ItemCategoriaGridFiltros = (props) => {
	const intl = useIntl();
	const [init, setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState(handleInitFilters(props))

	useEffect(() => {
		if (props.filterResetInputs) {
			setFiltersState(FILTER_STATE_INIT);
		}
	}, [props.filterResetInputs])

	useEffect(() => {
		!props.dropdownActive && props.setFilterResetInputs(false)
		!props.dropdownActive && setFiltersLoading(false)
	}, [props.dropdownActive]);

	const handleChange = (name, value) => {
		props.handleFilterChange();
		setFiltersLoading(true);
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));

		if (query['nombre'] !== '') {
			props.setSearchActive(true);
		} else {
			props.setSearchActive(false);
		}

		query[name] = value

		setInit(true)

		props.setFilterQuery(query);
		setFiltersLoading(false)
		return;
	};

	useEffect(() => {
		handleChange('showInactivos', filtersState.showInactivos);
	}, [filtersState.showInactivos])

	const handleOnEnter = (e, name, value) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			handleChange(name, value);
		}
	};

	const handleChangeInactivosSwitch = (value) => {
		setFiltersState({...filtersState, showInactivos : value});
		handleChange('showInactivos', value);
	  };

	return (
		<>
			{init &&
				<Collapse in={props.dropdownActive}>
					<Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({
										id: 'item_categoria.render.general_data.categoria',
										defaultMessage: 'Categoría',
									})}
									placeholder={intl.formatMessage({
										id: 'item_categoria.render.general_data.categoria',
										defaultMessage: 'Categoría',
									})}
									name="nombre"
									value={filtersState.nombre}
									onChange={(e) => setFiltersState({...filtersState, nombre: e.target.value})}
									onKeyDown={(e) => handleOnEnter(e, 'nombre', filtersState.nombre)}
									onBlur={(e) => handleChange("nombre", filtersState.nombre)}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
						<Grid component={Box} item xs={2} className="align-self-end">
							<form noValidate autoComplete="off">
								<div className="d-flex inline justify-content-around align-items-center">
									{intl.formatMessage({
										id: "item_categoria.render.filtros.ver_inactivos",
										defaultMessage: "Ver Inactivas"
									})}
									<Switch
										onChange={(value) => handleChangeInactivosSwitch(value)}
										checked={filtersState.showInactivos}
										disabled={filtersLoading}
										offColor="#FF4961"
										onColor="#28D094"
									/>
								</div>
							</form>
						</Grid>
					</Grid>
				</Collapse>
			}
		</>
	)
}

export default ItemCategoriaGridFiltros
