import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import duallistboxConfig from '../../commons/duallistbox/DuallistboxConfig.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

const TIPO_GESTOR_ID = 2

class GerenciadoresAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        razon_social: '',
        tipo: null,
        activo: true,
        telefono: '',
        observaciones: '',
        direccion: '',
        numero: '',
				subregiones: [],
				poderes: [],
				titulares: [],
        localidad: null,
        envia_alerta: false,

      },
      cecos:[{ id: 0, nombre: '124567'},{ id: 1, nombre: '9863456'}], // CAMBIAR POR WS
      regiones:[],
      jefes:[],
      personasCombo:[],
      responsables:[],
      subregiones:[],
      paises: [],
      provincias: [],
      localidades: [],
			poderesDisponibles: [],
			titularesDisponibles: [],
      subRegionesDisponibles: [],
      estados: [{ id: 1, nombre: this.props.intl.formatMessage({ id: 'gerenciadoresAbm.state_name_activo', defaultMessage: 'Activo' })},{ id: 0, nombre: this.props.intl.formatMessage({ id: 'gerenciadoresAbm.state_name_inactivo', defaultMessage: 'Inactivo' })}],
      tipos: [],

      categorias: [],
      gerenciadores: [],
      errors: [],
			direccionForm: null,
			loading: false
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleProvinciaChange = this.handleProvinciaChange.bind(this);

    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsuarioFormChange = this.handleUsuarioFormChange.bind(this);
    this.handlePerfilChange = this.handlePerfilChange.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.handleGerenciadorChange = this.handleGerenciadorChange.bind(this);
    this.handleBasesChange = this.handleBasesChange.bind(this);
    this.handleLocalidadChange = this.handleLocalidadChange.bind(this);
		this.handleChangeDireccion = this.handleChangeDireccion.bind(this);
    this.handleEnviaAlertaChange = this.handleEnviaAlertaChange.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.razon_social': (value) => Validator.notEmpty(value),
        'formData.contacto': (value) => Validator.notEmpty(value),
        'formData.direccionValid': (value) => Validator.notEmpty(value),
        'formData.tipo': (value) => Validator.notEmpty(value),
			}
    });

  }
  initForm() {
		this.setState({ loading: true });
    let component = this;


		Promise.all([
      // this.ajaxHandler.getJson('/bases/select'),
      this.ajaxHandler.getJson('/regiones', null),
      this.ajaxHandler.getJson('/gerenciadores/tipo/1/select',null),
      this.ajaxHandler.getJson('/paises/select',null),
      this.ajaxHandler.getJson('/subregiones',null),
      //this.getSubRegiones(),
      //this.state.props.action !== 'ADD' ? this.getData('gerenciadores', this.state.props.match.params.id) : null
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/gerenciadores/' + this.state.props.match.params.id) : null,
      this.ajaxHandler.getJson('/tipos-gerenciadores'),
			this.ajaxHandler.getJson('/movil-titulares'),
			this.ajaxHandler.getJson('/ordenestrabajo/estados'),
		]).then((data) => {
      let regiones = data[0];
      let gerenciadores = data[1];
      let paises = data[2];
      let subRegionesDisponibles = data[3];
      let formData = data[4];
			let tiposGerenciador = data[5];
			let titularesDisponibles = data[6];
      let poderesDisponibles = data[7];
			let direccionParams = {}

      if(formData){
        formData.tipo = formData.tipo ? formData.tipo.id : null;
				direccionParams = {
					calle: formData.direccion,
					numero: formData.numero,
					localidad: formData.localidad,
					provincia: formData.provincia,
					pais: formData.pais,
					latitud: formData.latitud,
					longitud: formData.longitud
				};

      }
        
      component.setState({
        regiones: regiones,
        gerenciadores: gerenciadores,
        subRegionesDisponibles: subRegionesDisponibles,
        paises: paises,
        tipos: tiposGerenciador,
				titularesDisponibles: titularesDisponibles,
				poderesDisponibles: poderesDisponibles,
				direccionForm: direccionParams
			});
			if(formData) component.setState({
				formData: formData
      });


      $(this.refs.duallistbox).bootstrapDualListbox({
        moveOnSelect: false,
        moveAllLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.options_label_mover_todos', defaultMessage: 'Mover todos' }),
        removeAllLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.options_label_remover_todos', defaultMessage: 'Remover todos' }),
        moveSelectedLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.options_label_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
        removeSelectedLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.options_label_remover_seleccionados', defaultMessage: 'remover_seleccionados' }),
        infoText: false,
        filterPlaceHolder: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.filter_placeholder', defaultMessage: 'Filtro' }),
        nonSelectedListLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.label_sub_regiones_disponibles', defaultMessage: 'Sub Regiones Disponibles:' }),
        selectedListLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_regions.label_sub_regiones_asignadas', defaultMessage: 'Sub Regiones Asignadas:' })
      });

      $(this.refs.duallistbox).bootstrapDualListbox(duallistboxConfig(this.props.intl));

			$(this.refs.duallistbox).change((e) => {

				let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
				formDataCopy.subregiones = $(this.refs.duallistbox).val();
				this.setState({
					formData: formDataCopy
				}, ()=>{ $(this.refs.duallistbox).bootstrapDualListbox('refresh', true);});
			});


			$(this.refs.duallistboxtitulares).bootstrapDualListbox({
        moveOnSelect: false,
        moveAllLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.options_label_mover_todos', defaultMessage: 'Mover todos' }),
        removeAllLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.options_label_remover_todos', defaultMessage: 'Remover todos' }),
        moveSelectedLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.options_label_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
        removeSelectedLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.options_label_remover_seleccionados', defaultMessage: 'Remover seleccionados' }),
        infoText: false,
        filterPlaceHolder: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.filter_placeholder', defaultMessage: 'Filtro' }),
        nonSelectedListLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.label_titulares_disponibles', defaultMessage: 'Sub Regiones Disponibles:' }),
        selectedListLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_holder.label_titulares_asignados', defaultMessage: 'Titulares Asignados:' })
      });

    $(this.refs.duallistboxtitulares).bootstrapDualListbox(duallistboxConfig(this.props.intl));

    $(this.refs.duallistboxtitulares).change((e) => {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.titulares = $(this.refs.duallistboxtitulares).val();
        this.setState({
            formData: formDataCopy
        }, ()=>{ $(this.refs.duallistboxtitulares).bootstrapDualListbox('refresh', true);});
    });


			$(this.refs.duallistboxpoderes).bootstrapDualListbox({
        moveOnSelect: false,
        moveAllLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.options_label_mover_todos', defaultMessage: 'Mover todos' }),
        removeAllLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.options_label_remover_todos', defaultMessage: 'Remover todos' }),
        moveSelectedLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.options_label_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
        removeSelectedLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.options_label_remover_seleccionados', defaultMessage: 'Remover seleccionados' }),
        infoText: false,
        filterPlaceHolder: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.filter_placeholder', defaultMessage: 'Filtro' }),
        nonSelectedListLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.label_poderes_disponibles', defaultMessage: 'Mover todos' }),
        selectedListLabel: component.props.intl.formatMessage({ id: 'gerenciadoresAbm.add_powers.label_poderes_asignados', defaultMessage: 'Mover todos' })
      });

      $(this.refs.duallistboxpoderes).bootstrapDualListbox(duallistboxConfig(this.props.intl));

        $(this.refs.duallistboxpoderes).change((e) => {

            let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy.poderes = $(this.refs.duallistboxpoderes).val();
            this.setState({
                formData: formDataCopy
            }, ()=>{ $(this.refs.duallistboxpoderes).bootstrapDualListbox('refresh', true);});
        });




    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });

  }
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('GERENCIADORES_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('GERENCIADORES_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('GERENCIADORES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
	}

  getData(service, param) {
    let serviceURL ="";

    if ( service === "provincias" || service === "localidades"  ) {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param+ '/select') : (Config.get('apiUrlBase') + '/' + service);
    } else {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
    }
    return fetch(serviceURL, {
      method: 'GET',
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  getSubRegiones() {
		return fetch(Config.get('apiUrlBase') + '/subregiones', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
  }
  handlePaisChange(object) {

    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        // formDataCopy.pais = { id: object.value, label: object.label };
        formDataCopy.pais = { id: object.value, label: object.label };
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => {
          this.setState({ provinciasLoading: true });
          this.ajaxHandler.getJson('/provincias/' + object.value + '/select')
          .then((data) => {
            this.setState({
              provincias: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ provinciasLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.pais = null;
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => resolve());
      }
    });
  }

  handleProvinciaChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.provincia = { id: object.value, label: object.label };
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          localidades: []
        }, () => {
          this.setState({ localidadesLoading: true });
          this.ajaxHandler.getJson('/localidades/' + object.value + '/select')
          .then((data) => {
            this.setState({
              localidades: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ localidadesLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          localidades: []
        }, () => resolve());
      }
    });
  }

  handleLocalidadChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.localidad = { id: object.value, label: object.label };
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }


  handleRegionChange(name, object) {
    Promise.all([
      this.getData('subregiones', object.id)
		]).then((data) => {
      let subregiones = data[0];
			this.setState({
        subregiones: subregiones
      });
      this.handleSelectFormChange(name, object);
		}).catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
    });
  }



  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  handleEnviaAlertaChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['envia_alerta'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  handleEsUsuarioChange(esUsuario) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['esUsuario'] = esUsuario;
    this.setState({
			formData: formDataCopy
		});
  }

  handleNotificacionesActivasChange(notificacionesActivas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleChangeDireccion(keyDireccion, valueDireccion, direccionValid){
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[keyDireccion] = valueDireccion;
    formDataCopy['direccionValid'] = direccionValid;
		this.setState({
			formData: formDataCopy
		});

	}

	handleBasesChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }


  handleUsuarioFormChange(event){
    const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

    //TODO: CHEQUEAR QUE EL USUARIO SEA ÜNICO LO MISMO CON EL NRO. DE LEGAJO
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleSelectFormChange(name, object) {

   let value = object === null ? null : object.id;

   if(this.state.props.action === 'EDIT') {
      if ( name === "localidad") {
          value = object.value;
      }

   }

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
    console.log(this.state.formData);
  }

  handlePerfilChange(name, perfil) {
    const value = perfil === null ? null : perfil.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		}, () => {
      if(perfil){
        perfil.permisos.includes('USUARIO_POSEER_MOVIL') ? this.setState({ hasPermisoMovil : true }) : this.setState({ hasPermisoMovil : false });
        perfil.permisos.includes('USUARIO_ACTUAR_COMO_GERENCIADOR') ? this.setState({ hasPermisoGerenciador : true }) : this.setState({ hasPermisoGerenciador : false });
        perfil.permisos.includes('USUARIO_TRABAJAR_BASES') ? this.setState({ hasPermisoBases : true }) : this.setState({ hasPermisoBases : false });
      }
		});
  }

  handleMovilChange(name, movil) {
    const value = movil === null ? null : movil.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleGerenciadorChange(name, gerenciador) {
   /* const value = gerenciador === null ? null : gerenciador.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});*/
  }

  handleDatePickerFormChange(name, event) {
    let date = event ? event.format(): '';
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }


	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    console.log(this.state.formData);
		this.ajaxHandler.fetch('/gerenciadores' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {

        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();

	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/gerenciadores'
		});
	}

	render() {
        this.formValidation.validate();
		let formData = this.state.formData;
        let validationState = this.formValidation.state;
        let state = this.state;
		return (
			<React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				    {this.state.loading && <Loading />}
			        <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
                                {this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
                            </div>
                            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
                            <div className="form-body">
                                <div className="card pull-up">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <h4 className="form-section">
                                                <i className="icon-home"></i><FormattedMessage id="gerenciadoresAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="gerenciadoresAbm.render.general_data.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                                            </h4>
                                            <div className="row">
                                                {/* Gerenciador */}
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-md-3 label-control col-form-label" htmlFor="razon_social">
                                                            <FormattedMessage id="gerenciadoresAbm.render.general_data.label_gerenciador" defaultMessage="Gerenciador *:"/>
                                                        </label>
                                                        <div className="col-md-9">
                                                            {this.props.action === 'VIEW' ? (
                                                            <div className="form-control-static col-form-label form-value">{formData.razon_social}</div>
                                                            ) : (
                                                            <div>
                                                                <input type="text" className="form-control" id="razon_social" name="razon_social" placeholder={this.props.intl.formatMessage({ id: 'gerenciadoresAbm.render.general_data.placeholder_gerenciador', defaultMessage: 'Gerenciador' })} value={formData.razon_social} onChange={this.handleInputFormChange} />
                                                                <div className="help-block text-danger field-message" hidden={validationState.formData.razon_social.pristine || validationState.formData.razon_social.valid}>{validationState.formData.razon_social.message}</div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* TIPOS */}
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-md-3 label-control col-form-label" htmlFor="tipo">
                                                            <FormattedMessage id="gerenciadoresAbm.render.general_data.label_tipo:" defaultMessage="Tipo:"/> *
                                                        </label>
                                                        <div className="col-md-9">
                                                            <div>
                                                                <Select
                                                                id="tipo"
                                                                name="tipo"
                                                                disabled={this.state.props.action !== 'EDIT' && this.state.props.action !== 'ADD'}    
                                                                readOnly={this.state.props.action !== 'EDIT' && this.state.props.action !== 'ADD'}
                                                                placeholder={this.props.intl.formatMessage({ id: 'gerenciadoresAbm.render.general_data.placeholder_tipo', defaultMessage: 'Tipo' })} 
                                                                options={this.state.tipos}
                                                                valueKey='id'
                                                                labelKey='nombre'
                                                                required
                                                                value={formData.tipo}
                                                                onChange={(e) => this.handleSelectFormChange("tipo", e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* SUBREGIONES */}
                                                <div className="col-md-9">
                                                    <div className="form-group row">
                                                        <label className="col-md-2 label-control col-form-label" htmlFor="subregiones">
                                                        <FormattedMessage id="gerenciadoresAbm.render.general_data.label_sub_regiones" defaultMessage="Sub Regiones *:"/>
                                                        </label>
                                                        {this.props.action === 'VIEW' ? (
                                                                <div className="col-md-6">
                                                                    {this.state.formData.subregiones && this.state.formData.subregiones.length ? (
                                                                    <div className="form-group row">
                                                                        <div className="col-md-12">
                                                                            <table className="table table-bordered">
                                                                            <thead className="thead-fleet subregiones">
                                                                                <tr>
                                                                                <th scope="col"><FormattedMessage id="gerenciadoresAbm.render.general_data.table_column_sub_regiones" defaultMessage="Subregiones"/></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {this.state.formData.subregiones.map((subr, index)=>{
                                                                                let sub = this.state.subRegionesDisponibles.find((element)=>element.id == subr);
                                                                                return(
                                                                                <tr key={index}>
                                                                                    <td className="align-middle">{sub.nombre}</td>
                                                                                </tr>
                                                                                );
                                                                            })}
                                                                            </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                        ) : (<p style={{marginTop:'10px'}}><FormattedMessage id="gerenciadoresAbm.render.general_data.no_cuenta_con_subregiones" defaultMessage="No cuenta con subregiones"/></p>)}
                                                                </div>) : (
                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <select multiple="multiple" size="10" ref="duallistbox"  value={formData.subregiones} readOnly>
                                                                    {this.state.subRegionesDisponibles.map(function(subregion) {
                                                                    return <option key={subregion.id} value={subregion.id}>{subregion.nombre}</option>;
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* ESTADO */}
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                                                        <FormattedMessage id="gerenciadoresAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Switch
                                                                onChange={this.handleEstadoChange}
                                                                checked={formData.activo}
                                                                options={this.state.estados}
                                                                id="activo"
                                                                name="activo"
                                                                valueKey='id'
                                                                labelKey='nombre'
                                                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                                                offColor="#FF4961"
                                                                onColor="#28D094"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


								<div className={this.state.formData.tipo != TIPO_GESTOR_ID ? 'hidden' : 'card pull-up'}>
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-briefcase"></i><FormattedMessage id="gerenciadoresAbm.render.management_powers.header_poderes_gestion" defaultMessage=" Poderes de Gestión"/>
                      </h4>
	                    <div className="row">
													{/* Poderes */}
													<div className="col-md-6">
														<div className="form-group row">
															<label className="col-md-3 label-control col-form-label" htmlFor="poderes">
																<FormattedMessage id="gerenciadoresAbm.render.management_powers.label_poderes" defaultMessage="Poderes:"/>
															</label>
															<div className="col-md-9">
																	<div className="form-group">
																		<select multiple="multiple" size="10" ref="duallistboxpoderes" value={formData.poderes}>
																			{this.state.poderesDisponibles.map(function(poder) {
																			return <option key={poder} value={poder}>{poder}</option>;
																			})}
																		</select>
																	</div>

															</div>
														</div>
													</div>
		                      {/* Titulares */}
		                      <div className="col-md-6">
		                        <div className="form-group row">
		                          <label className="col-md-3 label-control col-form-label" htmlFor="titulares">
														 		<FormattedMessage id="gerenciadoresAbm.render.management_powers.label_titulares" defaultMessage="Titulares:"/>
		                          </label>
		                          <div className="col-md-9">

																	<div className="form-group">
																		<select multiple="multiple" size="10" ref="duallistboxtitulares" value={formData.titulares}>
																			{this.state.titularesDisponibles.map(function(titular) {
																			return <option key={titular.id} value={titular.id}>{titular.nombre}</option>;
																			})}
																		</select>
																	</div>

		                          </div>
		                        </div>
		                      </div>
	                    </div>
                    </div>
                  </div>
                </div>


                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-phone"></i><FormattedMessage id="gerenciadoresAbm.render.contact_details.header_datos_Contacto" defaultMessage=" Datos de Contacto"/>
                      </h4>

                      <div className="row">
                        {/* Contacto */}
                        <div className="col-md-6">

                          <div  className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="contacto">
                              <FormattedMessage id="gerenciadoresAbm.render.contact_details.label_contacto" defaultMessage="Contacto *:"/>
                            </label>
                            <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.contacto}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="contacto" name="contacto" placeholder={this.props.intl.formatMessage({ id: 'gerenciadoresAbm.render.contact_details.placeholder_contacto', defaultMessage: 'Contacto' })} value={formData.contacto} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.contacto.pristine || validationState.formData.contacto.valid}>{validationState.formData.contacto.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="row">
                        {/* TELEFONO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="telefono">
                              <FormattedMessage id="gerenciadoresAbm.render.contact_details.label_telefono" defaultMessage="Teléfono :"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.telefono}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="telefono" name="telefono" placeholder={this.props.intl.formatMessage({ id: 'gerenciadoresAbm.render.contact_details.placeholder_telefono', defaultMessage: 'Teléfono' })} value={formData.telefono} onChange={this.handleInputFormChange} />

                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        {/* EMAIL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="email">
                              <FormattedMessage id="gerenciadoresAbm.render.contact_details.label_email" defaultMessage="Email* :"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.email}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="email" name="email" placeholder={this.props.intl.formatMessage({ id: 'gerenciadoresAbm.render.contact_details.placeholder_email', defaultMessage: 'Email' })} value={formData.email} onChange={this.handleInputFormChange} />

                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {formData.tipo ? (
                      formData.tipo== 4 || formData.tipo.nombre === 'GPS' ? (
                      <div className="row">
                        {/* ENVIA ALERTA */}
                        <div className="col-md-6" >
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="envia_alerta">
                            <FormattedMessage id="gerenciadoresAbm.render.general_data.label_envia_alerta" defaultMessage="Enviar alerta de GPS:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEnviaAlertaChange}
                                checked={formData.envia_alerta}
                                id="envia_alerta"
                                name="envia_alerta"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      ) :null 
                      ) :''} 

                    </div>
                  </div>
                </div>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body" id="container-direccion">
											<h4 className="form-section mt-2">
												<i className="ft-map-pin"></i><FormattedMessage id="gerenciadoresAbm.render.location_data.header_datos_ubicacion" defaultMessage=" Datos de Ubicación"/>
											</h4>
											<DireccionAutocompletar
												action={this.props.action}
												camposHidden={["codigoPostal"]}
												cambioDireccion={this.handleChangeDireccion}
												formulario={this.state.direccionForm}
                        localidadInteger={true}
												crearProvincias={true}
												camposObligatorios={[]}
                        crearLocalidades={true}
												/>

										</div>
									</div>
								</div>

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="gerenciadoresAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'gerenciadoresAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'gerenciadoresAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(GerenciadoresAbm);
