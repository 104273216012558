import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../ui/Loading.js";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as Utils from '../../commons/utils/Utils';
import swal from 'sweetalert'
import Fade from "@material-ui/core/Fade";
import {Grid} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import PresupuestoDetalladoEditModal from "./PresupuestoDetalladoEditModal";
import PresupuestoDetalladoItemGrid from "./PresupuestoDetalladoItemGrid";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

const requiredSymbol = ' *';

function Row(props) {
	const intl = props.intl;
	const [presupuestoItem, setPresupuestoItem] = useState(props.presupuestoItem);
	const [showModalEdit, setShowModalEdit] = useState(false);
	
	const useStylesCambioLlanta = makeStyles((theme) => ({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "15px",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			borderRadius: "15px",
		},
	}));

	const classesCambio = useStylesCambioLlanta();

	const total = () => {
		let total = null;
		if(presupuestoItem.cantidad !== null){
			if(presupuestoItem.costo !== null){
				total = presupuestoItem.cantidad * presupuestoItem.costo;
			}
		}
		return total;
	};

	const updateElement = (data) => {
		props.updateServicioItem(data, props.index);
		presupuestoItem.cantidad = data.cantidad;
		presupuestoItem.costo = data.costo;
		closeModalEdit();
	}

	const closeModalEdit = (event) => {
		setShowModalEdit(false);
	};

	const renderTableTbody = (field, index) => {
		switch (field) {
			case 'index':
				return (
					<td key={index} className="all custom-lineHeight">
						{props.index + 1}
					</td>
				);
			case 'id':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.id_item}
					</td>
				);
			case 'external_code':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.external_code}
					</td>
				);
			case 'descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.descripcion}
					</td>
				);
			case 'cuenta_mayor':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.cuenta_mayor}
					</td>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.cuenta_mayor_descripcion}
					</td>
				);
			case 'cantidad':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.cantidad}
					</td>
				);
			case 'costo':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.costo}
					</td>
				);
			case 'marcaNombre':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.marcaNombre}
					</td>
				);
			case 'total':
				return (
					<td key={index} className="all custom-lineHeight">
						{total()}
					</td>
				);
			case 'servicio':
				return (
					<td key={index} className="all custom-lineHeight">
						{
							presupuestoItem.servicio ? 
								presupuestoItem.servicio.nombre :
								(
									presupuestoItem.servicio_nombre ? presupuestoItem.servicio_nombre : ''
								)
						}
					</td>
				);
			default:
				return null;
		}
	}

	return (
		<React.Fragment>
			<tr key={presupuestoItem.id}>
				<Modal
					open={showModalEdit}
					onClose={closeModalEdit}
					className={classesCambio.modal}
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={showModalEdit}>
						<Box sx={style}>
							<PresupuestoDetalladoEditModal
								type="presupuestoItem"
								intl={props.intl}
								element={presupuestoItem}
								updateElement={updateElement}
								closeModalEdit={closeModalEdit}
								enabledCosto={props.showItems.includes('costo')}
								enabledCantidad={props.showItems.includes('cantidad')}
							/>
						</Box>
					</Fade> 
				</Modal>
				{props.showItems.map((field, index) => renderTableTbody(field, index))}
				<td className="all custom-lineHeight">
					<button
						className="action edit btn btn-sm btn-icon btn-dt-grid text-warning"
						title={intl.formatMessage({ id: 'Editar', defaultMessage: 'Editar' })}
						data-togle="tooltip"
						data-placement="top"
						onClick={(event) => {setShowModalEdit(true); event.preventDefault();}}
					>
						<i className="fa fa-pencil fa-xs"/>
					</button>
					<button
						className="action view btn btn-sm btn-icon btn-dt-grid text-danger"
						title={intl.formatMessage({ id: 'Eliminar', defaultMessage: 'Eliminar' })}
						data-togle="tooltip"
						data-placement="top"
						onClick={() => props.deleteItem(props.index)}
					>
						<i className="fa fa-trash fa-xs"></i>
					</button>
				</td>
			</tr>


		</React.Fragment>
	);
}

const NEW_ITEM_INIT = {
	external_code: '',
	descripcion: '',
	cuenta_mayor: '',
	cuenta_mayor_descripcion: '',
	cantidad: '',
	costo: '',
	id_item: null,
	servicio_id: null,
	servicio: '',
	marcaNombre: '',
	id: null,
	marca: null,
	categoria: null
};

const PresupuestoDetalladoItem = (props) => {
	const intl = props.intl;
	const [loading, setLoading] = useState(false);
	const [redirectTo, setRedirectTo] = useState(null);
	const [newPresupuestoItem, setNewPresupuestoItem] = useState({ ...NEW_ITEM_INIT })
	const [items, setItems] = useState([]);
	const [servicios, setServicios] = useState([]);
	const [presupuestoItems, setPresupuestoItems] = useState([]);
	const [comboInsumoMarca, setComboInsumoMarca] = useState([]);
	const [marcaObligatorio, setMarcaObligatorio] = useState(false);
	const MySwal = withReactContent(Swal);

	useEffect(() => {
	  	init();
  	}, []);

	useEffect(() => {
		setPresupuestoItems(props.presupuestoItems);
	}, [props.presupuestoItems]);

	const init = () => {
		loadInit(null)
	}

	const renderField = (field) => {
		switch (field) {
			case 'id':
				return (
					<Grid component={Box} item xs={2} key="id">
						<form noValidate autoComplete="off">
							<Autocomplete
								clearText="Clear"
								type="number"
								id="id"
								name="id"
								options={items}
								value={newPresupuestoItem}
								getOptionLabel={(option) => option.id ? option.id.toString() : ''}
								onChange={(event, value) => handleFormChangeSelectObject(value)}
								renderInput={(params) => (
									<TextField {...params}
										type="number"
										label={intl.formatMessage({ id: "Presupuesto.Id", defaultMessage: "Id" })}
										placeholder={intl.formatMessage({id: 'Presupuesto.Id', defaultMessage: 'Id'})}
										onChange={handleInputChange}
									/>
								)}
							/>
						</form>
					</Grid>
				);
			case 'external_code':
				return (
					<Grid component={Box} item xs={2} key="external_code">
						<form noValidate autoComplete="off">
							<TextField
								id="external_code"
								InputLabelProps={newPresupuestoItem.external_code ? { shrink: true} : {}}
								label={intl.formatMessage({ id: 'Presupuesto.Repuesto', defaultMessage: 'Repuesto' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Repuesto', defaultMessage: 'Repuesto' })}
								value={newPresupuestoItem.external_code}
								onChange={e => handleMaterialChange('external_code', e.target.value)}
								onBlur={HandleURepuesto}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'descripcion':
				return (
					<Grid component={Box} item xs={2} key="descripcion">
						<form noValidate autoComplete="off">
							<Autocomplete
								id="descripcion"
								name="descripcion"
								options={items}
								value={newPresupuestoItem}
								getOptionLabel={(option) => option.descripcion ? option.descripcion : ''}
								onChange={(event, value) => handleFormChangeSelectObject(value)}
								onBlur={(e) => resetSelect(newPresupuestoItem, 'descripcion')}
								renderInput={(params) => (
									<TextField {...params}
										label={intl.formatMessage({ id: "Presupuesto.Descripción", defaultMessage: "Descripción" })}
										placeholder={intl.formatMessage({id: 'Presupuesto.Descripción', defaultMessage: 'Descripción'})}
										onChange={handleInputChange}
									/>
								)}
							/>
						</form>
          			</Grid>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<Grid component={Box} item xs={2} key="cuenta_mayor_descripcion">
						<form noValidate autoComplete="off">
							<TextField
								disabled={true}
								id="cuenta_mayor_descripcion"
								type="text"
								InputLabelProps={newPresupuestoItem.cuenta_mayor_descripcion ? { shrink: true} : {}}
								label={intl.formatMessage({ id: 'Presupuesto.descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
								value={newPresupuestoItem.cuenta_mayor_descripcion}
								onChange={e => handleMaterialChange('cuenta_mayor_descripcion', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'cantidad':
				return (
					<Grid component={Box} item xs={1} key="cantidad">
						<form noValidate autoComplete="off">
							<TextField
								id="cantidad"
								type="number"
								InputLabelProps={newPresupuestoItem.cantidad ? { shrink: true} : {}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label={intl.formatMessage({ id: 'Presupuesto.Cantidad', defaultMessage: 'Cantidad' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Cantidad', defaultMessage: 'Cantidad' })}
								value={newPresupuestoItem.cantidad}
								onChange={e => handleMaterialChange('cantidad', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'costo':
				return (
					<Grid component={Box} item xs={2} key="costo">
						<form noValidate autoComplete="off">
							<TextField
								id="costo"
								type="number"
								InputLabelProps={newPresupuestoItem.costo ? { shrink: true} : {}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label={intl.formatMessage({ id: 'Presupuesto.Precio', defaultMessage: 'Precio' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Precio', defaultMessage: 'Precio' })}
								value={newPresupuestoItem.costo}
								onChange={e => handleMaterialChange('costo', e.target.value)}
								className="col-12"
							/>
						</form>
					</Grid>
				);
			case 'marcaNombre':
				return (
					<Grid component={Box} item xs={2} key="marcaNombre">
						<form noValidate autoComplete="off">
							<TextField
								disabled={true}
								id="marcaNombre"
								type="text"
								InputLabelProps={newPresupuestoItem.marcaNombre ? { shrink: true} : {}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label={intl.formatMessage({ id: 'Presupuesto.Marca', defaultMessage: 'Marca' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Marca', defaultMessage: 'Marca' })}
								value={newPresupuestoItem.marcaNombre}
								className="col-12"
							/>
						</form>
					</Grid>
				);
				case 'marca':
				return !marcaObligatorio ? (
					<Grid component={Box} item xs={2} key="marca">
						<form noValidate autoComplete="off">
							<Autocomplete
								id="marca"
								name="marca"
								options={comboInsumoMarca}
								value={newPresupuestoItem.marca}
								getOptionLabel={(option) => option.nombre ? option.nombre : ''}
								onChange={(event, value) => {
									const itemCopy = JSON.parse(JSON.stringify(newPresupuestoItem));
									setNewPresupuestoItem({...itemCopy, marca: value})
								}}
								renderInput={(params) => (
									<TextField {...params}
										label={intl.formatMessage({ id: "Presupuesto.Marca", defaultMessage: "Marca" })}
										placeholder={intl.formatMessage({id: 'Presupuesto.Marca', defaultMessage: 'Marca'})}
									/>
								)}
							/>
						</form>
					</Grid>) : '';
			case 'servicio':
				return (
					<Grid component={Box} item xs={2} key="servicio">
						<form noValidate autoComplete="off">
							<TextField
								disabled={newPresupuestoItem.descripcion ? false : true}
								id="servicio"
								type="text"
								select
								label={intl.formatMessage({ id: 'Presupuesto.Servicio', defaultMessage: 'Servicio' })}
								placeholder={intl.formatMessage({ id: 'Presupuesto.Servicio', defaultMessage: 'Servicio' })}
								onChange={(e) => handleChangeSelectServicio(e.target.value)}
								value={newPresupuestoItem.servicio}
								className="col-12"
								InputLabelProps={newPresupuestoItem.servicio ? { shrink: true} : {}}
							>
							{servicios.map(option => (
								<MenuItem key={option.id} value={option}>
									{option.nombre}
								</MenuItem>
							))}
							</TextField>
						</form>
					</Grid>
				);
			default:
				return null;
		}
	}

	const resetSelect = (item, column) => {
		if(!item.descripcion){
			loadInit(column);
		}
	};

	const setLoadingAndUpdate = (state) => {
		setLoading(state);
	};

	const handleMaterialChange = (name, value) => {
		let copy = JSON.parse(JSON.stringify(newPresupuestoItem));
		if (name === "costo" || name === "cantidad") {
			if ((value && value >= 0) || !value) {
				copy[name] = value;
			}
		}else{
			copy[name] = value;
		}
		copy.servicio = newPresupuestoItem.servicio
		setNewPresupuestoItem(copy)
	};

	const HandleURepuesto = (e) => {
		let external_code = e.target.value;
		if (external_code) { validateRepuesto(external_code); }
	};

	const validateRepuesto = (external_code) => {
		Utils.getData('/items/simple-search?external_code=' + external_code)
		.then((data) => {
			if(data.length > 0){
				let copy = JSON.parse(JSON.stringify(newPresupuestoItem));
				copy.external_code = data[0].external_code ? data[0].external_code : ''
				copy.descripcion = data[0].descripcion ? data[0].descripcion : ''
				copy.cuenta_mayor = data[0].cuenta_mayor ? data[0].cuenta_mayor : ''
				copy.cuenta_mayor_descripcion = data[0].cuenta_mayor_descripcion ? data[0].cuenta_mayor_descripcion : ''
				copy.costo = data[0].costo ? data[0].costo : "0"
				copy.id_item = data[0].id
				setNewPresupuestoItem(copy)
				loadServices(data[0].id)
			}else{
				swal(
					intl.formatMessage({id: 'El_código_de_repuesto_es_inexistente', defaultMessage: 'El código de repuesto es inexistente.' }),
					intl.formatMessage({id: 'Por_favor_ingrese_los_datos_manualmente.', defaultMessage: 'Por favor ingrese los datos manualmente.' }),
					"warning"
				);
				let copy = JSON.parse(JSON.stringify(newPresupuestoItem));
				copy.external_code = '';
				copy.descripcion = ''
				copy.id_item = ''
				copy.cuenta_mayor = ''
				copy.cuenta_mayor_descripcion = ''
				copy.costo = ''
				setServicios([]);
				setNewPresupuestoItem(copy)
			}
		});
	};

	const handleFormChangeSelectObject = (value) => {
		let copy = JSON.parse(JSON.stringify(newPresupuestoItem));
		
		if(value){
			copy.external_code = value.external_code ? value.external_code : ''
			copy.descripcion = value.descripcion ? value.descripcion : ''
			copy.cuenta_mayor = value.cuenta_mayor ? value.cuenta_mayor : ''
			copy.cuenta_mayor_descripcion = value.cuenta_mayor_descripcion ? value.cuenta_mayor_descripcion : ''
			copy.costo = value.costo ? value.costo : "0"
			copy.id_item = value.id
			copy.id = value.id
			copy.marcaNombre = value.marcaNombre
			copy.categoria = value.categoria
			loadServices(value.id)
			if (!marcaObligatorio) getMarcasByCategoria(value.categoria);
		}else{
			copy.id = '';
			copy.descripcion = '';
			copy.id_item = null;
			copy.servicio = null;
			copy.servicio_id = null;
			copy.cantidad = '';
			copy.costo = '';
			copy.categoria = null;
			copy.marca = null;
			setComboInsumoMarca([]);
			loadInit(null);
		}
		setNewPresupuestoItem(copy)
	};

	const handleInputChange = (e) => {
		let value = e.target.value;
		let column = e.target.id;
		Utils.getData('/items/simple-search?column=' + column + '&value=' + value )
		.then((data) => {
			setItems(data)
		});
	};

	const addItem = () => {
		newPresupuestoItem.cantidad = newPresupuestoItem.cantidad ? newPresupuestoItem.cantidad : 1;
		newPresupuestoItem.costo = newPresupuestoItem.costo ? newPresupuestoItem.costo : 0;
		props.addItemServicio(newPresupuestoItem)
		setNewPresupuestoItem({ ...NEW_ITEM_INIT })
		setServicios([]);
	};

	const validate = () => {
		for (let i = 0; i < props.obligatorios.length; i++) {
			if(!newPresupuestoItem[props.obligatorios[i]])
				return true
		}
		return false;
	};

	const handleChangeSelectServicio = (value) => {
		let copy = JSON.parse(JSON.stringify(newPresupuestoItem));
		if(value){
			copy['cantidad'] = value.cantidad ? value.cantidad : '';
			copy['servicio_id'] = value.id;
			copy['servicio'] = value;
		}else{
			copy['cantidad'] = '';
			copy['servicio_id'] = null;
			copy['servicio'] = '';
		}
		setNewPresupuestoItem(copy)
	};

	const loadServices = (itemId) => {
		Utils.getData('/servicios/simple-search-presupuesto?tipo=item&ticketTipo=' + props.ticket.ticketTipo + '&itemId=' + itemId)
		.then((data) => {
			setServicios(data)
		});
	}
	
	const loadInit = (column) => {
		if(props.showItems && props.showItems.length > 0){
			if (ConfigBusiness.get('inventario.marca.obligatorio') === 'false') setMarcaObligatorio(false);
			
			if (column) {
				setLoading(true);
				Utils.getData('/items/simple-search?column=' + column + '&value=' + '' )
				.then((data) => {
					setItems(data)
				})
				.finally(() => {
					setLoading(false)
				});
			}else{
				if(props.showItems.includes('id')){
					column = 'id'
				}else if (props.showItems.includes('descripcion')) {
					column = 'descripcion'
				}
				if (column) {
					setLoading(true);
					Utils.getData('/items/simple-search?column=' + column + '&value=' + '' )
					.then((data) => {
						setItems(data)
					})
					.finally(() => {
						setLoading(false)
					});
				}
			}	
		}
		
	}

	const getMarcasByCategoria = (catId) => {
		setLoading(true);
		const comboInsumoMarcaReq = !marcaObligatorio ? Utils.getData(`/panol/insumomarca/select-by-categoria/${catId}`) : null;
		comboInsumoMarcaReq.then(marcas => {
			setComboInsumoMarca(marcas)
		})
		.finally(() => setLoading(false));
	}

	return (
		<React.Fragment>
			{redirectTo && <Redirect push to={redirectTo} />}
			{loading && <Loading />}
			<div className="table-responsive" style={{padding: "15px"}}>
				<div style={{marginRight: "3px", marginLeft: "3px", padding: "6px"}}>
					<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
						{props.showItems.map(field => renderField(field))}
						<Grid component={Box} item xs={1}>
							<form noValidate autoComplete="off">
								<button
									className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main"
									style={{ height: 49 }}
									onClick={() => addItem()}
									disabled={validate()}
								>
									<i className="ft-plus"></i>
								</button>
							</form>
						</Grid>
					</Grid>
				</div>
				<PresupuestoDetalladoItemGrid
					intl={props.intl}
					presupuestoItems={presupuestoItems}
					marcaObligatorio={marcaObligatorio}
					loading={(state) => setLoadingAndUpdate(state)}
					deleteItem={props.deleteItem}
					updateServicioItem={props.updateServicioItem}
					showItems={props.showItems}
				/>
				<div className="row p-1" style={{justifyContent: "right"}}>
					<label className="col-md-2 label-control col-form-label" htmlFor="repuestos">
						<FormattedMessage id="Presupuesto.Total_Repuestos" defaultMessage="Total Repuestos"/> {requiredSymbol}:
					</label>
					<div className="col-md-3">
						<div>
							<input disabled 
								type="number"
								className="form-control text-right pr-1"
								id="total_repuestos"
								name="total_repuestos"
								value={props.totalRepuestos}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default injectIntl(PresupuestoDetalladoItem);
