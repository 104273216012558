import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Security from '../../commons/security/Security.js'
import Timezone from '../../commons/timezone/Timezone.js'
import Loading from '../ui/Loading.js'

class TicketVerPresupuestos extends Component {
	constructor(props) {
    super(props);
    this.props = props;

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    if(props.ticket) {
      this.ticket = props.ticket;
    }
    this.action = props.action;

    this.state = {
      loading: false,
      presupuestos: []
    }
  }

  componentDidMount() {
    if(Security.hasPermission('TICKETS_VER_PRESUPUESTOS')) {
        this.ajaxHandler.subscribe(this);
        this.loadFormData();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  componentDidUpdate() {
    $('[data-tooltip="tooltip"]').tooltip();
  }

  loadFormData() {
    let component = this;
    component.setState({ loading: true });
    component.ajaxHandler.getJson('/ticket-presupuestos/ticket/' + this.props.ticket.id + '/grid')
    .then(data => {
      if(data) component.setState({
        presupuestos: data
      });
    }).finally(() => {
      component.setState({ loading: false });
    });
  }

  handleViewAdjunto(e, adjunto) {
    this.ajaxHandler.fetch('/ticket-presupuestos/adjunto/' + adjunto, {
      method: 'GET'
    }).then(response => {
      if (response.status === 200) {
        return response.blob();
      }
    }).then(fileBlob => {
      let fileUrl = URL.createObjectURL(fileBlob);
      window.open(fileUrl);
    }).catch(() => {
    });
    e.preventDefault();
  }

    handleEditPresupuesto(e, presupuesto){
        this.props.editPresupuesto(presupuesto);
    }

	render() {
    if(this.props.ticket) {
      return (
        <React.Fragment>
          {this.state.loading && <Loading />}
          {this.state.presupuestos ? (
            <div className="card pull-up">
              <div className="card-content">
                <div className="card-body">
                  <h4 className="form-section">
                    <i className="la la-list"></i><FormattedMessage id="ticketVerPresupuestos.render.budgets.header_presupuestos" defaultMessage=" Presupuestos"/>
                  </h4>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <thead className="thead-fleet">
                          <tr>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_fecha" defaultMessage="Fecha"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_nombre" defaultMessage="Nombre"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_subido_por" defaultMessage="Subido por"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_mano_de_obra" defaultMessage="Mano de obra"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_repuestos" defaultMessage="Repuestos"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_impuestos" defaultMessage="Impuestos"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_otros" defaultMessage="Otros"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_total" defaultMessage="Total"/></th>
                            <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budgets.table_column_adicional" defaultMessage="Adicional"/></th>
                            {
                              ConfigBusiness.get('tickets.trabajaConEstadosPresupuesto.habilitado') === 'true' ?
                                <th scope="col"><FormattedMessage id="Estado" defaultMessage="Estado"/></th> 
                                : null
                            }
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.presupuestos.map((presupuesto, index)=>{
                            
                          return(
                            <tr key={index}>
                              <td className="align-middle">{ Timezone.getDateForClient(presupuesto.fechaHora, 'YYYY-MM-DD HH:mm:ss', "DD/MM/YYYY HH:mm:ss") }</td>
                              <td className="align-middle">{presupuesto.nombre}</td>
                              <td className="align-middle">{presupuesto.persona.nombre + ' '  + presupuesto.persona.apellido }</td>
                              <td className="align-middle">{Math.round(presupuesto.manoDeObra*100)/100}</td>
                              <td className="align-middle">{Math.round(presupuesto.repuestos*100)/100}</td>
                              <td className="align-middle">{Math.round(presupuesto.impuestos*100)/100}</td>
                              <td className="align-middle">{Math.round(presupuesto.otros*100)/100}</td>
                              <td className="align-middle">{Math.round((presupuesto.manoDeObra + presupuesto.repuestos + presupuesto.impuestos + presupuesto.otros)*100)/100}</td>
                              <td className="align-middle">{presupuesto.adicional? (<div className="text-center success dt-info-icon"><i className="ft-check"></i></div>) : ''}</td>
                              {console.log(ConfigBusiness.get('tickets.trabajaConEstadosPresupuesto.habilitado') === 'true' ? (presupuesto.estado==="Aprobado" ? false : true) : true)}
                              {ConfigBusiness.get('tickets.trabajaConEstadosPresupuesto.habilitado') === 'true' ?
                                <td className="align-middle">
                                  {
                                      presupuesto.estado && (
                                          <span className={
                                              {
                                                  'Pendiente': 'pendiente',
                                                  'Pendiente-Auditor': 'pendiente',
                                                  'Validado-Auditor': 'pendiente',
                                                  'Rechazado': 'rechazado-presupuesto-color',
                                                  'Aprobado': 'aprobado-presupuesto-color',
                                                  'Auto-Aprobado': 'aprobado-presupuesto-color',
                                              }[presupuesto.estado] || ''
                                          }>
                                              <FormattedMessage id={presupuesto.estado} defaultMessage={presupuesto.estado} />
                                          </span>
                                      )
                                  }
                                </td>
                              : null}
                              <td className="align-middle">
                                { (ConfigBusiness.get('tickets.trabajaConEstadosPresupuesto.habilitado') === 'true' ? (presupuesto.estado==="Aprobado" || presupuesto.estado==="Auto-Aprobado" ? false : true) : true  &&
                                  this.action != 'VIEW' && presupuesto.activo && !this.props.ticket.factura && Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') && this.props.ticket.gerenciador && ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') !== 'true') ? 
                                    <button className="action edit btn btn-sm btn-icon btn-dt-grid text-success" title={this.props.intl.formatMessage({ id: 'ticketVerPresupuestos.render.budgets.button_title_editar', defaultMessage: 'Editar' })}
                                      onClick={(e) => this.handleEditPresupuesto(e, presupuesto)}
                                    >
                                      <i className="fa fa-pencil fa-xs"></i>
                                    </button> : ''
                                }
                                <button className="action btn btn-icon text-fleet btn-sm btn-dt-grid p-0 m-0" onClick={(e) => this.handleViewAdjunto(e, presupuesto.adjunto)} data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'ticketVerPresupuestos.render.budgets.button_title_ver_adjunto', defaultMessage: 'Ver Adjunto' })}>
                                  <i className="fa fa-paperclip fa-xs"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : ( null )}
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketVerPresupuestos);
